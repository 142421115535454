import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EmploymentCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    height: "590px",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const GvsEmploymentDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [experienceInfo, setExperienceInfo] = useState([]);

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getExperienceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (experienceInfo.length > 0) {
      onDataUpdate({
        experienceInfo,
        renderExperienceDetails: () => renderExperienceDetails(),
      });
    }
  }, [experienceInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderExperienceDetails = () => {
    return (
      <>
        {/* <View wrap> */}

        {experienceInfo?.length > 0 && (
          <>
            {experienceInfo.map((experienceEvidence, index) => (
              <React.Fragment key={index}>
                <View key={index} style={EmploymentCheckStyles.table}>
                  <Text
                    style={{
                      textAlign: "center",
                      textDecoration: "underline",
                      backgroundColor: "#c5d9eb",
                      fontSize: 13,
                    }}
                  >
                    Employment Credential
                  </Text>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "256px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Respondent Name
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.respondentName}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "256px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Designation
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.designation}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "256px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Respondent’s Contact Details
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.respondentContactDetails}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Confirmation Feedback
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Details of Verification
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Candidate’s Claim
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Employer Confirmed
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Name of candidate
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.nameOfCandidate}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.nameOfCandidateRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Organization Name
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.organizationName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.organizationNameRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Employment Start Dates
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.employmentStartDates}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.employmentStartDatesRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Employment End Dates
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.employmentEndDates}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.employmentEndDatesRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Last Position Held & Department
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.lastPosition}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.lastPositionRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Employee ID/Code
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.employeeCode}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.employeeCodeRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Supervisor’s Name
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.supervisorName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 8,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.supervisorNameRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Supervisor’s Designation
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.supervisorDesignation}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.supervisorDesignationRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Salary Details
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.salary}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.verificationDetails?.salaryRemark}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Reason for Leaving
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.reasonForLeaving}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.verificationDetails
                          ?.reasonForLeavingRemark
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Eligibility to Re-hire
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.rehire}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Attendance & Punctuality
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      Telephone number:- +91
                      {experienceEvidence?.experience?.attendance}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Job Performance
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.jobPerformance}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Organization Integrity Issues
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {
                        experienceEvidence?.experience
                          ?.organizationIntegrityIssues
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Exit formalities
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.exitFormalities}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      FnF Settled
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.fnFSettled}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Does the candidate owe any dues
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.candidateDues}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Candidate overall behavior and code of conduct
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.candidateBehavior}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Ever Seen Intoxicated or taking drugs, alcohol in Office
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.intoxicated}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Any disciplinary issues against the candidate
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.candidateDisciplinary}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Any special comments about the candidate
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 9,
                      }}
                    >
                      {experienceEvidence?.experience?.candidateSpecialComments}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "900",
                      }}
                    >
                      Comments
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 5,
                        fontSize: 9,
                      }}
                    >
                      The above-mentioned detailed information has been written
                      and confirmed by{" "}
                      {experienceEvidence?.experience?.respondentName},{" "}
                      {experienceEvidence?.experience?.designation} at{" "}
                      {experienceEvidence?.experience?.organizationName}, on{" "}
                      {experienceEvidence?.verificationDetails?.remarkDate} for
                      a detailed understanding. Please refer to Annexure{" "}
                      {experienceEvidence?.verificationDetails?.annexure} of the
                      final report.
                    </Text>
                  </View>
                </View>

                {/* {experienceInfo.map((experienceEvidence, index) => (
                  <View key={index} break> */}

                {/* Loop through and display each proof image */}
                {experienceEvidence?.experience?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={EmploymentCheckStyles.centeredImageContainer}
                      wrap={false} // Ensure image stays on its own page
                      break={proofIndex !== 0} // Add page break after each image except the first one
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          margin: "17px 0 0 0",
                          textDecoration: "underline",
                          textAlign: "center",
                          display: "block",
                          marginTop: "50px",
                          marginBottom: "20px",
                        }}
                      >
                        Annexure-{" "}
                        {experienceEvidence?.verificationDetails?.annexure}
                      </Text>
                      <AsyncImage
                        fileUrl={url}
                        alt={`Employment evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                {/* </View> */}
                {/* ))} */}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
        {/* </View> */}
      </>
    );
  };
  return null;
};
