/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";
import {
  addressAnnexure,
  getSeverity,
  addressType,
  maritalStatus,
} from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const AddressDetails = ({
  userVerificationDetail,
  taskDetail,
  addressCheckInfo,
  getAddressCheckInfo,
  activityDetails,
  showActivity,
  getActivityDetails,
  flag,
}) => {
  let localStorageData = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const categories = [{ name: "Current" }, { name: "Permanent" }];
  const [fileUpload, SetFileUplaod] = useState([]);
  const [editable, setEditable] = useState(false);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activity, setActivity] = useState("");
  // const [remarkForActivity, setRemarkForActivity] = useState("");

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `addressCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getAddressCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "addressCheck/link";
    } else {
      url = "addressCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };
  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    if (type === "file") {
      const updatedUrls = item?.address?.reportUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateAddressFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.address?.uploadProofUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateAddressProofArray(e, id, item, updatedUrls);
    }
  };

  const updateAddressFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "addressCheck/link";
    } else {
      url = "addressCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      address: {
        ...item.address,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.address.remark,
        uploadProofUrl: [
          ...new Set([...item.address.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Address details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getAddressCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateAddressProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "addressCheck/link";
    } else {
      url = "addressCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      address: {
        ...item.address,
        reportUrl: [...new Set([...item.address.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.address.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Address details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getAddressCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        newData[index] = {
          ...newData[index],
          address: {
            ...newData[index].address,
            [e.target.name]: e.target.value,
          },
          verificationDetails: {
            ...newData[index].verificationDetails,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(addressCheckInfo);
    SetFileUplaod(data?.address?.reportUrl || []);
  }, [addressCheckInfo]);

  const updateDiscreteCalls = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "addressCheck/link";
    } else {
      url = "addressCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      address: {
        ...data[index]?.address,
        reportUrl: [
          ...new Set([...data[index]?.address?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.address?.remark,
        uploadProofUrl: [
          ...new Set([...data[index]?.address?.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   addressCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Address details updated successfully");
      if (flag === "false") {
        getAddressCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`addressCheck/address/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getAddressCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Address",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
        case "file":
          SetFileUplaod(uploadedFileUrls);
          toast.success("File successfully uploaded");
          break;
        case "uploadProof":
          SetUploadProof(uploadedFileUrls);
          toast.success("Upload proof successfully uploaded");
          break;
        default:
          break;
      }
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  console.log("address data---", data);
  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Address Task Submitted!",
                userVerificationDetail,
                addressCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}

      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>
        </div>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex gap-2">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",

                                item?._id,
                                dataIndex
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",

                                item?._id,
                                dataIndex
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Major Discrepancy",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Minor Discrepancy",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Positive",
                              command: () => {
                                createActivity(
                                  "Pending -> Positive",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Positive",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Negative",
                              command: () => {
                                createActivity(
                                  "Pending -> Negative",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Negative",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Recommended",
                              command: () => {
                                createActivity(
                                  "Pending -> Recommended",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Recommended",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Not Recommended",
                              command: () => {
                                createActivity(
                                  "Pending -> Not Recommended",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Not Recommended",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Failed",
                              command: () => {
                                createActivity(
                                  "Pending -> Failed",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Failed",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Could Not Verified",
                              command: () => {
                                createActivity(
                                  "Pending -> Could Not Verified",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Could Not Verified",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                          ]}
                        />
                      )}
                      <div className="ml-[20px]">
                        {getSeverity(item?.address?.status)}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Address Type
                      </label>
                      <Dropdown
                        value={item?.address?.typeOfAddress || ""}
                        onChange={(e) => onInputChange(e, dataIndex)}
                        options={categories}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Type"
                        filter
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="typeOfAddress"
                        name="typeOfAddress"
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Landmark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="landmark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.landmark || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        City
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="city"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.city || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        State
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="state"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.state || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Pin Code
                      </label>
                      <InputText
                        className="form-control"
                        keyfilter="int"
                        maxLength={6}
                        name="pincode"
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.address?.pincode || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Contact Number
                      </label>
                      <InputText
                        className="form-control"
                        keyfilter="int"
                        maxLength={10}
                        name="contactNumber"
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.address?.contactNumber || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Period of Stay(Months)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfStay"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.periodOfStay || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Pancard Verification
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="panCardVerification"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.panCardVerification || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Bank Account Verification
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="bankAccVerification"
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.address?.bankAccVerification || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Voter Id Verification
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="voterIdVerification"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.voterIdVerification || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Ownership
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="ownership"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.ownership || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.address?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.address?.remark || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.address?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      proofIndex,
                                      "proof"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.address?.status === "Pending" ? (
                      item?.address?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.address?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.address?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.address?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Address Type
                      </label>

                      {/* <InputText
                        className="form-control"
                        type="text"
                        name="typeOfAddress"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.typeOfAddress || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      /> */}

                      <Dropdown
                        value={item?.address?.typeOfAddress || ""}
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        options={addressType}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Address"
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="addressType"
                        name="typeOfAddress"
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Complete Address
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="completeAddress"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.completeAddress || ""}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Prominent Landmark
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="landmark"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.landmark || ""}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Period of Stay(Months)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfStay"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.periodOfStay || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Marital Status
                      </label>
                      {/* <InputText
                        className="form-control"
                        type="text"
                        name="maritalStatus"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.maritalStatus || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      /> */}
                      <Dropdown
                        value={item?.address?.maritalStatus || ""}
                        onChange={(e) => onInputChange(e, dataIndex)}
                        options={maritalStatus}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select"
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="maritalStatus"
                        name="maritalStatus"
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Easy to locate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="easyToLocate"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.easyToLocate || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Type of Accommodation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="accommodationType"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.accommodationType || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Residence Status
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="residenceStatus"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.residenceStatus || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Building Appearance
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="buildingAppearance"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.buildingAppearance || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        No. of family members
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="familyMembers"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.familyMembers || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Person Met
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="personMet"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.personMet || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Relation with the Candidate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="relationWithCandidate"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.relationWithCandidate || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Contact Number
                      </label>
                      <InputText
                        className="form-control"
                        keyfilter="int"
                        name="contactNumber"
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.address?.contactNumber || ""}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        City
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="city"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.city || ""}
                      />
                    </div>
                  </div>
                  <h4>Re-Confirmation:</h4>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name of Verifier
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="verifier"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.verifier || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Relationship with candidate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="relationshipWithCandidate"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.relationshipWithCandidate || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        Additional remarks
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarks"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.additionalRemarks || ""}
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.address?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="remark">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remark"
                      onChange={(e) => {
                        // setRemarkForActivity(e.target.value);
                        onInputChange(e, dataIndex, "alphanumeric");
                      }}
                      value={item?.address?.remark || ""}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose JPEG,PNG Only)
                      </span>
                    </label>
                    {item?.address?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProof")
                        }
                        itemTemplate={customItemTemplate}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.address?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <div
                                role="presentation"
                                onClick={(e) =>
                                  removeImg(
                                    e,
                                    item._id,
                                    item,
                                    proofIndex,
                                    "proof"
                                  )
                                } // Pass the `item` to removeImg
                                className="cursor-pointer"
                              >
                                <i
                                  className="pi pi-times"
                                  style={{ color: "red" }}
                                ></i>
                              </div>

                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "proof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  proofImage[dataIndex]?.[proofIndex] && (
                                    <a
                                      href={proofImage[dataIndex][proofIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${dataIndex}_${proofIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.address?.status === "Pending" ? (
                      item?.address?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* {item?.address?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateDiscreteCalls(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.address?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.address?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Type of Address -
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="typeOfAddress"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.typeOfAddress || ""}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name of Candidate
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="nameOfCandidate"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.nameOfCandidate || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Father’s Name
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="fatherName"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.fatherName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Address claim by candidate
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="completeAddress"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.completeAddress || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Confirmation of Address (Post Office, Pin No)
                      </label>

                      <InputText
                        className="form-control"
                        type="int"
                        name="pincode"
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.address?.pincode || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Candidate - Date of Birth
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="candidateDateOfBirth"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.candidateDateOfBirth || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Contact Number of Candidate
                      </label>

                      <InputText
                        className="form-control"
                        type="int"
                        name="contactNumber"
                        maxLength={10}
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.address?.contactNumber || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Period of Stay
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfStay"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.periodOfStay || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Type of Property
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="typeOfProperty"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.typeOfProperty || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Photo ID Proof Signature
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="photoIDProofSignature"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.photoIDProofSignature || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Address verifyed by Gvs
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="addressVerifyedByGvs"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.address?.addressVerifyedByGvs || ""}
                            disabled={
                              flag === "true" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>
                      )}
                  </div>
                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="row my-2">
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Remark Date
                            </label>

                            <InputText
                              className="form-control"
                              type="Date"
                              name="remarkDate"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols"
                                )
                              }
                              value={
                                item?.verificationDetails?.remarkDate || ""
                              }
                              disabled={
                                flag === "false" ||
                                localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              Annexure
                            </label>

                            <Dropdown
                              value={item?.verificationDetails?.annexure || ""}
                              onChange={(e) => onInputChange(e, dataIndex)}
                              options={addressAnnexure}
                              optionLabel="name"
                              optionValue="name"
                              placeholder="Select Annexure"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              className="w-full md:w-14rem"
                              inputId="annexure"
                              name="annexure"
                              disabled={
                                flag === "false" ||
                                localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="small mb-1" htmlFor="clientType">
                              ID Proof & Address confirmed by the person while
                              conducting virtual check. (Respondent Name)
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="respondentName"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols"
                                )
                              }
                              value={item?.address?.respondentName || ""}
                              disabled={
                                flag === "true" ||
                                localStorageData?.role === "Auditor"
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="remark">
                      Remarks
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="additionalRemarks"
                      onChange={(e) => {
                        // setRemarkForActivity(e.target.value);
                        onInputChange(e, dataIndex, "alphanumeric");
                      }}
                      value={item?.address?.additionalRemarks || ""}
                      disabled={
                        flag === "true" || localStorageData?.role === "Auditor"
                      }
                    />
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.address?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={localStorageData?.role === "BCAStaff"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="col-md-8">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(e, dataIndex, "alphanumeric");
                            }}
                            value={item?.address?.remark || ""}
                            disabled={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        </div>

                        {localStorageData?.role !== "user" && (
                          <div className="col-md-12 my-2">
                            <label className="small mb-1" htmlFor="clientType">
                              Upload Proof{" "}
                              <span className="opacity-70">
                                (Please Choose PDF less than 10MB)
                              </span>
                            </label>
                            {item?.address?.uploadProofUrl?.length === 0 && (
                              <FileUpload
                                name="fileUpload[]"
                                auto
                                multiple
                                accept="image/*"
                                customUpload={true}
                                maxFileSize={10000000}
                                uploadHandler={(e) =>
                                  handleFileUpload(e, "uploadProof")
                                }
                                itemTemplate={customItemTemplate}
                                disabled={
                                  flag === "false" ||
                                  localStorageData?.role === "Auditor"
                                }
                              />
                            )}
                            <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                              {(item?.address?.uploadProofUrl || []).map(
                                (fileUrl, proofIndex) => {
                                  // Extract file name from the URL
                                  const fileName = fileUrl.split("/").pop();
                                  const fileExtension = fileName
                                    .split(".")
                                    .pop()
                                    .toLowerCase();

                                  const isImage = ["jpeg", "png"].includes(
                                    fileExtension
                                  );

                                  return (
                                    <li
                                      key={proofIndex}
                                      className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                    >
                                      <div
                                        role="presentation"
                                        onClick={(e) =>
                                          removeImg(
                                            e,
                                            item._id,
                                            item,
                                            proofIndex,
                                            "proof"
                                          )
                                        } // Pass the `item` to removeImg
                                        className="cursor-pointer"
                                      >
                                        <i
                                          className="pi pi-times"
                                          style={{ color: "red" }}
                                        ></i>
                                      </div>
                                      <Button
                                        label="Download"
                                        onClick={(e) =>
                                          getImg(
                                            e,
                                            fileName,
                                            dataIndex,
                                            proofIndex,
                                            "proof"
                                          )
                                        }
                                        className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                      />
                                      <div className="text-center mt-2">
                                        {isImage &&
                                          proofImage[dataIndex]?.[
                                            proofIndex
                                          ] && (
                                            <a
                                              href={
                                                proofImage[dataIndex][
                                                  proofIndex
                                                ]
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              download={`file_${dataIndex}_${proofIndex}`}
                                            >
                                              View Image
                                            </a>
                                          )}
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.address?.status === "Pending" ? (
                      item?.address?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.address?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.address?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.address?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_SIRIUS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Candidate Name:
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="nameOfCandidate"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.nameOfCandidate || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Complete Address
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="completeAddress"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.completeAddress || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Father’s Name:
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="fatherName"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.fatherName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Date Of Visit:
                      </label>

                      <InputText
                        className="form-control"
                        type="Date"
                        name="dateOfVisit"
                        onChange={(e) => onInputChange(e, dataIndex)}
                        value={item?.address?.dateOfVisit || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Landmark:
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="landmark"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.landmark || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Type of Accommodation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="accommodationType"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.accommodationType || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Residential Status:
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="residenceStatus"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.residenceStatus || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Period Of Stay
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfStay"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.periodOfStay || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Person met on the address:
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="personMet"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.personMet || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Relation with the Candidate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="relationWithCandidate"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.relationWithCandidate || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        Conclusion & Additional Comments:
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="additionalRemarks"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.additionalRemarks || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.address?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={
                            flag === "true" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.address?.remark || ""}
                        disabled={
                          flag === "false" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.address?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.address?.status === "Pending" ? (
                      item?.address?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.address?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.address?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.address?.status)}</div>
                    )}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Type of Address -
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="typeOfAddress"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.address?.typeOfAddress || ""}
                        disabled={true}
                      />
                    </div>

                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Address
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="completeAddress"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.completeAddress || ""}
                      />
                    </div>

                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Residence Status
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="residenceStatus"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.residenceStatus || ""}
                      />
                    </div>

                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Tel/Mobile No
                      </label>

                      <InputText
                        className="form-control"
                        type="int"
                        name="contactNumber"
                        maxLength={10}
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.contactNumber || ""}
                      />
                    </div>
                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Stay(From)
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="stayFrom"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.stayFrom || ""}
                      />
                    </div>

                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Stay(To)
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="stayTo"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.address?.stayTo || ""}
                      />
                    </div>

                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Alternate/Family Member Contact Details
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="alternateNumber"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.alternateNumber || ""}
                      />
                    </div>

                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Marital Status
                      </label>

                      <Dropdown
                        value={item?.address?.maritalStatus}
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        options={maritalStatus}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Status"
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="maritalStatus"
                        name="maritalStatus"
                      />
                    </div>
                    <div className="col-md-4  mb-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Members in family
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="familyMembers"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.address?.familyMembers || ""}
                      />
                    </div>
                    {/* ------------------------------------------------ */}
                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                        <>
                          <div className="font-bold">
                            Verified Information-:
                          </div>
                          <div className="col-md-4  mb-2">
                            <label className="small mb-1" htmlFor="clientType">
                              Verified Address
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="verifiedAddress"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols"
                                )
                              }
                              value={item?.address?.verifiedAddress || ""}
                            />
                          </div>

                          <div className="col-md-4  mb-2">
                            <label className="small mb-1" htmlFor="clientType">
                              Name of the person contacted
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="personContacted"
                              onChange={(e) =>
                                onInputChange(e, dataIndex, "text")
                              }
                              value={item?.address?.personContacted || ""}
                            />
                          </div>
                          <div className="col-md-4  mb-2">
                            <label className="small mb-1" htmlFor="clientType">
                              Relationship with the candidate
                            </label>

                            <InputText
                              className="form-control"
                              type="text"
                              name="relationshipWithCandidate"
                              onChange={(e) =>
                                onInputChange(
                                  e,
                                  dataIndex,
                                  "alphanumericWithAllowedSymbols"
                                )
                              }
                              value={
                                item?.address?.relationshipWithCandidate || ""
                              }
                            />
                          </div>
                        </>
                      )}

                    {/* ------------------------------------------------------ */}
                  </div>
                  <div className="col-md-8  mb-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Any other comments
                    </label>

                    <InputTextarea
                      className="form-control"
                      type="text"
                      name="anyOtherComments"
                      onChange={(e) =>
                        onInputChange(
                          e,
                          dataIndex,
                          "alphanumericWithAllowedSymbols"
                        )
                      }
                      value={item?.address?.anyOtherComments || ""}
                      rows={5}
                      // disabled={
                      //   flag === "true" ||
                      //   localStorageData?.role === "Auditor"
                      // }
                    />
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.address?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          // disabled={
                          //   flag === "true" ||
                          //   localStorageData?.role === "Auditor"
                          // }
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.address?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                      <>
                        <div className="col-md-8">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(e, dataIndex, "alphanumeric");
                            }}
                            value={item?.address?.remark || ""}
                            // disabled={
                            //   flag === "false" ||
                            //     localStorageData?.role === "Auditor"
                            // }
                          />
                        </div>

                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                            <span className="opacity-70">
                              (Please Choose JPEG,PNG,PDF less than 5MB)
                            </span>
                          </label>
                          {item?.address?.uploadProofUrl?.length === 0 && (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*,application/pdf"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                              // disabled={
                              //   flag === "false" ||
                              //   localStorageData?.role === "Auditor"
                              // }
                            />
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.address?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );
                                const isPdf = fileExtension === "pdf";
                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      onClick={(e) =>
                                        removeImg(
                                          e,
                                          item._id,
                                          item,
                                          proofIndex,
                                          "proof"
                                        )
                                      } // Pass the `item` to removeImg
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                    />

                                    <div className="text-center mt-2">
                                      {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                          <a
                                            href={
                                              proofImage[dataIndex][proofIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download={`file_${dataIndex}_${proofIndex}`}
                                          >
                                            View Image
                                          </a>
                                        )}
                                      {isPdf &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                          <a
                                            href={
                                              proofImage[dataIndex][proofIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                          >
                                            View PDF
                                          </a>
                                        )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </>
                    )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2">
                    {flag === "false" && item?.address?.status === "Pending" ? (
                      item?.address?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.address?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.address?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.address?.status)}</div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
          {localStorageData?.role === "user" && (
            <ConfirmDialog visible={isModalOpen} />
          )}
        </>
      ))}
    </>
  );
};

export default AddressDetails;
