import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EmploymentCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const PamacEmploymentDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [experienceInfo, setExperienceInfo] = useState([]);

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getExperienceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (experienceInfo.length > 0) {
      onDataUpdate({
        experienceInfo,
        renderExperienceDetails: () => renderExperienceDetails(),
      });
    }
  }, [experienceInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderExperienceDetails = () => {
    return (
      <>
        <Text style={{ textAlign: "center", textDecoration: "underline" }}>
          Employment Verification
        </Text>
        {experienceInfo?.length > 0 && (
          <>
            {experienceInfo.map((experienceEvidence, index) => (
              <React.Fragment key={index}>
                <View style={{ marginTop: "40px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Employment Details:
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            experienceEvidence?.experience?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {experienceEvidence?.experience?.status}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View key={index} style={EmploymentCheckStyles.table}>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Details
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        As Provided
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        As Verified
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name & Address of the company
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.companyName}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.verificationDetails
                          ?.companyNameRemark === "Verified"
                          ? experienceEvidence?.verificationDetails
                              ?.companyNameRemark
                          : experienceEvidence?.reason?.reasonOfCompanyName}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Period of employment
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.employmentStartDates &&
                        experienceEvidence?.experience?.employmentEndDates
                          ? `${formatDate(experienceEvidence?.experience?.employmentStartDates)} To ${formatDate(experienceEvidence?.experience?.employmentEndDates)}`
                          : "NA"}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.verificationDetails
                          ?.employmentStartDatesRemark === "Verified" &&
                        experienceEvidence?.verificationDetails
                          ?.employmentEndDatesRemark === "Verified"
                          ? `${experienceEvidence?.verificationDetails?.employmentStartDatesRemark} - ${experienceEvidence?.verificationDetails?.employmentEndDatesRemark}`
                          : `${experienceEvidence?.reason?.reasonOfEmploymentStartDates} - ${experienceEvidence?.reason?.reasonOfEmploymentEndDates}`}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Title / designation
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.designation}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.verificationDetails
                          ?.designationRemark === "Verified"
                          ? experienceEvidence?.verificationDetails
                              ?.designationRemark
                          : experienceEvidence?.reason?.reasonOfDesignation}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Reporting Manager’s name & designation
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {
                          experienceEvidence?.experience
                            ?.reportingManagerDesignation
                        }
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.verificationDetails
                          ?.reportingManagerDesignationRemark === "Verified"
                          ? experienceEvidence?.verificationDetails
                              ?.reportingManagerDesignationRemark
                          : experienceEvidence?.reason
                              ?.reasonOfReportingManagerDesignation}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Salary / remuneration
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.salary}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.verificationDetails
                          ?.salaryRemark === "Verified"
                          ? experienceEvidence?.verificationDetails
                              ?.salaryRemark
                          : experienceEvidence?.reason?.reasonOfSalary}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Reason for leaving
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.reasonForLeaving}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.verificationDetails
                          ?.reasonForLeavingRemark === "Verified"
                          ? experienceEvidence?.verificationDetails
                              ?.reasonForLeavingRemark
                          : experienceEvidence?.reason
                              ?.reasonOfReasonForLeaving}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Eligibility for rehire
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.rehire}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Any area of concern
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.areaOfConcern}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Mode of verification
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.modeOfVerification}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Additional remarks
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.additionalRemarks}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name & designation of the verifier
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.verifierName}
                      </Text>
                    </View>
                    <View style={EmploymentCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Contact details of the verifier
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {experienceEvidence?.experience?.verifierContact}
                      </Text>
                    </View>
                  </View>

                  {/* Loop through and display each proof image */}
                  {experienceEvidence?.experience?.uploadProofUrl?.map(
                    (url, proofIndex) => (
                      <View
                        key={proofIndex}
                        style={EmploymentCheckStyles.centeredImageContainer}
                        wrap={false} // Ensure image stays on its own page
                        break={proofIndex !== 0} // Add page break after each image except the first one
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            margin: "17px 0 0 0",
                            textDecoration: "underline",
                            textAlign: "center",
                            display: "block",
                            marginTop: "50px",
                            marginBottom: "20px",
                          }}
                        >
                          Copy of written verification received from{" "}
                          {experienceEvidence?.experience?.companyName}
                        </Text>
                        <AsyncImage
                          fileUrl={url}
                          alt={`Employment evidence ${proofIndex + 1}`}
                        />
                      </View>
                    )
                  )}
                </View>

                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };
  return null;
};
