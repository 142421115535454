import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const DrivingLicenseCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 80,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const PamacDrivingLicenseDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [dlInfo, setDlInfo] = useState([]);

  const getDlInfo = (id) => {
    if (id) {
      ApiService.get(`identityCheck/${id}`)
        .then((res) => {
          const dlData = res?.data?.docs.find(
            (doc) => doc.identity?.type === "Driving License"
          );
          if (dlData) {
            console.log("dl>>>", dlData);
            setDlInfo(dlData);
          } else {
            console.log("No Driving License data found");
            setDlInfo([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    console.log("DLInfo>>>>", dlInfo);
  }, [dlInfo]);

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getDlInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (dlInfo && Object.keys(dlInfo).length > 0) {
      console.log("onDataUpdate called with:", {
        dlInfo,
        renderDlDetails: () => renderDlDetails(),
      });

      onDataUpdate({
        dlInfo,
        renderDlDetails: () => renderDlDetails(),
      });
    }
  }, [dlInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderDlDetails = () => {
    return (
      <>
        <React.Fragment>
          {/* Render table on one page */}
          <View style={DrivingLicenseCheckStyles.table}>
            <Text
              style={{
                textAlign: "center",
                textDecoration: "underline",
                backgroundColor: "#c5d9eb",
                fontSize: 13,
              }}
            >
              Identity Check DL
            </Text>

            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Date of Receipt
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.dateOfReciept}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Date of Returned to the Client
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.dateOfReturned}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Name of the Applicant
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.fathersName} */}
                {dlInfo?.identity?.nameOfApplicant}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Verification
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.verification}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "520px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 14,
                  fontWeight: "900",
                }}
              >
                Details
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Name of RTO
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.nameOfRTO}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Driving Licence Number
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.state} */}
                {dlInfo?.identity?.dlNumber}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Name of Contact person
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.nameOfContactPerson}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Designation & Department of person contacted
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.designationAndDepartment}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Contact Number
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.contactNumber}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Case Reference Number
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.caseReferenceNumber}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 14,
                  fontWeight: "900",
                }}
              >
                Final Status
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.status}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                REMARKS
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.remarks}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Latitude
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.latitude}
              </Text>
            </View>
            <View style={DrivingLicenseCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Longitude
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {dlInfo?.identity?.longitude}
              </Text>
            </View>
          </View>
          {/* <View break /> */}
          {dlInfo?.identity?.uploadProofUrl?.map((url, proofIndex) => (
            <View
              key={proofIndex}
              style={DrivingLicenseCheckStyles.centeredImageContainer}
              wrap={false}
              break
            >
              <AsyncImage fileUrl={url} alt={`DL evidence ${proofIndex + 1}`} />
            </View>
          ))}
          <View break />
        </React.Fragment>
      </>
    );
  };

  return null;
};
