import React, { useState, useEffect, useMemo } from "react";
import { useTable, useRowSelect, useSortBy } from "react-table";
import ApiService from "../../services/APIServices";
import { Col, Form, Input, Modal, Row, Tooltip } from "antd";
import CryptoJS from "crypto-js";
import NoData from "../../Helper/NoDataFound.jsx";
import { MultiSelect } from "primereact/multiselect";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Icons from "../../Helper/Icons.jsx";
import { Button } from "primereact/button";
import { casesCheckList, envChecks } from "../../utils/constants.js";
import { Password } from "primereact/password";

export default function SubcompanyList() {
  const [form] = Form.useForm();

  const [spinner, setSpinner] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddStaff, setShowaddStaff] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState("");
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  // const [noOfEmployee, setNoOfEmployee] = useState("");
  // const [websiteLink, setWebsiteLink] = useState("");
  const [clientCaseId, setClientCaseId] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [selectedChecks, setSelectedChecks] = useState([]);
  const [checksArray, setChecksArray] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedCompanyDetail, setSelectedCompanyDetail] = useState("");
  // const [BCAId, setBCAId] = useState("");

  const LocalData = JSON.parse(localStorage.getItem("user"));

  // const user = JSON.parse(localStorage.getItem("user"));

  // if (LocalData?.role === "BCA") {
  //   setBCAId(LocalData?._id);
  // } else {
  //   setBCAId(LocalData?.BCAId);
  // }

  console.log("LocalData>>>>>>", LocalData);

  const checks = casesCheckList.filter((check) => envChecks[check.name]);

  const deleteCompany = (id) => {
    const url = `company/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        setDialogVisible(false);
        toast.success("Sub Company Deleted Successfully");
        getAllCompanies();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Company");
      });
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      reject: () => setDialogVisible(false), // Close dialog on reject
      onHide: () => setDialogVisible(false), // Close dialog on close
    });
  };

  const accept = (id) => {
    deleteCompany(id);
  };

  const handleSubmit = () => {
    // Merge old checks with new ones
    const updatedChecks = [
      ...selectedCompanyDetail?.availableChecks,
      ...checksArray,
    ].filter(
      (check, index, self) => index === self.findIndex((c) => c === check) // Ensures no duplicates
    );

    // Send the updated checks to the backend
    ApiService.post(`company/${selectedCompanyDetail?._id}`, {
      availableChecks: updatedChecks,
    })
      .then(() => {
        toast.success("Check updated successfully");
        setVisibleModal(false);
        setSelectedChecks([]);
        getAllCompanies();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in check update");
      });
  };

  let queryParams = `/?limit=5&page=${page}`;

  if (LocalData?.role === "company") {
    queryParams += `&companyId=${LocalData?._id}`;
  }
  if (search) {
    queryParams += `&text=${search}`;
  }

  const decryptField = (encryptedField) => {
    if (!encryptedField) return encryptedField; // If the field is empty or null, return as is
    const bytes = CryptoJS.AES.decrypt(encryptedField, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const getAllCompanies = async () => {
    try {
      setSpinner(true);
      const res = await ApiService.get(`company/subcompany${queryParams}`);
      const decryptedCompanies = res.data.docs.map((company) => ({
        ...company,
        // password: decryptField(company.password),
        email: company.email,
        // email: decryptField(company.email),
        phoneNumber: decryptField(company.phoneNumber),
      }));

      setCompanyList(decryptedCompanies);
      setTotalPages(res.data.totalPages);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      console.log(err);
    }
  };

  // const handlePhoneNumberChange = (e) => {
  //   const inputNumber = e.target.value;
  //   if (/^\d+$/.test(inputNumber) && inputNumber.length <= 10) {
  //     setPhoneNumber(inputNumber);
  //   }
  // };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptField = (field) => {
    if (!field) return field; // If the field is empty or null, return as is
    return CryptoJS.AES.encrypt(field, secretKey).toString();
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/;
    return passwordRegex.test(password);
  };

  const onFinish = () => {
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    setIsLoading(true);
    const body = {
      BCAId: LocalData?.BCAId,
      parentCompanyId: LocalData?._id,
      name: name,
      address: address,
      email: email,
      role: "subcompany",
      phoneNumber: encryptField(phoneNumber),
      // websiteLink: websiteLink,
      clientCaseId: clientCaseId,
      // noOfEmployees: noOfEmployee,
      availableChecks: LocalData?.availableChecks,
      userName: userName,
      password: encryptField(password),
      apiKeyRequest: {
        apiKey: null,
        requestStatus: null,
      },
    };
    ApiService.post("company", body)
      .then(() => {
        setIsLoading(false);
        toast.success("Subcompany added successfully");
        setShowaddStaff(false);
        form.resetFields();
        getAllCompanies();
        setSelectedChecks([]);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage =
          err.response?.data?.error || "An unexpected error occurred.";
        toast.error(errorMessage);
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCompanies();
  }, [page, search]);

  const checksTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        return (
          (
            <>
              <div
                role="presentation"
                // onClick={() => {
                //   navigate("/employee", {
                //     state: {
                //       companyDetails: row?.original,
                //     },
                //   }
                // );
                // }}
                className="flex align-items-center gap-2 text-teal-500 hover:text-blue-500"
              >
                <span>{row?.original?.name} </span>
              </div>
            </>
          ) || <span>NA</span>
        );
      },
    },

    {
      Header: "User Name",
      accessor: "userName",
      Cell: ({ row }) => {
        const userName = row?.original?.userName;

        return (
          <>
            <div className="flex align-items-center gap-2">
              {userName ? (
                <Tooltip placement="top" title={userName}>
                  <span>{userName?.substring(0, 5)}...</span>
                </Tooltip>
              ) : (
                <span>NA</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        const email = row?.original?.email;

        return (
          <>
            <div className="flex align-items-center gap-2">
              {email ? (
                <Tooltip placement="top" title={email}>
                  <span>{email?.substring(0, 5)}...</span>
                </Tooltip>
              ) : (
                <span>NA</span>
              )}
            </div>
          </>
        );
      },
    },

    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      Cell: ({ row }) => {
        return row?.original?.phoneNumber || <span>NA</span>;
      },
    },

    {
      Header: "Address",
      accessor: "address",
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.address.length > 10 ? (
              <div>
                <Tooltip title={row?.original?.address} placement="bottom">
                  {row?.original?.address.substring(0, 10)}....
                </Tooltip>
              </div>
            ) : (
              <span>{row?.original?.address || "NA"}</span>
            )}
          </div>
        );
      },
    },

    {
      Header: "Select Checks",
      accessor: "",
      Cell: ({ row }) => {
        return (
          (
            <>
              <div
                role="presentation"
                onClick={() => {
                  setVisibleModal(true);
                  setSelectedCompanyDetail(row?.original);
                }}
                className="flex cursor-pointer align-items-center gap-2"
              >
                <Icons name="sidebar-arrow" />
              </div>
            </>
          ) || <span>NA</span>
        );
      },
    },
    {
      Header: "Delete Company",
      Cell: ({ row }) => {
        const id = row?.original?._id;
        return (
          <div
            className="cursor-pointer"
            role="presentation"
            onClick={() => confirm1(id)}
          >
            <DeleteIcon style={{ color: "red" }} />
            {/* <ConfirmDialog /> */}
          </div>
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => companyList, [companyList]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect
  );

  return (
    <>
      <div className="container px-auto mx-auto flex flex-wrap bg-white mt-6">
        <div className="flex flex-row justify-end mt-[25px] xl:mt-0 w-full">
          <Button
            className="text-white !text-[14px] font-medium h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
            icon="pi pi-plus"
            onClick={() => setShowaddStaff(true)}
            label="Add Sub Company"
          />
        </div>
        <div className=" w-full bg-white">
          <div className="flex flex-col mt-[20px]">
            <div className=" shadow p-[30px]  overflow-x-auto overflow-style-none ">
              <div className="inline-block min-w-full  align-middle ">
                <div className="justify-between flex flex-col lg:flex-row">
                  <div className=" text-black text-[22px] font-semibold ">
                    Your Sub Company
                  </div>
                  <div className="flex flex-row gap-[5px] ">
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        className="h-[39px] max-sm:w-[10rem]"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder="Search by Name"
                      />
                    </span>
                  </div>
                </div>

                <div className="w-full mt-[20px]  flex md:flex md:overflow-x-auto lg:overflow-x-visible md:rounded-lg">
                  <table
                    className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                    {...getTableProps()}
                  >
                    <thead className="bg-white">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          className="px-6 py-4"
                          key={Math.random().toString(36).substr(2, 9)}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column) => (
                            <th
                              className="py-3.5 px-4 text-gray-400 text-sm font-medium rtl:text-right text-nowrap"
                              key={Math.random().toString(36).substr(2, 9)}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ""
                                    : ""
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                      {...getTableBodyProps()}
                    >
                      {spinner ? (
                        <tr>
                          <td colSpan={columns.length}>
                            <div className="text-center mt-1">
                              <ProgressSpinner
                                style={{ width: "50px", height: "50px" }}
                                strokeWidth="8"
                                animationDuration=".7s"
                              />
                            </div>
                          </td>
                        </tr>
                      ) : data.length === 0 ? (
                        <tr>
                          <td colSpan={columns.length}>
                            <NoData info="Ooops, we could not find any case" />
                          </td>
                        </tr>
                      ) : (
                        rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                              key={Math.random().toString(36).substr(2, 9)}
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    className="px-4 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                                    key={Math.random()
                                      .toString(36)
                                      .substr(2, 9)}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-center mt-4 mb-4">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      onChange={handleChange}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                      page={page}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          width="50%"
          title="Add Your Sub Company"
          open={showAddStaff}
          footer={null}
          onCancel={() => setShowaddStaff(false)}
          className="custom-modal-content modalRespo"
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="add-new-wallet"
            form={form}
          >
            <p className="mt-2">Let’s add your Sub Company</p>
            {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
              <Col span={12}>
                <lable>Client Case ID</lable>
                <Form.Item
                  name="clientCaseId"
                  rules={[
                    {
                      required: false,
                      message: "Client Case Id is required",
                    },
                  ]}
                >
                  <Input
                    value={clientCaseId}
                    size="large"
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9\s@._-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue) || newValue === "") {
                        setClientCaseId(newValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z0-9\s@._-]*$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Row gutter={18} className="registerinfo-row">
              <Col span={12}>
                <lable>Company Name</lable>
                <Form.Item
                  name="name" // Unique name for the field
                  rules={[
                    {
                      required: false,
                      message: "name is required",
                    },
                  ]}
                >
                  <Input
                    value={name}
                    // placeholder="Enter Company Name"
                    size="large"
                    // onChange={(e) => {
                    //   setName(e.target.value);
                    // }}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\s-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue) || newValue === "") {
                        setName(newValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z\s-]$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <lable>Email</lable>
                <Form.Item
                  name="email" // Unique name for the field
                  rules={[
                    {
                      required: false,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input
                    value={email}
                    // placeholder="Enter Email Address"
                    size="large"
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    // }}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9\s@._-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue) || newValue === "") {
                        setEmail(newValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z0-9\s@._-]*$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18} className="registerinfo-row">
              <Col span={12}>
                <lable>Phone Number</lable>
                <Form.Item
                  name="phoneNumber" // Unique name for the field
                  rules={[
                    {
                      required: false,
                      message: "Phone number is required",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter a valid phone number!",
                    },
                  ]}
                >
                  <Input
                    value={phoneNumber}
                    // placeholder="Enter Phone Number"
                    size="large"
                    maxLength={10}
                    // onChange={handlePhoneNumberChange}
                    onChange={(e) => {
                      const regex = /^[0-9\s-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue) || newValue === "") {
                        setPhoneNumber(newValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      const regex = /^[0-9\s-]*$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <lable>Address</lable>
                <Form.Item
                  name="address" // Unique name for the field
                  rules={[
                    {
                      required: false,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input
                    value={address}
                    // placeholder="Enter Address"
                    size="large"
                    // onChange={(e) => {
                    //   setAddress(e.target.value);
                    // }}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9\s@._-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue) || newValue === "") {
                        setAddress(newValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z0-9\s@._-]*$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={18} className="registerinfo-row">
              <Col span={12}>
                <lable>User Name</lable>
                <Form.Item
                  name="userName" // Unique name for the field
                  rules={[
                    {
                      required: false,
                      message: "name is required",
                    },
                  ]}
                >
                  <Input
                    value={userName}
                    // placeholder="Enter User Name"
                    size="large"
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9\s-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue) || newValue === "") {
                        setUserName(newValue);
                      }
                      // setUserName(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z0-9\s-]*$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <lable>Password</lable>
                <Form.Item
                  name="password" // Unique name for the field
                >
                  <Password
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    size="large"
                    toggleMask
                    // feedback={false}
                    className="w-full h-[42px]"
                  />
                </Form.Item>
                {passwordError && (
                  <div style={{ color: "red" }}>{passwordError}</div>
                )}
              </Col>
            </Row>

            {/* <div>
              <lable>Select Checks</lable>
              <MultiSelect
                value={selectedChecks}
                options={checks}
                onChange={(e) => {
                  setSelectedChecks(e.value);

                  const selectedChecksNames = e.value.map(
                    (check) => check.name
                  );
                  setChecksArray(selectedChecksNames);
                }}
                optionLabel="name"
                // placeholder="Select Type of Checks"
                itemTemplate={checksTemplate}
                className="w-full md:w-20rem"
                display="chip"
                filter
              />
            </div> */}

            <div className="mt-[40px] text-center">
              <button
                type="submit"
                disabled={isLoading}
                className="w-[150px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Wait...
                  </>
                ) : (
                  "Add Sub Company"
                )}
              </button>
            </div>
          </Form>
        </Modal>

        <Modal
          width="30%"
          title="Select Checks"
          open={visibleModal}
          footer={null}
          onCancel={() => setVisibleModal(false)}
          className="custom-modal-content modalRespo"
        >
          <div>
            <MultiSelect
              value={selectedChecks}
              options={checks}
              onChange={(e) => {
                // Combine old selected checks with new ones, ensuring uniqueness
                const combinedChecks = [...selectedChecks, ...e.value].filter(
                  (check, index, self) =>
                    index === self.findIndex((c) => c.name === check.name) // Avoid duplicates
                );
                setSelectedChecks(combinedChecks);

                const selectedChecksNames = combinedChecks.map(
                  (check) => check.name
                );
                setChecksArray(selectedChecksNames);
              }}
              optionLabel="name"
              placeholder="Select Type"
              itemTemplate={checksTemplate}
              className="w-full md:w-20rem"
              display="chip"
              filter
            />
          </div>

          <div className="mt-[20px]">
            <div className="text-black text-[22px] font-semibold">
              Selected Checks:
            </div>
            {(selectedCompanyDetail?.availableChecks !== undefined ||
              selectedCompanyDetail?.availableChecks?.length > 0) && (
              <ul className="!pl-0">
                {selectedCompanyDetail?.availableChecks?.map((check) => (
                  <li
                    key={Math.random().toString(36).substr(2, 9)}
                    role="presentation"
                    className="flex flex-col bg-gradient-to-r from-sky-800 to-teal-400 font-semibold text-transparent bg-clip-text text-sm font-medium whitespace-nowrap"
                  >
                    {check}
                  </li>
                ))}
              </ul>
            )}
            <ul className="!pl-0">
              {selectedChecks.map((check) => (
                <li
                  className="flex flex-col bg-gradient-to-r from-sky-800 to-teal-400 font-semibold text-transparent bg-clip-text text-sm font-medium whitespace-nowrap"
                  key={Math.random().toString(36).substr(2, 9)}
                >
                  {check.name}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-3">
            <Button
              className="w-[100px] max-[700px]:mt-2 text-white text-xs font-medium h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Modal>

        <ConfirmDialog visible={dialogVisible} />
      </div>
    </>
  );
}
