import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import * as Yup from "yup";
import ApiService from "../../services/APIServices";

import { InputTextarea } from "primereact/inputtextarea";
import { toast } from "react-toastify";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      style={{ width: "50%" }}
    />
  );
});

export const WebForm = () => {
  const { webFormId } = useParams();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [webFormData, setWebFormData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    designation: Yup.string().required("Designation is required"),
    leaveReason: Yup.string().required("Leave Reason is required"),
    feedBack: Yup.string().required("Feedback is required"),
    annualCTC: Yup.string().required("Annual CTC is required"),
  });

  const formik = useFormik({
    initialValues: {
      designation: "",
      leaveReason: "",
      feedBack: "",
      annualCTC: "",
    },

    validationSchema: validationSchema,
    onSubmit: async () => {
      // setIsLoading(true);
      // const formData = {
      //   webFormDetails: {
      //     // ...values,
      //     designation: values.designation,
      //     leaveReason: values.leaveReason,
      //     feedBack: values.feedBack,
      //     annualCTC: values.annualCTC,
      //     },
      //   isSubmitted: true,
      // };
      // ApiService.post(`webform/${webFormId}`, formData)
      //   .then((res) => {
      //     toast.success("Feedback Submitted Successfully");
      //     getWebFormDetials();
      //     setIsLoading(false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setIsLoading(false);
      //   });
    },
  });
  const handleConfirm = () => {
    setIsLoading(true);
    const formData = {
      webFormDetails: {
        ...formik.values,
      },
      isSubmitted: true,
    };

    ApiService.post(`webform/${webFormId}`, formData)
      .then(() => {
        toast.success("Feedback Submitted Successfully");
        getWebFormDetials();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWebFormDetials = async () => {
    await ApiService.get(`webForm/${webFormId}`)
      .then((res) => {
        setIsFormSubmitted(res?.data?.isSubmitted);
        setWebFormData(res?.data);

        formik.setFieldValue("name", res?.data.webFormFields?.candidateName);
        formik.setFieldValue("email", res?.data.webFormFields?.email);
        formik.setFieldValue("startDate", res?.data.webFormFields?.startDate);
        formik.setFieldValue("endDate", res?.data.webFormFields?.endDate);
        formik.setFieldValue(
          "leaveReason",
          res?.data.webFormDetails?.leaveReason
        );
        formik.setFieldValue("feedBack", res?.data.webFormDetails?.feedBack);
        formik.setFieldValue(
          "designation",
          res?.data.webFormDetails?.designation
        );
        formik.setFieldValue("annualCTC", res?.data?.webFormDetails?.annualCTC);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWebFormDetials();
  }, [webFormId]);
  return (
    <>
      <div>
        <div
          className="container px-auto py-18 mx-auto flex flex-wrap bg-white mt-4 shadow-md min-h-[80vh]"
          // style={{ height: "80vh" }}
        >
          <div className="rounded-lg w-full">
            {isFormSubmitted === true && (
              <Alert severity="success">
                This feedback is already submitted
              </Alert>
            )}
            <h5 className="mt-2">
              {webFormData?.webFormFields?.candidateName} / employment history
              confirmation
            </h5>
            <form className="mt-3" onSubmit={formik.handleSubmit}>
              <div className="row gx-3 mb-3">
                <div className="col-md-4">
                  <label className="mb-1" htmlFor="inputEmployeName">
                    Employee Name
                  </label>
                  <InputText
                    className="form-control w-[50px]"
                    type="text"
                    placeholder="Employee Name"
                    id="name"
                    {...formik.getFieldProps("name")}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label className="mb-1" htmlFor="inputEmployeId">
                    Employer Email ID
                  </label>
                  <InputText
                    placeholder="Employer Email ID"
                    className="form-control"
                    type="text"
                    id="email"
                    {...formik.getFieldProps("email")}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <>
                    <label className="mb-1" htmlFor="inputStartDate">
                      Select your Start Date
                    </label>
                    <InputText
                      className="form-control "
                      id="startDate"
                      {...formik.getFieldProps("startDate")}
                      disabled
                    />
                    {formik.touched.startDate && formik.errors.startDate ? (
                      <div className="text-red-500">
                        {formik.errors.startDate}
                      </div>
                    ) : null}
                  </>
                </div>
              </div>
              <div className="row gx-3 mb-3">
                <div className="col-md-4">
                  <>
                    <label className="mb-1" htmlFor="inputEndDate">
                      Select your End Date
                    </label>
                    <InputText
                      className="form-control"
                      id="endDate"
                      {...formik.getFieldProps("endDate")}
                      disabled
                    />
                    {formik.touched.endDate && formik.errors.endDate ? (
                      <div className="text-red-500">
                        {formik.errors.endDate}
                      </div>
                    ) : null}
                  </>
                </div>
                <div className="col-md-4">
                  {webFormData?.webFormFields?.designation && (
                    <>
                      <label className="mb-1" htmlFor="inputDesigntion">
                        Designation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        id="designation"
                        placeholder="Designation"
                        {...formik.getFieldProps("designation")}
                        value={formik.values.designation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.designation &&
                      formik.errors.designation ? (
                          <div className="text-red-500">
                            {formik.errors.designation}
                          </div>
                        ) : null}
                    </>
                  )}
                </div>
                <div className="col-md-4">
                  {webFormData?.webFormFields?.annualCCT && (
                    <>
                      <label className="mb-1" htmlFor="inputAnnualCTC">
                        Annual CTC
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        placeholder="Annual CTC"
                        keyfilter="int"
                        id="annualCTC"
                        value={formik.values.annualCTC}
                        {...formik.getFieldProps("annualCTC")}
                      />
                      {formik.touched.annualCTC && formik.errors.annualCTC ? (
                        <div className="text-red-500">
                          {formik.errors.annualCTC}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
              <div className="row gx-3 mb-3">
                <div className="col-md-4">
                  {webFormData?.webFormFields?.reasonToLeave && (
                    <>
                      <label className="mb-1" htmlFor="inputReasonToLeave">
                        Reason to leave
                      </label>
                      <InputTextarea
                        className="form-control"
                        placeholder="Reason to leave"
                        type="text"
                        id="leaveReason"
                        value={formik.values.leaveReason}
                        {...formik.getFieldProps("leaveReason")}
                      />
                      {formik.touched.leaveReason &&
                      formik.errors.leaveReason ? (
                          <div className="text-red-500">
                            {formik.errors.leaveReason}
                          </div>
                        ) : null}
                    </>
                  )}
                </div>
                <div className="col-md-4">
                  {webFormData?.webFormFields?.feedback && (
                    <>
                      <label className="mb-1" htmlFor="inputFeedback">
                        Feedback
                      </label>
                      <InputTextarea
                        type="text"
                        id="feedBack"
                        placeholder="Feedback"
                        {...formik.getFieldProps("feedBack")}
                      />
                      {formik.touched.feedBack && formik.errors.feedBack ? (
                        <div className="text-red-500">
                          {formik.errors.feedBack}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>

              {isFormSubmitted === false && (
                <button
                  type="button"
                  onClick={handleConfirm}
                  className="w-[141px]  h-[37px] m-auto bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
                >
                  <div className="text-white text-xs m-auto text-center font-medium">
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Wait...
                      </>
                    ) : (
                      "Confirm"
                    )}{" "}
                  </div>
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
