import React, { useState, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { genderOptions, getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

const IdentityDetails = ({
  userVerificationDetail,
  taskDetail,
  identityCheckInfo,
  getIndetityInfo,
  activityDetails,
  showActivity,
  getActivityDetails,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activity, setActivity] = useState("");
  const [editDateOfVerificationInAadhar, setEditDateOfVerificationInAadhar] =
    useState(false);
  const [editDateOfVerificationInPan, setEditDateOfVerificationInPan] =
    useState(false);
  const [editDateOfBirthInAadhar, setEditDateOfBirthInAadhar] = useState(false);
  const [editDateOfReceiptinDL, setEditDateOfReceiptinDL] = useState(false);
  const [editDateOfReturnedinDL, setEditDateOfReturnedinDL] = useState(false);
  const [editDateOfExpiryInPP, setEditDateOfExpiryInPP] = useState(false);
  const [editDateOfBirthInVoterID, setEditDateOfBirthInVoterID] =
    useState(false);

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getDiscreteCallInfo(userVerificationDetail);
  }, [userVerificationDetail]);

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `identityCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getIndetityInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  useEffect(() => {
    setData(identityCheckInfo);
  }, [identityCheckInfo]);

  const formatDateToDDMMYY = (isoDate) => {
    console.log("date check", isoDate);
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const year = date.getFullYear();
    console.log("FORMATEDDATE>>", day, month, year);

    return `${day}/${month}/${year}`;
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    if (type === "file") {
      const updatedUrls = item?.identity?.reportUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateIdentityFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.identity?.uploadProofUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateIdentityProofArray(e, id, item, updatedUrls);
    }
  };

  const updateIdentityFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      identity: {
        ...item.identity,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.identity.remark,
        uploadProofUrl: [
          ...new Set([...item.identity.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Experience details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      // if (flag === "false") {
      getIndetityInfo(userVerificationDetail);
      // }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateIdentityProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      identity: {
        ...item.identity,
        reportUrl: [...new Set([...item.identity.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.identity.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Identity details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getIndetityInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const onInputChange = (e, index, type) => {
    const { name, value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          identity: {
            ...newData[index].identity,
            [name]: value,
          },
          verificationDetails: {
            ...newData[index].verificationDetails,
            [e.target.name]: e.target.value,
          },
        };
        return newData;
      });
    }
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
        case "file":
          SetFileUplaod(uploadedFileUrls);
          toast.success("File successfully uploaded");
          break;
        case "uploadProof":
          SetUploadProof(uploadedFileUrls);
          toast.success("Upload proof successfully uploaded");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const updateUANDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      identity: {
        ...data[index]?.identity,
        reportUrl: [
          ...new Set([...data[index]?.identity?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.identity?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.identity?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Identity form successfully submitted");
      if (flag === "false") {
        getIndetityInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };

  // const updateCheckStatus = async (status, e, id, index, type) => {
  //   e.preventDefault();
  //   const body = {
  //     status: status,
  //     type: type,
  //   };
  //   try {
  //     await ApiService.post(`identityCheck/identity/status/${id}`, body);

  //     toast.success("Check Task updated!");
  //     // updateTaskItem(id, status, type);
  //     getIndetityInfo(userVerificationDetail);
  //   } catch (error) {
  //     console.error("Error In Task Update", error);
  //     toast.error("Error In Task Update");
  //   }
  // };

  const updateCheckStatus = async (status, id) => {
    const body = { status: status };
    try {
      await ApiService.post(`identityCheck/identity/status/${id}`, body);
      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // getDiscreteCallInfo(userVerificationDetail);
      getIndetityInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Identity",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}

      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}

      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <div className="flex flex-row justify-between mb-[10px]">
                  <div className="flex justify-between">
                    {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                      <SplitButton
                        label="Update Status"
                        icon="pi pi-check"
                        model={[
                          {
                            label: "Major Discrepancy",
                            command: () => {
                              createActivity(
                                "Pending -> Major Discrepancy",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Major Discrepancy",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Minor Discrepancy",
                            command: () => {
                              createActivity(
                                "Pending -> Minor Discrepancy",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Minor Discrepancy",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Verified Clear",
                            command: () => {
                              createActivity(
                                "Pending -> Verified Clear",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Verified Clear",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Insufficiency",
                            command: () => {
                              createActivity(
                                "Pending -> Insufficiency",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Insufficiency",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Unable to Verify",
                            command: () => {
                              createActivity(
                                "Pending -> Unable to Verify",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Unable to Verify",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Stop Check",
                            command: () => {
                              createActivity(
                                "Pending -> Stop Check",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Stop Check",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Positive",
                            command: () => {
                              createActivity(
                                "Pending -> Positive",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Positive",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Negative",
                            command: () => {
                              createActivity(
                                "Pending -> Negative",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Negative",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Recommended",
                            command: () => {
                              createActivity(
                                "Pending -> Recommended",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Recommended",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Not Recommended",
                            command: () => {
                              createActivity(
                                "Pending -> Not Recommended",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Not Recommended",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Failed",
                            command: () => {
                              createActivity(
                                "Pending -> Failed",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Failed",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                          {
                            label: "Could Not Verified",
                            command: () => {
                              createActivity(
                                "Pending -> Could Not Verified",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Could Not Verified",

                                item?._id,
                                dataIndex
                              );
                            },
                          },
                        ]}
                      />
                    )}
                    <div className="ml-[20px]">
                      {getSeverity(item?.identity?.status)}
                    </div>
                  </div>
                </div>
              )}

              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  {item?.identity?.type === "Aadhaar Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Aadhar Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Address
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="address"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.address || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Gender
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="gender"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.gender || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Last digit of mobile Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="lastDigit"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.lastDigit || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Age
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="age"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.age || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summery
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "PAN Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Status
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panStatus || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Type(Individual/Commercial)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="individual"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.individual || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summery
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Driving License" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Driving Licence Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my--1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Licence
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Issue
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfIssue"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfIssue || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="country"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.country || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Passport" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Do you have passport?
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="isDocAvailable"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.isDocAvailable || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Passport Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Passport
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Issue Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="issueCountry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.issueCountry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Voter ID" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            VoterId Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On VoterId
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type !== "Voter ID" &&
                    item?.identity?.type !== "Passport" &&
                    item?.identity?.type !== "Driving License" &&
                    item?.identity?.type !== "PAN Card" &&
                    item?.identity?.type !== "Aadhaar Card" && (
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    )}

                  {item?.identity?.type !== "Voter ID" &&
                    item?.identity?.type !== "Passport" &&
                    item?.identity?.type !== "Driving License" &&
                    item?.identity?.type !== "PAN Card" &&
                    item?.identity?.type !== "Aadhaar Card" && (
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    )}

                  <div className="row my-2"></div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="clientType">
                      Additional Remarks
                    </label>
                    <InputTextarea
                      className="form-control"
                      type="text"
                      name="additionalRemarks"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.identity?.additionalRemarks || ""}
                      disabled={flag === "true"}
                      rows={5}
                      cols={30}
                    />
                  </div>

                  {/* ----------------------------------------------------------------------------- */}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.identity?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={localStorageData?.role === "BCAStaff"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  // onClick={(e) =>
                                  //   removeImg(
                                  //     e,
                                  //     item._id,
                                  //     item,
                                  //     reportIndex,
                                  //     "file"
                                  //   )
                                  // }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.identity?.remark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.identity?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "false"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.identity?.status === "Pending" ? (
                      item?.identity?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.identity?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateUANDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.identity?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.identity?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  {item?.identity?.type === "Aadhaar Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Aadhar Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.identity?.cardNumber || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Full Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.name || ""}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.fathersName || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          /> */}

                          {/* {item?.identity?.dateOfBirth !== "" &&
                            editDateOfBirthInAadhar === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfBirthInAadhar(
                                    !editDateOfBirthInAadhar
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfBirth === "" ||
                          editDateOfBirthInAadhar === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfBirth || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfBirth"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                          />
                          {/* </>
                          )} */}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Address
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="address"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.address || ""}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.state || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Gender
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="text"
                            name="gender"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.gender || ""}
                            disabled={flag === "true"}
                          /> */}
                          <Dropdown
                            value={item?.identity?.gender}
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            options={genderOptions}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select your Gender"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            className="w-full md:w-14rem flex items-center justify-center"
                            inputId="gender"
                            name="gender"
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Last 4 digit of mobile Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="lastDigit"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.identity?.lastDigit || ""}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Age
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="age"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.age || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          {/* {item?.identity?.dateOfVerification !== "" &&
                            editDateOfVerificationInAadhar === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfVerificationInAadhar(
                                    !editDateOfVerificationInAadhar
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfVerification === "" ||
                          editDateOfVerificationInAadhar === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfVerification || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfVerification"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                          />
                          {/* </>
                          )} */}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summary
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "PAN Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.panNumber || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.name || ""}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Status
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panStatus || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Type(Individual/Commercial)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="individual"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.individual || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                            disabled={flag === "true"}
                          /> */}

                          {/* {item?.identity?.dateOfVerification !== "" &&
                            editDateOfVerificationInPan === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfVerificationInPan(
                                    !editDateOfVerificationInPan
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfVerification === "" ||
                          editDateOfVerificationInPan === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfVerification || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfVerification"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                          />
                          {/* </>
                          )} */}
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summary
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Driving License" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Receipt
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="date"
                            name="dateOfReciept"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfReciept || ""}
                            // disabled={flag === "true"}
                          /> */}

                          {/* {item?.identity?.dateOfReciept !== "" &&
                            editDateOfReceiptinDL === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfReceiptinDL(
                                    !editDateOfReceiptinDL
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfReciept === "" ||
                          editDateOfReceiptinDL === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfReciept || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfReciept"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfReciept"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfReciept || ""}
                          />
                          {/* </>
                          )} */}
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Returned to the Client:
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="date"
                            name="dateOfReturned"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfReturned || ""}
                            // disabled={flag === "true"}
                          /> */}

                          {/* {item?.identity?.dateOfReturned !== "" &&
                            editDateOfReceiptinDL === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfReturnedinDL(
                                    !editDateOfReturnedinDL
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfReturned === "" ||
                          editDateOfReturnedinDL === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfReturned || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfReturned"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <> */}
                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfReturned"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfReturned || ""}
                          />
                          {/* </>
                          )} */}
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of the Applicant:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfApplicant"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.nameOfApplicant || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Verification
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="verification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.verification || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of RTO
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfRTO"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.nameOfRTO || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Driving Licence Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dlNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.dlNumber || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of Contact person
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfContactPerson"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.nameOfContactPerson || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Designation & Department of person contacted:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="designationAndDepartment"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={
                              item?.identity?.designationAndDepartment || ""
                            }
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Contact Number
                          </label>

                          <InputText
                            className="form-control"
                            type="number"
                            name="contactNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.identity?.contactNumber || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Case Reference Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="caseReferenceNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.caseReferenceNumber || ""}
                          />
                        </div>

                        {/* <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Final Status
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="finalStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.status || ""}
                            disabled={true}
                          />
                        </div> */}

                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            REMARKS:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="remarks"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.remarks || ""}
                          />
                        </div>

                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Latitude:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="latitude"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.latitude || ""}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Longitude:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="longitude"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.longitude || ""}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Passport" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Do you have passport?
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="isDocAvailable"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.isDocAvailable || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Passport Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Passport
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>
                          {/* 
                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          /> */}

                          {item?.identity?.dateOfExpiry !== "" &&
                            editDateOfExpiryInPP === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfExpiryInPP(!editDateOfExpiryInPP)
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfExpiry === "" ||
                          editDateOfExpiryInPP === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfExpiry || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfExpiry"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfExpiry"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfExpiry
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Issue Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="issueCountry"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.issueCountry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Voter ID" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            VoterId Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On VoterId
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>
                          {/* 
                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          /> */}
                          {item?.identity?.dateOfBirth !== "" &&
                            editDateOfBirthInVoterID === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfBirthInVoterID(
                                    !editDateOfBirthInVoterID
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfBirth === "" ||
                          editDateOfBirthInVoterID === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfBirth || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfBirth"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfBirth"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfBirth
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type !== "Voter ID" &&
                    item?.identity?.type !== "Passport" &&
                    item?.identity?.type !== "Driving License" &&
                    item?.identity?.type !== "PAN Card" &&
                    item?.identity?.type !== "Aadhaar Card" && (
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                          />
                        </div>
                      </div>
                    )}

                  <div className="row my-2"></div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="clientType">
                      Additional Remarks
                    </label>
                    <InputTextarea
                      className="form-control"
                      type="text"
                      name="additionalRemarks"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.identity?.additionalRemarks || ""}
                      rows={5}
                      cols={30}
                    />
                  </div>

                  {/* ----------------------------------------------------------------------------- */}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.identity?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="remark">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remark"
                      onChange={(e) => {
                        // setRemarkForActivity(e.target.value);
                        onInputChange(e, dataIndex, "alphanumeric");
                      }}
                      value={item?.identity?.remark || ""}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose JPEG,PNG Only)
                      </span>
                    </label>
                    {item?.identity?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProof")
                        }
                        itemTemplate={customItemTemplate}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.identity?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <div
                                role="presentation"
                                onClick={(e) =>
                                  removeImg(
                                    e,
                                    item._id,
                                    item,
                                    proofIndex,
                                    "proof"
                                  )
                                }
                                className="cursor-pointer"
                              >
                                <i
                                  className="pi pi-times"
                                  style={{ color: "red" }}
                                ></i>
                              </div>
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "proof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  proofImage[dataIndex]?.[proofIndex] && (
                                    <a
                                      href={proofImage[dataIndex][proofIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${dataIndex}_${proofIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.identity?.status === "Pending" ? (
                      item?.identity?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* {item?.identity?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateUANDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.identity?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.identity?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_SIRIUS_REPORT_ENABLED === "true" && (
                <form>
                  {item?.identity?.type === "Aadhaar Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Aadhar Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Address
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="address"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.address || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Gender
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="gender"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.gender || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Last digit of mobile Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="lastDigit"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.lastDigit || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Age
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="age"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.age || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summery
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "PAN Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Status
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panStatus || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Type(Individual/Commercial)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="individual"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.individual || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summery
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Driving License" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Driving Licence Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Licence
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Issue
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfIssue"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfIssue || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="country"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.country || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Passport" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Do you have passport?
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="isDocAvailable"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.isDocAvailable || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Passport Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Passport
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Issue Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="issueCountry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.issueCountry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Voter ID" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            VoterId Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On VoterId
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Identity Type
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="type"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.identity?.type || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Card Number
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="cardNumber"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.identity?.cardNumber || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Name On Card
                      </label>

                      <InputText
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            dataIndex,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.identity?.name || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>

                  {item?.identity?.type !== "Voter ID" &&
                    item?.identity?.type !== "Passport" &&
                    item?.identity?.type !== "Driving License" &&
                    item?.identity?.type !== "PAN Card" &&
                    item?.identity?.type !== "Aadhaar Card" && (
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    )}

                  <div className="row my-2"></div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="clientType">
                      Additional Remarks
                    </label>
                    <InputTextarea
                      className="form-control"
                      type="text"
                      name="additionalRemarks"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.identity?.additionalRemarks || ""}
                      disabled={flag === "true"}
                      rows={5}
                      cols={30}
                    />
                  </div>

                  {/* ----------------------------------------------------------------------------- */}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.identity?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  // onClick={(e) =>
                                  //   removeImg(
                                  //     e,
                                  //     item._id,
                                  //     item,
                                  //     reportIndex,
                                  //     "file"
                                  //   )
                                  // }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.identity?.remark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.identity?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "false"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  // onClick={(e) =>
                                  //   removeImg(
                                  //     e,
                                  //     item._id,
                                  //     item,
                                  //     proofIndex,
                                  //     "proof"
                                  //   )
                                  // }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                  <div></div>

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.identity?.status === "Pending" ? (
                      item?.identity?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.identity?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateUANDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.identity?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.identity?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true" && (
                <form>
                  {item?.identity?.type === "Aadhaar Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Aadhar Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          /> */}

                          {item?.identity?.dateOfBirth !== "" &&
                            editDateOfBirthInAadhar === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfBirthInAadhar(
                                    !editDateOfBirthInAadhar
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfBirth === "" ||
                          editDateOfBirthInAadhar === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfBirth || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfBirth"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfBirth"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfBirth
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Address
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="address"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.address || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Gender
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="gender"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.gender || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Last digit of mobile Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="lastDigit"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.lastDigit || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Age
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="age"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.identity?.age || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          {item?.identity?.dateOfVerification !== "" &&
                            editDateOfVerificationInAadhar === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfVerificationInAadhar(
                                    !editDateOfVerificationInAadhar
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfVerification === "" ||
                          editDateOfVerificationInAadhar === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfVerification || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfVerification"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfVerification"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfVerification
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summary
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "PAN Card" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Pan Status
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="panStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.panStatus || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Type(Individual/Commercial)
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="individual"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.individual || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Verification
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="Date"
                            name="dateOfVerification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfVerification || ""}
                            disabled={flag === "true"}
                          /> */}

                          {item?.identity?.dateOfVerification !== "" &&
                            editDateOfVerificationInPan === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfVerificationInPan(
                                    !editDateOfVerificationInPan
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfVerification === "" ||
                          editDateOfVerificationInPan === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfVerification || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfVerification"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfVerification"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfVerification
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Record summary
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="recordSummery"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.recordSummery || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Driving License" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Receipt
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="date"
                            name="dateOfReciept"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfReciept || ""}
                            // disabled={flag === "true"}
                          /> */}

                          {item?.identity?.dateOfReciept !== "" &&
                            editDateOfReceiptinDL === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfReceiptinDL(
                                    !editDateOfReceiptinDL
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfReciept === "" ||
                          editDateOfReceiptinDL === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfReciept || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfReciept"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfReciept"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfReciept
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Date of Returned to the Client:
                          </label>

                          {/* <InputText
                            className="form-control"
                            type="date"
                            name="dateOfReturned"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfReturned || ""}
                            // disabled={flag === "true"}
                          /> */}

                          {item?.identity?.dateOfReturned !== "" &&
                            editDateOfReceiptinDL === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfReturnedinDL(
                                    !editDateOfReturnedinDL
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfReturned === "" ||
                          editDateOfReturnedinDL === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfReturned || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfReturned"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfReturned"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfReturned
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of the Applicant:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfApplicant"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.nameOfApplicant || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Verification
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="verification"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.verification || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of RTO
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfRTO"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.nameOfRTO || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Driving Licence Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="dlNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dlNumber || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Name of Contact person
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="nameOfContactPerson"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.identity?.nameOfContactPerson || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Designation & Department of person contacted:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="designationAndDepartment"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={
                              item?.identity?.designationAndDepartment || ""
                            }
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Contact Number
                          </label>

                          <InputText
                            className="form-control"
                            type="number"
                            name="contactNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.identity?.contactNumber || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Case Reference Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="caseReferenceNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.caseReferenceNumber || ""}
                            // disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Final Status
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="finalStatus"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.status || ""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            REMARKS:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="remarks"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.remarks || ""}
                            // disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Latitude:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="latitude"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.latitude || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4 my-1">
                          <label className="small mb-1" htmlFor="clientType">
                            Longitude:
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="longitude"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.longitude || ""}
                            // disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Passport" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Do you have passport?
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="isDocAvailable"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.isDocAvailable || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Passport Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Passport
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Date Of Expiry
                          </label>
                          {/* 
                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfExpiry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfExpiry || ""}
                            disabled={flag === "true"}
                          /> */}

                          {item?.identity?.dateOfExpiry !== "" &&
                            editDateOfExpiryInPP === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfExpiryInPP(!editDateOfExpiryInPP)
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfExpiry === "" ||
                          editDateOfExpiryInPP === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfExpiry || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfExpiry"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfExpiry"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfExpiry
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Issue Country
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="issueCountry"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.issueCountry || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type === "Voter ID" && (
                    <>
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            VoterId Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On VoterId
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            DOB(date of birth)
                          </label>
                          {/* 
                          <InputText
                            className="form-control"
                            type="text"
                            name="dateOfBirth"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.dateOfBirth || ""}
                            disabled={flag === "true"}
                          /> */}
                          {item?.identity?.dateOfBirth !== "" &&
                            editDateOfBirthInVoterID === false && (
                              <i
                                className="pi pi-pencil"
                                style={{
                                  fontSize: "1rem",
                                  color: "#075B86",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                                onClick={() =>
                                  setEditDateOfBirthInVoterID(
                                    !editDateOfBirthInVoterID
                                  )
                                }
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Date"
                              ></i>
                            )}
                          <br />
                          {item?.identity?.dateOfBirth === "" ||
                          editDateOfBirthInVoterID === true ? (
                            <>
                              <Calendar
                                value={item?.identity?.dateOfBirth || ""}
                                onChange={(e) =>
                                  onInputChange(
                                    e,
                                    dataIndex,
                                    "alphanumericWithAllowedSymbols"
                                    // "education"
                                  )
                                }
                                name="dateOfBirth"
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy"
                                showIcon
                                // readOnlyInput
                                className="!w-[350px]"
                              />
                            </>
                          ) : (
                            <>
                              <InputText
                                className="form-control"
                                type="text"
                                name="dateOfBirth"
                                // onChange={(e) =>
                                //   onInputChange(e, dataIndex, "text", "education")
                                // }
                                value={formatDateToDDMMYY(
                                  item?.identity?.dateOfBirth
                                )}
                                readOnly={true}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Fathers Name
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="fathersName"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.fathersName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            State
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="state"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.state || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {item?.identity?.type !== "Voter ID" &&
                    item?.identity?.type !== "Passport" &&
                    item?.identity?.type !== "Driving License" &&
                    item?.identity?.type !== "PAN Card" &&
                    item?.identity?.type !== "Aadhaar Card" && (
                      <div className="row my-2">
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Identity Type
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="type"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.type || ""}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Card Number
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="cardNumber"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.cardNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Name On Card
                          </label>

                          <InputText
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) =>
                              onInputChange(
                                e,
                                dataIndex,
                                "alphanumericWithAllowedSymbols"
                              )
                            }
                            value={item?.identity?.name || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      </div>
                    )}

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                      <div className="col-md-8">
                        <label className="small mb-1" htmlFor="clientType">
                          Additional Remarks
                        </label>
                        <InputTextarea
                          className="form-control"
                          type="text"
                          name="additionalRemarks"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.identity?.additionalRemarks || ""}
                          disabled={flag === "true"}
                          rows={5}
                          cols={30}
                        />
                      </div>
                    )}

                  {/* ----------------------------------------------------------------------------- */}
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.identity?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          // disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.identity?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[dataIndex][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "dataEntry" && (
                      <>
                        <div className="col-md-8">
                          <label className="small mb-1" htmlFor="remark">
                            Remark
                          </label>
                          <textarea
                            className="form-control h-[110px]"
                            name="remark"
                            onChange={(e) => {
                              // setRemarkForActivity(e.target.value);
                              onInputChange(e, dataIndex, "alphanumeric");
                            }}
                            value={item?.identity?.remark || ""}
                            // disabled={flag === "false"}
                          />
                        </div>
                        <div className="col-md-12 my-2">
                          <label className="small mb-1" htmlFor="clientType">
                            Upload Proof{" "}
                            <span className="opacity-70">
                              (Please Choose PDF less than 10MB)
                            </span>
                          </label>
                          {item?.identity?.uploadProofUrl?.length === 0 && (
                            <FileUpload
                              name="fileUpload[]"
                              auto
                              multiple
                              accept="image/*,application/pdf"
                              customUpload={true}
                              maxFileSize={10000000}
                              uploadHandler={(e) =>
                                handleFileUpload(e, "uploadProof")
                              }
                              itemTemplate={customItemTemplate}
                              // disabled={flag === "false"}
                            />
                          )}
                          <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                            {(item?.identity?.uploadProofUrl || []).map(
                              (fileUrl, proofIndex) => {
                                // Extract file name from the URL
                                const fileName = fileUrl.split("/").pop();
                                const fileExtension = fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase();

                                const isImage = ["jpeg", "png"].includes(
                                  fileExtension
                                );
                                const isPdf = fileExtension === "pdf";
                                return (
                                  <li
                                    key={proofIndex}
                                    className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                  >
                                    <div
                                      role="presentation"
                                      // onClick={(e) =>
                                      //   removeImg(
                                      //     e,
                                      //     item._id,
                                      //     item,
                                      //     proofIndex,
                                      //     "proof"
                                      //   )
                                      // }
                                      className="cursor-pointer"
                                    >
                                      <i
                                        className="pi pi-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    </div>
                                    <Button
                                      label="Download"
                                      onClick={(e) =>
                                        getImg(
                                          e,
                                          fileName,
                                          dataIndex,
                                          proofIndex,
                                          "proof"
                                        )
                                      }
                                      className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                    />
                                    <div className="text-center mt-2">
                                      {isImage &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                          <a
                                            href={
                                              proofImage[dataIndex][proofIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download={`file_${dataIndex}_${proofIndex}`}
                                          >
                                            View Image
                                          </a>
                                        )}
                                      {isPdf &&
                                        proofImage[dataIndex]?.[proofIndex] && (
                                          <a
                                            href={
                                              proofImage[dataIndex][proofIndex]
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                          >
                                            View PDF
                                          </a>
                                        )}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>{" "}
                      </>
                    )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.identity?.status === "Pending" ? (
                      item?.identity?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.identity?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateUANDetails(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.identity?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.identity?.status)}</div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
          {localStorageData?.role === "user" && (
            <ConfirmDialog visible={isModalOpen} />
          )}
        </>
      ))}
    </>
  );
};

export default IdentityDetails;
