import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import EducationDetail from "../../StaffMember/EducationDetails.jsx";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NoData from "../../../Helper/NoDataFound.jsx";
import { ProgressSpinner } from "primereact/progressspinner";

export default function EducationCheck({
  userVerificationRequestInfo,
  info,
  taskDetail,
  flag,
}) {
  const [selectedStaffForEducationCheck, setSelectedStaffForEducationCheck] =
    useState(null);
  const [
    selectedStaffForInternalEducationCheck,
    setSelectedStaffForInternalEducationCheck,
  ] = useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [BCAStaffListInternal, setBCAStaffListInternal] = useState([]);
  const [educationCheckInfo, setEducationCheckInfo] = useState([]);
  const [educationCheckId, setEducationCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [missingEducations, setMissingEducations] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [showActivity, setShowActivity] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type !== "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Education"))
          );
        });
        console.log("staffListForEducation>>", filteredData);

        setBCAStaffList(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBCAInternalStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type === "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Education"))
          );
        });
        console.log("staffListForEducation>>", filteredData);

        setBCAStaffListInternal(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(userVerificationRequestInfo?.checksOption, "CHECKS++");

    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else if (user?.role === "Vendor") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.vendorId);
      } else if (user?.role === "Coordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else if (user?.role === "FieldCoordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else {
        getBCAStaffByBCAId(user?._id);
        getBCAInternalStaffByBCAId(user?.BCAId);
      }
    }
    geteducationCheckInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  useEffect(() => {
    console.log(missingEducations, "missingEducations");
  }, [missingEducations.length > 0]);

  useEffect(() => {
    if (
      educationCheckInfo.length >= 0 &&
      userVerificationRequestInfo?.checksOption?.education?.length > 0
    ) {
      const missingQualifications = [];

      userVerificationRequestInfo.checksOption.education.forEach((option) => {
        const normalizedOption = option;

        const found = educationCheckInfo.some(
          (check) => check.education.qualification === normalizedOption
        );

        if (!found) {
          missingQualifications.push(option);
        }
      });

      setMissingEducations(missingQualifications);

      if (missingQualifications.length > 0) {
        console.log("Missing educations:", missingQualifications);
      }
    }
  }, [
    educationCheckInfo,
    userVerificationRequestInfo?.checksOption?.education,
  ]);

  const geteducationCheckInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    setLoading(true);
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setEducationCheckInfo(res?.data?.docs);
        setEducationCheckId(res?.data?.docs[0]?._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId:
          selectedStaffForEducationCheck?._id ||
          selectedStaffForInternalEducationCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Education",
      };

      if (userVerificationRequestInfo?.EducationTaskId === null) {
        // body.BCAId = assignEducationTask?.selectedStaff?.BCAId;
        // body.assignedStaffMemberId = assignEducationTask?.selectedStaff?._id;
        body.checkListItem = educationCheckInfo;
        await createTask(body);
      } else {
        // body.BCAId = assignEducationTask.selectedStaff.BCAId;
        // body.assignedStaffMemberId = assignEducationTask.selectedStaff._id;
        body.checkListItem = educationCheckInfo;
        let path = `task/${userVerificationRequestInfo?.EducationTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId:
            selectedStaffForEducationCheck?._id ||
            selectedStaffForInternalEducationCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      console.error("Error in API:", error);
      setAssignLoading(false);
    }
  };
  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
        // setShowConsentModal(false);
      });
  };
  const updateCaseIsAssigned = async () => {
    let currentDate = new Date();
    let body = {
      isAssigned: {
        status: true,
        dateAndTime: currentDate,
      },
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      geteducationCheckInfo(userVerificationRequestInfo?._id);
      info();
      setAssignLoading(false);
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.post(path, body);
    if (response) {
      toast.success("Task updated successfully");
      geteducationCheckInfo(userVerificationRequestInfo?._id);
      info();

      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
      setAssignLoading(false);
    }
  };

  const getActivityDetails = (id) => {
    ApiService.get(`caseActivity/byCaseId/${id}`)

      .then((res) => {
        // Sort the data based on createdAt field

        const sortedData = res?.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const filteredData = sortedData.filter(
          (item) => item.checkName === "Education"
        );

        console.log("Filtered Activity Details:", sortedData);

        setActivityDetails(filteredData);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const createEducationCheck = async (qualification) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    setAddNewLoading(true);

    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      education: {
        candidateName: "",
        qualification: qualification === "" ? "" : qualification,
        unversityName: "",
        degreeName: "",
        isGraduated: "",
        specilisation: "",
        unversityAddress: "",
        countryName: "",
        nameAndDesignationOfTheReferee: "",
        state: "",
        city: "",
        pincode: "",
        instituteAddress: "",
        instituteName: "",
        schoolName: "",
        remarkAboutUniversity: "",
        respondentName: "",
        respondentDesignation: "",
        remarkFromVerifier: "",
        schoolAddress: "",
        yearOfPassing: "",
        enrollmentNumber: "",
        regestrationNumber: "",
        lastExamHallTicketNumber: "",
        courseStartDate: "",
        courseEndDate: "",
        courseType: "",
        rollNumber: "",
        contactDetails: "",
        modeOfVerification: "",
        verifiedBy: "",
        grade: "",
        modeOfEducation: "",
        additionalRemarks: "",
        uploadAddressProof: "",
        reportUrl: [],
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
      },
      verificationDetails: {
        candidateNameRemark: "",
        remarkAboutUniversityRemark: "",
        reportRemark: "",
        remarkDate: "",
        selectRemark: "",
        annexure: "",
        qualificationRemark: "",
        unversityNameRemark: "",
        startDateRemark: "",
        endDateRemark: "",
        unversityAddressRemark: "",
        instituteNameRemark: "",
        degreeNameRemark: "",
        universityNameRemark: "",
        schoolNameRemark: "",
        schoolAddressRemark: "",
        yearOfPassingRemark: "",
        enrollmentNumberRemark: "",
        regestrationNumberRemark: "",
        lastExamHallTicketNumberRemark: "",
        modeOfVerificationRemark: "",
        verifiedByRemark: "",
        rollNumberRemark: "",
        gradeRemark: "",
        modeOfEducationRemark: "",
        additionalRemarksRemark: "",
        uploadAddressProofRemark: "",
        reportUrl: [],
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
      },
      reason: {
        reasonOfQualification: "",
        reasonOfSchoolName: "",
        reasonOfUnversityName: "",
        reasonOfYearOfPassing: "",
        reasonOfRollNumber: "",
      },
      location: {
        latitute: "",
        longitute: "",
      },
    };
    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Education Added successfully");
        setAddNewLoading(false);
        geteducationCheckInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setAddNewLoading(false);
      });
  };
  useEffect(() => {}, [educationCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      return selectedStaff ? selectedStaff : null;
    }
  };

  const handlePrefillDataForInternal = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffListInternal.length > 0) {
      const selectedStaff = BCAStaffListInternal.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      console.log("selectedStaffOnPrefill>>", selectedStaff);

      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillEducationStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.EducationTaskId
      );
      setSelectedStaffForEducationCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prefillInternalEducationStaff = async () => {
    try {
      const selectedstaff = await handlePrefillDataForInternal(
        userVerificationRequestInfo?.EducationTaskId
      );
      console.log("selectedstaffInternal>>", selectedstaff);

      setSelectedStaffForInternalEducationCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForEducation = async () => {
      if (userVerificationRequestInfo?.EducationTaskId !== null) {
        await prefillEducationStaff(
          userVerificationRequestInfo?.EducationTaskId
        );
      }
    };

    fetchStaffForEducation();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  useEffect(() => {
    const fetchStaffForEducation = async () => {
      if (userVerificationRequestInfo?.EducationTaskId !== null) {
        await prefillInternalEducationStaff(
          userVerificationRequestInfo?.EducationTaskId
        );
      }
    };

    fetchStaffForEducation();
  }, [userVerificationRequestInfo !== "" && BCAStaffListInternal.length > 0]);

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Education Check</h5>
          </div>

          <div className="flex max-sm:flex-col gap-2">
            {educationCheckInfo.length > 0 &&
            (user?.role === "BCA" ||
              user?.role === "Vendor" ||
              user?.role === "FieldCoordinator" ||
              user?.role === "Coordinator") ? (
              <>
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForInternalEducationCheck}
                  onChange={(e) => {
                    setSelectedStaffForInternalEducationCheck(e.value);
                  }}
                  options={BCAStaffListInternal}
                  optionLabel="firstName"
                  className="w-[200px] h-[45px] max-sm:w-full"
                  placeholder="Assign Internal"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={educationCheckInfo.length === 0}
                />
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForEducationCheck}
                  onChange={(e) => {
                    setSelectedStaffForEducationCheck(e.value);
                  }}
                  options={BCAStaffList}
                  optionLabel="firstName"
                  className="w-[200px] h-[45px] max-sm:w-full"
                  placeholder="Assign External"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={educationCheckInfo.length === 0}
                />
              </>
            ) : (
              <></>
            )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              <Button
                label="Activity"
                type="button"
                onClick={() => {
                  getActivityDetails(userVerificationRequestInfo?._id);
                  setShowActivity(!showActivity);
                }}
                icon={showActivity ? "pi pi-angle-down" : "pi pi-angle-right"}
                iconPos="right"
                className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />

              {educationCheckInfo.length > 0 &&
              (user?.role === "BCA" ||
                user?.role === "Vendor" ||
                user?.role === "FieldCoordinator" ||
                user?.role === "Coordinator") ? (
                <Button
                  label="Assign"
                  type="button"
                  loading={assignLoading}
                  onClick={() => handleCreateTaskAPI()}
                  className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              ) : (
                <></>
              )}

              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createEducationCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
        {!loading && user?.role !== "BCAStaff" && (
          <div className="flex flex-row gap-[10px]">
            {missingEducations.length > 0 &&
              missingEducations.map((option, index) => (
                <Button
                  key={index}
                  label={option}
                  onClick={() => createEducationCheck(option)}
                  type="button"
                  className="w-[150px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60 mb-2"
                />
              ))}
          </div>
        )}
      </div>

      {loading ? (
        <>
          <div className="flex justify-center items-center h-screen w-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              strokeWidth="4"
              animationDuration=".7s"
            />
          </div>
        </>
      ) : (
        <>
          {educationCheckInfo.length === 0 ? (
            <>
              <div className="flex items-center">
                <NoData info="Ooops, we could not find any education check details" />
              </div>
            </>
          ) : (
            <>
              {educationCheckInfo.length > 0 &&
                educationCheckInfo !== null &&
                educationCheckId !== undefined &&
                educationCheckId !== null &&
                educationCheckInfo !== undefined && (
                  <>
                    <EducationDetail
                      userVerificationDetail={userVerificationRequestInfo?._id}
                      educationCheckId={educationCheckId}
                      educationCheckInfo={educationCheckInfo}
                      geteducationCheckInfo={geteducationCheckInfo}
                      taskDetail={taskDetail}
                      activityDetails={activityDetails}
                      showActivity={showActivity}
                      getActivityDetails={getActivityDetails}
                      flag={flag}
                    />
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
}
