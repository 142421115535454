import React, { useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Page,
  Document,
  PDFViewer,
} from "@react-pdf/renderer";

import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import pamac_logo from "../../../assets/images/pamac_logo.png";
import pamac_footer from "../../../assets/images/pamac_footer.png";
import { PamacEducationDetailCheck } from "./PamacEducationDetailCheck.jsx";
import { PamacEmploymentDetailCheck } from "./PamacEmploymentDetailCheck.jsx";
import { PamacReferenceDetailCheck } from "./PamacReferenceDetailCheck.jsx";
import { PamacAddressDetailCheck } from "./PamacAddressDetailCheck.jsx";
import { PamacCourtDetailCheck } from "./PamacCourtDetailCheck.jsx";
import { PamacAadharDetailCheck } from "./PamacAadharDetailCheck.jsx";
import { PamacPanDetailCheck } from "./PamacPanDetailCheck.jsx";
import { PamacDrivingLicenseDetailCheck } from "./PamacDrivingLicenseDetailCheck.jsx";
import { PamacCriminalDetailCheck } from "./PamacCriminalDetailCheck.jsx";
import { PamacCreditDetailCheck } from "./PamacCreditDetailCheck.jsx";
import { PamacGlobalDatabaseDetailCheck } from "./PamacGlobalDatabaseDetailCheck.jsx";
import { PamacDisclaimer } from "./PamacDisclaimer.jsx";

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

// Define styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 75, // Increased top padding for header
    paddingBottom: 40, // Increased bottom padding for footer
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    position: "absolute",
    top: 5,
    left: 40,
    right: 40,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 40,
    right: 40,
  },
  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: { flexDirection: "row", marginTop: 24 },

  logo: { width: 100, height: 70 },
  // heading: {
  //   textAlign: "center",
  //   marginTop: 40,
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   textDecoration: "underline",
  // },
  headingContainer: {
    backgroundColor: "#d3d3d3",
    border: "1px solid black",
    padding: 2,
    width: "100%",
    textAlign: "center",
    marginTop: 40,
  },
  heading: {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    textDecoration: "underline",
  },

  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: 15,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  labelCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontWeight: 900,
    fontSize: 8,
  },
  synopsis: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  synopsistableCell: {
    flex: 1, // Normal cell width
    border: "1px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    height: "90px",
    bottom: 30,
    left: 0,
    right: 0,
  },
});

const checksStyle = StyleSheet.create({
  checksContainer: {
    marginTop: "20px",
  },
});

const sypnosisStyles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // marginTop: 10,
    border: "1px solid black",
    // height: "400px",
  },

  // Header Row Style
  headerRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#d3d3d3", // Light gray background for header
    borderBottom: "1px solid black",
    minHeight: 30,
  },

  // Header Cell Style
  headerCell: {
    flex: 1,
    textAlign: "center",
    padding: 5,
    fontSize: 10,
    fontWeight: "bold",
    borderRight: "1px solid black",
  },

  // Row Style for Data
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
    minHeight: 30,
  },

  // Cell Style for Data Rows
  cell: {
    flex: 1,
    textAlign: "center",
    padding: 8,
    fontSize: 10,
    borderRight: "1px solid black",
  },
});

const Header = () => (
  <View style={styles.headerContainer} fixed>
    <CompanyTitle />
  </View>
);
const Footer = () => (
  <View style={styles.footerContainer} fixed>
    <Image style={styles.footer} src={pamac_footer} />
  </View>
);

const CompanyTitle = () => (
  <View style={styles.titleContainer} fixed>
    <View style={styles.spaceBetween}>
      <Text style={styles.reportTitle}>Stricly Confidential</Text>
      <Image style={styles.logo} src={pamac_logo} />
    </View>
  </View>
);
const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const GeneralInfo = ({ verificationInfo }) => (
  <View style={styles.table}>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Name of the Candidate
      </Text>
      <Text style={styles.tableCell}>
        {`${verificationInfo?.userInfo?.firstName} ${verificationInfo?.userInfo?.lastName}`}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: "900",
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Report Status
      </Text>
      <Text
        style={[
          styles.tableCell,
          { backgroundColor: getStatusColor(verificationInfo?.status) },
        ]}
      >
        {verificationInfo?.status}
      </Text>
    </View>

    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Name of the Client
      </Text>
      <Text style={styles.tableCell}>
        {verificationInfo?.companyInfo?.name}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Date of Initiation
      </Text>
      <Text style={styles.tableCell}>09 November 2016</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Employee Code
      </Text>
      <Text style={styles.tableCell}>123</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Date Insufficiency Cleared
      </Text>
      <Text style={styles.tableCell}>Not applicable</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Reference Number
      </Text>
      <Text style={styles.tableCell}>ABC/MUM/000</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
          backgroundColor: "#d3d3d3",
        }}
      >
        Date of Report
      </Text>
      <Text style={styles.tableCell}>
        {formatDate(verificationInfo?.finalReportDate)}
      </Text>
    </View>
  </View>
);

const Synopsis = ({
  selectedChecks,
  educationData,
  experienceData,
  referenceData,
  addressData,
  courtData,
  aadharData,
  panData,
  dlData,
  criminalData,
  creditData,
  globalDatabaseData,
}) => {
  const ROW_HEIGHT = 30; // Height of each row in pixels
  const HEADER_HEIGHT = 30; // Height of header
  const MAX_TABLE_HEIGHT = 390; // Maximum height before forcing a break

  // Function to create table sections based on height
  const createTableSections = () => {
    // const allRows = [];
    let currentHeight = HEADER_HEIGHT;
    let currentSection = [];
    const sections = [];

    // Helper to add a row and check height
    const addRow = (row) => {
      currentHeight += ROW_HEIGHT;
      currentSection.push(row);

      if (currentHeight >= MAX_TABLE_HEIGHT) {
        sections.push([...currentSection]);
        currentSection = [];
        currentHeight = HEADER_HEIGHT; // Reset height, accounting for new header
      }
    };

    // Add education verifications
    if (selectedChecks?.education) {
      educationData?.educationInfo?.forEach((item) => {
        addRow({
          type: "Education Verification",
          detail: item?.education?.qualification,
          status: item?.education?.status,
        });
      });
    }

    // Add employment verifications
    if (selectedChecks?.employment) {
      experienceData?.experienceInfo?.forEach((item) => {
        addRow({
          type: "Employment Verification",
          detail: item?.experience?.companyName,
          status: item?.experience?.status,
        });
      });
    }

    // Add reference verifications
    if (selectedChecks?.reference) {
      referenceData?.referenceInfo?.forEach((item) => {
        addRow({
          type: "Reference Verification",
          detail: item?.reference?.fullName,
          status: item?.reference?.status,
        });
      });
    }

    // Add address verifications
    if (selectedChecks?.address) {
      addressData?.addressInfo?.forEach((item) => {
        addRow({
          type: `${item?.address?.typeOfAddress} Verification`,
          detail: item?.address?.city,
          status: item?.address?.status,
        });
      });
    }

    // Add Court verifications
    if (selectedChecks?.court) {
      courtData?.courtInfo?.forEach((item) => {
        addRow({
          type: "Court Verification",
          detail: item?.court?.city,
          status: item?.court?.status,
        });
      });
    }

    if (selectedChecks?.aadhar && aadharData?.aadharInfo?.identity) {
      addRow({
        type: "Aadhar Verification",
        detail: aadharData?.aadharInfo?.identity?.cardNumber,
        status: aadharData?.aadharInfo?.identity?.status,
      });
    }

    if (selectedChecks?.pan && panData?.panInfo?.identity) {
      addRow({
        type: "Pan Verification",
        detail: panData?.panInfo?.identity?.panNumber,
        status: panData?.panInfo?.identity?.status,
      });
    }

    if (selectedChecks?.dl && dlData?.dlInfo?.identity) {
      addRow({
        type: "Driving License Verification",
        detail: dlData?.dlInfo?.identity?.dlNumber,
        status: dlData?.dlInfo?.identity?.status,
      });
    }

    // Add Criminal verifications
    if (selectedChecks?.criminal) {
      criminalData?.criminalInfo?.forEach((item) => {
        addRow({
          type: "Criminal Verification",
          detail: item?.criminal?.city,
          status: item?.criminal?.status,
        });
      });
    }

    // Add Credit verifications
    if (selectedChecks?.credit) {
      creditData?.creditInfo?.forEach((item) => {
        addRow({
          type: "Credit Verification",
          detail: item?.credit?.name,
          status: item?.credit?.status,
        });
      });
    }

    // Add Global Database verifications
    if (selectedChecks?.globalDatabase) {
      globalDatabaseData?.globalDatabaseInfo?.forEach((item) => {
        addRow({
          type: "Database Verification",
          detail: "Indian & Global Database Checks",
          // detail: item?.globalDatabase?.candidateName,
          status: item?.globalDatabase?.status,
        });
      });
    }

    // Add any remaining rows as the last section
    if (currentSection.length > 0) {
      sections.push(currentSection);
    }

    return sections;
  };

  const renderTable = (rows, index) => (
    <View
      style={[sypnosisStyles.table, index > 0 ? { marginTop: 20 } : null]}
      break={index > 0}
    >
      {/* Header */}
      <View style={sypnosisStyles.headerRow} fixed>
        <Text style={sypnosisStyles.headerCell}>Verification</Text>
        <Text style={sypnosisStyles.headerCell}>Details</Text>
        <Text style={sypnosisStyles.headerCell}>Status</Text>
      </View>

      {/* Rows */}
      {rows.map((row, rowIndex) => (
        <View key={`row-${rowIndex}`} style={sypnosisStyles.row} wrap={false}>
          <Text style={sypnosisStyles.cell}>{row.type}</Text>
          <Text style={sypnosisStyles.cell}>{row.detail}</Text>
          <Text
            style={[
              sypnosisStyles.cell,
              { backgroundColor: getStatusColor(row.status) },
            ]}
          >
            {row.status}
          </Text>
        </View>
      ))}
    </View>
  );

  const tableSections = createTableSections();

  return (
    <View>
      {tableSections.map((rows, index) => (
        <View key={`section-${index}`}>{renderTable(rows, index)}</View>
      ))}
    </View>
  );
};

// const Synopsis = ({
//   selectedChecks,
//   educationData,
//   experienceData,
//   referenceData,
//   addressData,
//   courtData,
//   aadharData,
//   panData,
//   dlData,
//   criminalData,
//   creditData,
// }) => {
//   return (
//     <View style={sypnosisStyles.table} wrap>
//       {/* Header */}
//       <View style={sypnosisStyles.headerRow}>
//         <Text style={sypnosisStyles.headerCell}>Verification</Text>
//         <Text style={sypnosisStyles.headerCell}>Details</Text>
//         <Text style={sypnosisStyles.headerCell}>Status</Text>
//       </View>

//       {/* Education Verification */}
//       {selectedChecks?.education &&
//         educationData?.educationInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Education Verification</Text>
//             <Text style={sypnosisStyles.cell}>
//               {item?.education?.qualification}
//             </Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.education?.status) },
//               ]}
//             >
//               {item?.education?.status}
//             </Text>
//           </View>
//         ))}

//       {/* Employment Verification */}
//       {selectedChecks?.employment &&
//         experienceData?.experienceInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Employment Verification</Text>
//             <Text style={sypnosisStyles.cell}>
//               {item?.experience?.companyName}
//             </Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.experience?.status) },
//               ]}
//             >
//               {item?.experience?.status}
//             </Text>
//           </View>
//         ))}

//       {/* Reference Verification */}
//       {selectedChecks?.reference &&
//         referenceData?.referenceInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Reference Verification</Text>
//             <Text style={sypnosisStyles.cell}>{item?.reference?.fullName}</Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.reference?.status) },
//               ]}
//             >
//               {item?.reference?.status}
//             </Text>
//           </View>
//         ))}

//       {/* Address Verification */}
//       {selectedChecks?.address &&
//         addressData?.addressInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Address Verification</Text>
//             <Text style={sypnosisStyles.cell}>{item?.address?.city}</Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.address?.status) },
//               ]}
//             >
//               {item?.address?.status}
//             </Text>
//           </View>
//         ))}

//       {/* Court Verification */}
//       {selectedChecks?.court &&
//         courtData?.courtInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Court Verification</Text>
//             <Text style={sypnosisStyles.cell}>{item?.court?.city}</Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.court?.status) },
//               ]}
//             >
//               {item?.court?.status}
//             </Text>
//           </View>
//         ))}

//       {/* Aadhar Verification */}
//       {selectedChecks?.aadhar && aadharData?.aadharInfo?.identity && (
//         <View style={sypnosisStyles.row}>
//           <Text style={sypnosisStyles.cell}>Aadhar Verification</Text>
//           <Text style={sypnosisStyles.cell}>
//             {aadharData?.aadharInfo?.identity?.cardNumber}
//           </Text>
//           <Text
//             style={[
//               sypnosisStyles.cell,
//               {
//                 backgroundColor: getStatusColor(
//                   aadharData?.aadharInfo?.identity?.status
//                 ),
//               },
//             ]}
//           >
//             {aadharData?.aadharInfo?.identity?.status}
//           </Text>
//         </View>
//       )}

//       {/* Pan Verification */}
//       {selectedChecks?.pan && panData?.panInfo?.identity && (
//         <View style={sypnosisStyles.row}>
//           <Text style={sypnosisStyles.cell}>Pan Verification</Text>
//           <Text style={sypnosisStyles.cell}>
//             {panData?.panInfo?.identity?.panNumber}
//           </Text>
//           <Text
//             style={[
//               sypnosisStyles.cell,
//               {
//                 backgroundColor: getStatusColor(
//                   panData?.panInfo?.identity?.status
//                 ),
//               },
//             ]}
//           >
//             {panData?.panInfo?.identity?.status}
//           </Text>
//         </View>
//       )}
//       {/* Driving License Verification */}
//       {selectedChecks?.dl && dlData?.dlInfo?.identity && (
//         <View style={sypnosisStyles.row}>
//           <Text style={sypnosisStyles.cell}>Driving License Verification</Text>
//           <Text style={sypnosisStyles.cell}>
//             {dlData?.dlInfo?.identity?.dlNumber}
//           </Text>
//           <Text
//             style={[
//               sypnosisStyles.cell,
//               {
//                 backgroundColor: getStatusColor(
//                   dlData?.dlInfo?.identity?.status
//                 ),
//               },
//             ]}
//           >
//             {dlData?.dlInfo?.identity?.status}
//           </Text>
//         </View>
//       )}

//       {/* Criminal Verification */}
//       {selectedChecks?.criminal &&
//         criminalData?.criminalInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Criminal Verification</Text>
//             <Text style={sypnosisStyles.cell}>{item?.criminal?.city}</Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.criminal?.status) },
//               ]}
//             >
//               {item?.criminal?.status}
//             </Text>
//           </View>
//         ))}
//       {/* Credit Verification */}
//       {selectedChecks?.credit &&
//         creditData?.creditInfo?.map((item, index) => (
//           <View key={index} style={sypnosisStyles.row}>
//             <Text style={sypnosisStyles.cell}>Credit Verification</Text>
//             <Text style={sypnosisStyles.cell}>{item?.credit?.name}</Text>
//             <Text
//               style={[
//                 sypnosisStyles.cell,
//                 { backgroundColor: getStatusColor(item?.credit?.status) },
//               ]}
//             >
//               {item?.credit?.status}
//             </Text>
//           </View>
//         ))}
//     </View>
//   );
// };

// Report document component
const ReportDocument = ({
  // data,
  verificationInfo,
  selectedChecks,
  educationData,
  experienceData,
  referenceData,
  addressData,
  courtData,
  aadharData,
  panData,
  dlData,
  criminalData,
  creditData,
  globalDatabaseData,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header />
      <View style={styles.headingContainer}>
        <Text style={styles.heading}>Final Background Report</Text>
      </View>

      <GeneralInfo verificationInfo={verificationInfo} />
      <Text style={styles.synopsis}>Synopsis</Text>
      <Synopsis
        selectedChecks={selectedChecks}
        educationData={educationData}
        experienceData={experienceData}
        referenceData={referenceData}
        addressData={addressData}
        courtData={courtData}
        aadharData={aadharData}
        panData={panData}
        dlData={dlData}
        criminalData={criminalData}
        creditData={creditData}
        globalDatabaseData={globalDatabaseData}
      />

      <Footer />
    </Page>
    {selectedChecks?.education && educationData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.education && educationData && (
            <>{educationData.renderEducationDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
    {selectedChecks?.employment && experienceData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.employment && experienceData && (
            <>{experienceData.renderExperienceDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
    {selectedChecks?.reference && referenceData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.reference && referenceData && (
            <>{referenceData.renderReferenceDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
    {selectedChecks?.address && addressData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.address && addressData && (
            <>{addressData.renderAddressDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
    {selectedChecks?.court && courtData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.court && courtData && (
            <>{courtData.renderCourtDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.aadhar && aadharData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.aadhar && aadharData && (
            <>{aadharData.renderAadharDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.pan && panData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.pan && panData && <>{panData.renderPanDetails()}</>}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.dl && dlData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.dl && dlData && <>{dlData.renderDlDetails()}</>}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.criminal && criminalData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.criminal && criminalData && (
            <>{criminalData.renderCriminalDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.credit && creditData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.credit && creditData && (
            <>{creditData.renderCreditDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.globalDatabase && globalDatabaseData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.globalDatabase && globalDatabaseData && (
            <>{globalDatabaseData.renderGlobalDatabaseDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    <Page size="A4" style={styles.page} wrap={true}>
      <Header />
      <PamacDisclaimer />
      <Footer />
    </Page>
  </Document>
);

// Sample data structure
// const sampleData = {
//   candidateName: "John Doe",
//   clientName: "ABC Limited",
//   reportStatus: "Verified Clear",
//   dateOfInitiation: "10 March 2024",
//   education: [
//     {
//       qualification: "B.Sc",
//       schoolName: "University A",
//       yearOfPassing: "2014",
//       status: "Verified Clear",
//     },
//     {
//       qualification: "M.Sc",
//       schoolName: "University B",
//       yearOfPassing: "2016",
//       status: "Verified Clear",
//     },
//   ],
//   employment: [
//     {
//       companyName: "Company X",
//       role: "Engineer",
//       period: "2015-2018",
//       status: "Verified Clear",
//     },
//     {
//       companyName: "Company Y",
//       role: "Manager",
//       period: "2018-2021",
//       status: "Verified Clear",
//     },
//   ],
// };

// Main component
export const PamacReport = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    education: true,
    employment: true,
    reference: true,
    address: true,
    court: true,
    aadhar: true,
    pan: true,
    dl: true,
    criminal: true,
    credit: true,
    globalDatabase: true,
  });
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [referenceData, setReferenceData] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [courtData, setCourtData] = useState(null);
  const [aadharData, setAadharData] = useState(null);
  const [panData, setPanData] = useState(null);
  const [dlData, setDlData] = useState(null);
  const [criminalData, setCriminalData] = useState(null);
  const [creditData, setCreditData] = useState(null);
  const [globalDatabaseData, setGlobalDatabaseData] = useState(null);

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("VerificationInfor>>>>", verificationInfo);
  }, [verificationInfo]);

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
  }, [location?.state?.id]);

  const handleEducationDataUpdate = (data) => {
    setEducationData(data);
  };

  useEffect(() => {
    console.log("DTATTATATT>>>>>", educationData);
  }, [educationData]);
  useEffect(() => {
    console.log("courtData>>>>>", courtData);
  }, [courtData]);

  const handleExperienceDataUpdate = (data) => {
    setExperienceData(data);
  };

  const handleReferenceDataUpdate = (data) => {
    setReferenceData(data);
  };
  const handleAddressDataUpdate = (data) => {
    setAddressData(data);
  };

  const handleCourtDataUpdate = (data) => {
    setCourtData(data);
  };

  const handleAadharDataUpdate = (data) => {
    setAadharData(data);
  };

  const handlePanDataUpdate = (data) => {
    setPanData(data);
  };

  const handleDlDataUpdate = (data) => {
    setDlData(data);
  };

  const handleCriminalDataUpdate = (data) => {
    setCriminalData(data);
  };

  const handleCreditDataUpdate = (data) => {
    setCreditData(data);
  };

  const handleGlobalDatabaseDataUpdate = (data) => {
    setGlobalDatabaseData(data);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  return (
    <div>
      <h1>Generate Background Report</h1>

      {/* Options to select sections for interim report */}
      <div>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.education}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                education: !prev.education,
              }))
            }
          />{" "}
          Education
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.employment}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                employment: !prev.employment,
              }))
            }
          />{" "}
          Employment
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.court}
            onChange={() =>
              setSelectedChecks((prev) => ({ ...prev, court: !prev.court }))
            }
          />{" "}
          Court
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.reference}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                reference: !prev.reference,
              }))
            }
          />
          Reference
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.address}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                address: !prev.address,
              }))
            }
          />
          Address
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.aadhar}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                aadhar: !prev.aadhar,
              }))
            }
          />
          Aadhar
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.pan}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                pan: !prev.pan,
              }))
            }
          />
          Pan
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.dl}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                dl: !prev.dl,
              }))
            }
          />
          Driving Licnese
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.criminal}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                criminal: !prev.criminal,
              }))
            }
          />
          Criminal
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.credit}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                credit: !prev.credit,
              }))
            }
          />
          Credit
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.globalDatabase}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                globalDatabase: !prev.globalDatabase,
              }))
            }
          />
          Global Database
        </label>
      </div>

      <button onClick={togglePreview}>
        {isPreview ? "Close Preview" : "Preview Report"}
      </button>
      <PamacEducationDetailCheck onDataUpdate={handleEducationDataUpdate} />
      <PamacEmploymentDetailCheck onDataUpdate={handleExperienceDataUpdate} />
      <PamacReferenceDetailCheck onDataUpdate={handleReferenceDataUpdate} />
      <PamacAddressDetailCheck onDataUpdate={handleAddressDataUpdate} />
      <PamacCourtDetailCheck onDataUpdate={handleCourtDataUpdate} />
      <PamacAadharDetailCheck onDataUpdate={handleAadharDataUpdate} />
      <PamacPanDetailCheck onDataUpdate={handlePanDataUpdate} />
      <PamacDrivingLicenseDetailCheck onDataUpdate={handleDlDataUpdate} />
      <PamacCriminalDetailCheck onDataUpdate={handleCriminalDataUpdate} />
      <PamacCreditDetailCheck onDataUpdate={handleCreditDataUpdate} />
      <PamacGlobalDatabaseDetailCheck
        onDataUpdate={handleGlobalDatabaseDataUpdate}
      />

      {/* PDF Preview */}
      {isPreview && (
        <div style={{ height: "500px", marginTop: "20px" }}>
          <PDFViewer width="100%" height="500px">
            <ReportDocument
              //   data={sampleData}
              verificationInfo={verificationInfo}
              selectedChecks={selectedChecks}
              educationData={educationData}
              experienceData={experienceData}
              referenceData={referenceData}
              addressData={addressData}
              courtData={courtData}
              aadharData={aadharData}
              panData={panData}
              dlData={dlData}
              criminalData={criminalData}
              creditData={creditData}
              globalDatabaseData={globalDatabaseData}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default PamacReport;
