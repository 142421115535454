import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EducationCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const GvsEducationDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [educationInfo, setEducationInfo] = useState([]);

  const getEducationInfo = (id) => {
    if (id) {
      ApiService.get(`educationCheck/${id}`)
        .then((res) => {
          setEducationInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getEducationInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (educationInfo.length > 0) {
      onDataUpdate({
        educationInfo,
        renderEducationDetails: () => renderEducationDetails(),
      });
    }
  }, [educationInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image
        src={imgSrc}
        alt={alt || "image"}
        className={className || ""}
        style={{ height: "550px" }}
      />
    );
  };

  const renderEducationDetails = () => {
    return (
      <>
        {educationInfo?.length > 0 && (
          <>
            {educationInfo.map((educationEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}

                <View style={EducationCheckStyles.table}>
                  <Text
                    style={{
                      textAlign: "center",
                      // textDecoration: "underline",
                      backgroundColor: "#c5d9eb",
                      fontSize: 13,
                    }}
                  >
                    Educational Qualification Check
                  </Text>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "bold",
                        backgroundColor: "#c5d9eb",
                      }}
                    >
                      Respondent Name
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      {educationEvidence?.education?.respondentName}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "bold",
                        backgroundColor: "#c5d9eb",
                      }}
                    >
                      Respondent Designation
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      {educationEvidence?.education?.respondentDesignation}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 2,
                        fontSize: 15,
                        fontWeight: "bold",
                        backgroundColor: "#c5d9eb",
                      }}
                    >
                      Feedback
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Details of Verification
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Candidate’s Claim
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Feedback
                    </Text>
                  </View>

                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "900",
                      }}
                    >
                      Institute Name
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      {educationEvidence?.education?.schoolName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      {educationEvidence?.verificationDetails?.schoolNameRemark}
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "900",
                      }}
                    >
                      Qualification Obtained
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      {educationEvidence?.education?.qualification}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.qualificationRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "900",
                      }}
                    >
                      Year of Passing
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      {educationEvidence?.education?.yearOfPassing}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      {
                        educationEvidence?.verificationDetails
                          ?.yearOfPassingRemark
                      }
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 12,
                        fontWeight: "900",
                      }}
                    >
                      Report Remarks
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 1,
                        fontSize: 13,
                      }}
                    >
                      Confirmation:{" "}
                      <Text
                        style={{
                          backgroundColor:
                            educationEvidence?.verificationDetails
                              ?.reportRemark === "Verified Clear"
                              ? "green"
                              : educationEvidence?.verificationDetails
                                ?.reportRemark === "Major Discrepancy"
                                ? "red"
                                : educationEvidence?.verificationDetails
                                  ?.reportRemark === "Pending"
                                  ? "yellow"
                                  : educationEvidence?.verificationDetails
                                    ?.reportRemark === "Minor Discrepancy"
                                    ? "orange"
                                    : educationEvidence?.verificationDetails
                                      ?.reportRemark === "Inconclusive"
                                      ? "pink"
                                      : "transparent",
                          color: "black",
                          padding: 5,
                          borderRadius: 5,
                        }}
                      >
                        {educationEvidence?.verificationDetails?.reportRemark}
                      </Text>
                    </Text>
                  </View>
                  <View style={EducationCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "600px",
                        // border: "0.5px solid black",
                        textAlign: "center",
                        padding: 1,
                        fontSize: 10,
                      }}
                    >
                      Please note that the verification has been Confirmed on
                      date {educationEvidence?.verificationDetails?.remarkDate}{" "}
                      The Degree Certificate provided by{" "}
                      {educationEvidence?.education?.schoolName} is absolutely
                      correct & the Document is{" "}
                      {educationEvidence?.verificationDetails?.selectRemark} in
                      the record of year{" "}
                      {educationEvidence?.education?.yearOfPassing} For detail
                      understanding please refer to the Annexure -{" "}
                      {educationEvidence?.verificationDetails?.annexure} of the
                      final Report.
                    </Text>
                  </View>
                </View>

                {/* <View break /> */}

                {educationEvidence?.education?.uploadProofUrl?.map(
                  (url, proofIndex) => (
                    <View
                      key={proofIndex}
                      style={EducationCheckStyles.centeredImageContainer}
                      wrap={false} // Prevent image splitting between pages
                      break // Page break to place image on a new page
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          margin: "17px 0 0 0",
                          textDecoration: "underline",
                          textAlign: "center",
                          display: "block",
                          marginTop: "50px",
                          marginBottom: "20px",
                        }}
                      >
                        Annexure-{" "}
                        {educationEvidence?.verificationDetails?.annexure}
                      </Text>
                      <AsyncImage
                        fileUrl={url}
                        alt={`Education evidence ${proofIndex + 1}`}
                      />
                    </View>
                  )
                )}
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
