import axios from "axios";
import AuthService from "./AuthService.js";

export default class ApiService {
  // THIS CONFIG FUNCTION IS USED TO RETURN HEADERS
  static getConfig() {
    const authService = AuthService.get();
    const connectedAddress = localStorage.getItem("account");

    var config = {
      headers: {
        Authorization: `Bearer ${authService.isAuthenticated()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(connectedAddress
          ? {
            "X-Connected-Address": connectedAddress,
          }
          : undefined),
      },
    };

    return config;
  }
  // POST REQUEST FUNCTION FOR DB
  static post(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`,
      body,
      config
    );
  }
  // POST REQUEST FUNCTION FOR SMART-CONTRACT
  static ledgerPost(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/${path}`, body, config);
  }
  // GET REQUEST FUNCTION FOR SMART-CONTRACT
  static ledgerGet(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/${path}`, body, config);
  }
  // GET REQUEST FUNCTION
  static get(path) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`,
      config
    );
  }
  // GET REQUEST FUNCTION BY FILTER FOR PRODUCT
  static getProductsByFilter(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    if (!obj.fields && !obj.filter) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
        config
      );
    }
    if (obj.isSegMintVerified) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&isSegMintVerified=true&fields=${obj.fields}&page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
        config
      );
    }
    if (obj.isSegMintVerified) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&isSegMintVerified=true&fields=${obj.fields}&page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
        config
      );
    }
    if (obj.isFeatured) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&isFeatured=true&fields=${obj.fields}&page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
        config
      );
    }
    if (obj.isPromoted) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&isPromoted=true&fields=${obj.fields}&page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
        config
      );
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&fields=${obj.fields}&page=${obj.page}&populate=${obj?.populate}&selectPopulate=${obj?.selectPopulate}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
      config
    );
  }
  // GET REQUEST FUNCTION BY FILTER, POPULATE & SELECT-POPULATE
  static getByFilter(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&fields=${obj.fields}&page=${obj.page}&populate=${obj?.populate}&selectPopulate=${obj?.selectPopulate}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
      config
    );
  }
  static getAllQueuedUsers(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    if (obj.find) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&find=${obj.find}`,
        config
      );
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}`,
      config
    );
  }
  static getAllUsers(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    if (obj.find) {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?find=${obj.find}&page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}`,
        config
      );
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}`,
      config
    );
  }

  static getLastWeekBuyHistoty(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&fields=${obj.fields}&page=${obj.page}&populate=${obj?.populate}&selectPopulate=${obj?.selectPopulate}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}&fromDate=${obj.fromDate}`,
      config
    );
  }
  // GET REQUEST
  static getForProfile(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?userId=${obj.userId}&${obj.fieldName}=${obj.fields}&page=${obj.page}&populate=${obj?.populate}&selectPopulate=${obj?.selectPopulate}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
      config
    );
  }
  // GET REQUEST FUNCTION USING POPULATE & FILTER
  static getByPopulate(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?filter=${obj.filter}&fields=${obj.fields}&populate=${obj.populate}&page=${obj.page}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
      config
    );
  }
  // GET REQUEST FUNCTION USING POPULATE & SELECT-POPULATE
  static getByPopulateFn(path, obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}?page=${obj.page}&populate=${obj?.populate}&selectPopulate=${obj?.selectPopulate}&limit=${obj.limit}&sort=${obj.sort}&order=${obj.order}`,
      config
    );
  }
  // UPDATE REQUEST FUNCTION USING PUT
  static put(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`,
      body,
      config
    );
  }
  // UPDATE REQUEST FUNCTION USING PATCH
  static patch(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`,
      body,
      config
    );
  }
  // DELETE REQUEST FUNCTION
  static delete(path) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`,
      config
    );
  }
  // GET PRODUCT TO VERIFY BY ADMIN
  static getProductVerified(obj) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/productVerify/${obj}`,
      config
    );
  }
  // get autocomplete
  static getAutocompleteInfo(fields, inputValue) {
    return axios.get(
      `https://api.peopledatalabs.com/v5/autocomplete?field=${fields}&text=${inputValue}&size=10&pretty=false`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key":
            "9d8480c19a6848b98f8b6de9880fdeca765a9078a4312c6fef6e95098e8bf1d3",
        },
      }
    );
  }

  // update the verified product details(for admin role)
  static updateProductVerified(productId, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated()) {
      var config = this.getConfig();
    }
    return axios.patch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/updateProductVerified/${productId}`,
      body,
      config
    );
  }
}
