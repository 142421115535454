import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { SplitButton } from "primereact/splitbutton";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
// import { InputTextarea } from "primereact/inputtextarea";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";

const ReferenceDetails = ({
  userVerificationDetail,
  taskDetail,
  referenceCheckInfo,
  getReferenceCheckInfo,
  activityDetails,
  showActivity,
  getActivityDetails,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const categories = [{ name: "Friends/Colleague" }, { name: "Others" }];
  // const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activity, setActivity] = useState("");
  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));
  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `referenceCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getReferenceCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
        case "file":
          SetFileUplaod(uploadedFileUrls);
          toast.success("File successfully uploaded");
          break;
        case "uploadProof":
          SetUploadProof(uploadedFileUrls);
          toast.success("Upload proof successfully uploaded");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  // const handleEdit = () => {
  //   setEditable(!editable);
  // };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Reference",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const getReferenceInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "referenceCheck/link";
    } else {
      url = "referenceCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {
        // res?.data?.docs[0]; // Assuming there's only one item in the array
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    if (type === "file") {
      const updatedUrls = item?.reference?.reportUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateReferenceFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.reference?.uploadProofUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateReferenceProofArray(e, id, item, updatedUrls);
    }
  };

  const updateReferenceFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "referenceCheck/link";
    } else {
      url = "referenceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      reference: {
        ...item.reference,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.reference.remark,
        uploadProofUrl: [
          ...new Set([...item.reference.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getReferenceInfo(userVerificationDetail);
      toast.success("Reference details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getReferenceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateReferenceProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "referenceCheck/link";
    } else {
      url = "referenceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      reference: {
        ...item.reference,
        reportUrl: [...new Set([...item.reference.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.reference.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getReferenceInfo(userVerificationDetail);
      toast.success("Reference details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getReferenceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          reference: {
            ...newData[index].reference,
            [e.target.name]: e.target.value,
          },
          verificationDetails: {
            ...newData[index].verificationDetails,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    setData(referenceCheckInfo);
  }, [referenceCheckInfo]);

  const updateReferenceDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "referenceCheck/link";
    } else {
      url = "referenceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      reference: {
        ...data[index]?.reference,
        reportUrl: [
          ...new Set([...data[index]?.reference?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.reference?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.reference?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
      // reference: data[index]?.reference,
    };
    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getReferenceInfo(userVerificationDetail);
      toast.success("Reference details updated successfully");
      getReferenceCheckInfo(userVerificationDetail);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`referenceCheck/reference/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getReferenceCheckInfo(userVerificationDetail);
      // setCheckStatus(status);
      // geteducationCheckInfo(userVerificationDetail);
      // navigate("/staff-member-dashboard");
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "reference",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Reference Task Submitted!",
                userVerificationDetail,
                referenceCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}

      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${index === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex justify-between">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",
                                e,
                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",
                                e,
                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}

                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );

                                updateCheckStatus(
                                  "Major Discrepancy",
                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );

                                updateCheckStatus(
                                  "Minor Discrepancy",
                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Positive",
                              command: () => {
                                createActivity(
                                  "Pending -> Positive",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Positive",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Negative",
                              command: () => {
                                createActivity(
                                  "Pending -> Negative",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Negative",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Recommended",
                              command: () => {
                                createActivity(
                                  "Pending -> Recommended",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Recommended",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Not Recommended",
                              command: () => {
                                createActivity(
                                  "Pending -> Not Recommended",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Not Recommended",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Failed",
                              command: () => {
                                createActivity(
                                  "Pending -> Failed",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Failed",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Could Not Verified",
                              command: () => {
                                createActivity(
                                  "Pending -> Could Not Verified",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Could Not Verified",

                                  item?._id,
                                  index
                                );
                              },
                            },
                          ]}
                        />
                      )}
                      <div className="ml-[20px]">
                        {getSeverity(item?.reference?.status)}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Type
                      </label>
                      <Dropdown
                        value={item?.reference?.type || ""}
                        onChange={(e) => onInputChange(e, index)}
                        options={categories}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Type"
                        filter
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        className="w-full md:w-14rem"
                        inputId="type"
                        name="type"
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Full Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="fullName"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.fullName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Contact Number
                      </label>
                      <InputText
                        className="form-control"
                        keyfilter="int"
                        maxLength={10}
                        name="contactNumber"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.reference?.contactNumber || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        E-mail Address
                      </label>
                      <InputText
                        className="form-control"
                        type="email"
                        name="email"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumericWithSymbols")
                        }
                        value={item?.reference?.email || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        City
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="city"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.city || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        State
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="state"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.state || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Country
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="country"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.country || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Pincode
                      </label>
                      <InputText
                        className="form-control"
                        keyfilter="int"
                        maxLength={6}
                        name="pinCode"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.reference?.pinCode || ""}
                        disabled={flag === "true"}
                      />
                    </div>

                    <div className="col-md-8 mt-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        type="text"
                        name="remark"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.remark || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.reference?.status === "Pending" ? (
                      <>
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateReferenceDetails(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.reference?.status)}</div>
                      </>
                    ) : (
                      <div>{getSeverity(item?.reference?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Name of the reference
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="fullName"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.fullName || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Name of the company & Designation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="companyNameAndDesignation"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.companyNameAndDesignation || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Contact Number
                      </label>
                      <InputText
                        className="form-control"
                        keyfilter="int"
                        name="contactNumber"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.reference?.contactNumber || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        E-mail Id
                      </label>
                      <InputText
                        className="form-control"
                        type="email"
                        name="email"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.email || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        How do you know the candidate and since how many years?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="candidateYear"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.candidateYear || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Period of reporting
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfReporting"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.periodOfReporting || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Designation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="designation"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.designation || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Attitude towards work
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="attitudeTowards"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.attitudeTowards || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Duties & Responsibilities
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="duties"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.duties || ""}
                      />
                    </div>

                    {/* <div className="col-md-8 mt-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        type="text"
                        name="remark"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.remark || ""}
                        disabled={flag === "true"}
                      />
                    </div> */}
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Effective/ Non Effective
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="effective"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.effective || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Ability to work under stress/ pressure
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="abilityToWork"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.abilityToWork || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Communication Skills
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="communicationSkill"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.communicationSkill || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Interpersonal Skills
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="interpersonalSkills"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.interpersonalSkills || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Technical Skills
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="technicalSkill"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.technicalSkill || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Leadership Skills(if applicable)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="leadershipSkill"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.leadershipSkill || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Professional Strengths
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="professionalStrength"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.professionalStrength || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Professional weakness/ Areas of improvement
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="professionalWeakness"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.professionalWeakness || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Any personal issues affecting the job performance
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="personalIssues"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.personalIssues || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Attendance and Punctuality
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="attendance"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.attendance || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Honesty / Reliability
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="honesty"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.honesty || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Integrity issues
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="integrity"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.integrity || ""}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Eligibility for rehire (If no , why)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="eligibilityRehire"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.eligibilityRehire || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Performance Rating (On a scale of 1 to 10, 1 being the
                        lowest and 10 being the highest )
                      </label>
                      <InputText
                        className="form-control"
                        type="int"
                        name="performanceRating"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.reference?.performanceRating || ""}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Additional Comments
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="additionalComments"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.additionalComments || ""}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload{" "}
                      <span className="opacity-70">
                        (Please Choose JPEG,PNG,PDF less than 5MB)
                      </span>
                    </label>
                    {item?.reference?.reportUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        multiple
                        auto
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={5000000}
                        uploadHandler={(e) => handleFileUpload(e, "file")}
                        itemTemplate={customItemTemplate}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.reference?.reportUrl || []).map(
                        (fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          const isPdf = fileExtension === "pdf";
                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <div
                                role="presentation"
                                onClick={(e) =>
                                  removeImg(
                                    e,
                                    item._id,
                                    item,
                                    reportIndex,
                                    "file"
                                  )
                                } // Pass the `item` to removeImg
                                className="cursor-pointer"
                              >
                                <i
                                  className="pi pi-times"
                                  style={{ color: "red" }}
                                ></i>
                              </div>
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(e, fileName, index, reportIndex, "img")
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {fileMap[index]?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={fileMap[index][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${index}_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                  {isPdf && (
                                    <a
                                      href={fileMap[index][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-center"
                                    >
                                      View PDF
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(e, index, "alphanumeric");
                          }}
                          value={item?.reference?.remark || ""}
                        />
                      </div>
                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG Only)
                          </span>
                        </label>
                        {item?.reference?.uploadProofUrl?.length === 0 ? (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                          />
                        ) : (
                          ""
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.reference?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        proofIndex,
                                        "proof"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        index,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                      proofImage[index]?.[proofIndex] && (
                                        <a
                                          href={proofImage[index][proofIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${index}_${proofIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>{" "}
                    </>
                  )}

                  <div className="flex justify-end gap-2 mt-[10px]">
                    {/* {item?.reference?.status === "Pending" ? ( */}
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateReferenceDetails(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.reference?.status)}</div>
                    </div>
                    {/* ) : (
                      <div>{getSeverity(item?.reference?.status)}</div>
                    )} */}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Respondent Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="fullName"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.fullName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Designation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="designation"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.designation || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Organization
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="organization"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.organization || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Contact No.
                      </label>
                      <InputText
                        className="form-control"
                        type="int"
                        name="contactNumber"
                        maxLength={10}
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.reference?.contactNumber || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Applicant Name:
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="applicantName"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.applicantName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Employer Name:
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="employerName"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.employerName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Last Position Held:
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="lastPosition"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.lastPosition || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Duties and Responsibilities
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="responsibilities"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.responsibilities || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Year of association
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="yearOfAssociation"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.yearOfAssociation || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Subject Knowledge/ Technical Skills (Excellent/ Good/
                        Average)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="subjectKnowledge"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.subjectKnowledge || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Communication skill
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="communicationSkill"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.communicationSkill || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Performance rating on a scale of 1 to 10
                      </label>
                      <InputText
                        className="form-control"
                        type="int"
                        name="performanceRating"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.reference?.performanceRating || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Soft skills (Excellent/ Good/ Average)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="softSkills"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.softSkills || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Behavior and code of conduct
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="behavior"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.behavior || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Integrity issues (If Any)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="integrity"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.integrity || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Professional strengths & where candidate needs
                        improvement
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="professionalStrength"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.professionalStrength || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        What is your overall assessment of the candidate
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="assessment"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.assessment || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Remark Date
                    </label>
                    <InputText
                      className="form-control"
                      type="Date"
                      name="remarkDate"
                      onChange={(e) =>
                        onInputChange(
                          e,
                          index,
                          "alphanumericWithAllowedSymbols"
                        )
                      }
                      value={item?.verificationDetails?.remarkDate || ""}
                      disabled={
                        flag === "false" || localStorageData?.role === "Auditor"
                      }
                    />
                  </div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="remark">
                      Additional comments
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="additionalComments"
                      onChange={(e) => {
                        onInputChange(
                          e,
                          index,
                          "alphanumericWithAllowedSymbols"
                        );
                      }}
                      value={item?.reference?.additionalComments || ""}
                      disabled={
                        flag === "true" || localStorageData?.role === "Auditor"
                      }
                    />
                  </div>

                  {/* <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload{" "}
                      <span className="opacity-70">
                        (Please Choose JPEG,PNG,PDF less than 5MB)
                      </span>
                    </label>
                    {item?.reference?.reportUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        multiple
                        auto
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={5000000}
                        uploadHandler={(e) => handleFileUpload(e, "file")}
                        itemTemplate={customItemTemplate}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.reference?.reportUrl || []).map(
                        (fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          const isPdf = fileExtension === "pdf";
                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(e, fileName, index, reportIndex, "img")
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {fileMap[index]?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={fileMap[index][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${index}_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                  {isPdf && (
                                    <a
                                      href={fileMap[index][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-center"
                                    >
                                      View PDF
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(e, index, "alphanumeric");
                          }}
                          value={item?.reference?.remark || ""}
                          disabled={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose PDF less than 10MB)
                          </span>
                        </label>
                        {item?.reference?.uploadProofUrl?.length === 0 ? (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                            disabled={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        ) : (
                          ""
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.reference?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        index,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                      proofImage[index]?.[proofIndex] && (
                                        <a
                                          href={proofImage[index][proofIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${index}_${proofIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>{" "}
                    </>
                  )} */}

                  <div className="flex justify-end gap-2 mt-[10px]">
                    {item?.reference?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateReferenceDetails(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.reference?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.reference?.status)}</div>
                    )}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Name of the Referee
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="refereeName"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.refereeName || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Do you Know the subject?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="subjectKnowledge"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.subjectKnowledge || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        What was your professional relationship with the subject
                        and from how long to do know him?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="professionalRelation"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.professionalRelation || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        What was the subject’s reason for leaving the job?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="leavingJob"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.leavingJob || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Is the subject eligible for rehire?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="eligibilityRehire"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.eligibilityRehire || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Any complaints against the subject?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="complaint"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.complaint || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Would you like to recommend the subject for working in
                        top MNC Company?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="recommendMNC"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.recommendMNC || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Any record of the subject misrepresenting facts or
                        accounts? If yes, please specify
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="misrepresenting"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.misrepresenting || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Professional Skills? (Rate -1 to 5)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="professionalSkills"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.professionalSkills || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Time management and organizational skill? (Rate -1 to 5)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="timeManagementSkill"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.timeManagementSkill || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Written Communication Skill? (Rate 1 to 5)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="wriitenSkill"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.wriitenSkill || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Oral Communication Skill? (Rate 1 to5)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="oralSkill"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.oralSkill || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Team Player? (Rate 1 to 5)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="teamPlayer"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.teamPlayer || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Is there any Integrity/Reliability/Disciplinary issue
                        against the subject?
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="irdIssue"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.irdIssue || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Current organization of the respondent
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="organization"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.organization || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Remarks
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="remark"
                        onChange={(e) =>
                          onInputChange(
                            e,
                            index,
                            "alphanumericWithAllowedSymbols"
                          )
                        }
                        value={item?.reference?.remark || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>

                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Designation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="designation"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.designation || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Company Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="companyAddress"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.companyAddress || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>

                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Relation with the referee
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="relationWithReferee"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.reference?.relationWithReferee || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>

                    <div className="col-md-4 mb-2">
                      <label className="small mb-1" htmlFor="entityName">
                        Contact No.
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="contactNumber"
                        maxLength={10}
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.reference?.contactNumber || ""}
                        // disabled={
                        //   flag === "true" ||
                        //   localStorageData?.role === "Auditor"
                        // }
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload{" "}
                      <span className="opacity-70">
                        (Please Choose JPEG,PNG,PDF less than 5MB)
                      </span>
                    </label>
                    {item?.reference?.reportUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        multiple
                        auto
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={5000000}
                        uploadHandler={(e) => handleFileUpload(e, "file")}
                        itemTemplate={customItemTemplate}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.reference?.reportUrl || []).map(
                        (fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          const isPdf = fileExtension === "pdf";
                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(e, fileName, index, reportIndex, "img")
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {fileMap[index]?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={fileMap[index][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${index}_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                  {isPdf && (
                                    <a
                                      href={fileMap[index][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-center"
                                    >
                                      View PDF
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(e, index, "alphanumeric");
                          }}
                          value={item?.reference?.remark || ""}
                          disabled={
                            flag === "false" ||
                            localStorageData?.role === "Auditor"
                          }
                        />
                      </div>
                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose PDF less than 10MB)
                          </span>
                        </label>
                        {item?.reference?.uploadProofUrl?.length === 0 ? (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                            disabled={
                              flag === "false" ||
                              localStorageData?.role === "Auditor"
                            }
                          />
                        ) : (
                          ""
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.reference?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        index,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                      proofImage[index]?.[proofIndex] && (
                                        <a
                                          href={proofImage[index][proofIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${index}_${proofIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>{" "}
                    </>
                  )} */}

                  <div className="flex justify-end gap-2 mt-[10px]">
                    {item?.reference?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateReferenceDetails(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.reference?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.reference?.status)}</div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
          {localStorageData?.role === "user" && (
            <ConfirmDialog visible={isModalOpen} />
          )}
        </>
      ))}
    </>
  );
};

export default ReferenceDetails;
