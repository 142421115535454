import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NoData from "../../../Helper/NoDataFound.jsx";
import { CourtDetails } from "../../StaffMember/CourtDetails.jsx";
import { ProgressSpinner } from "primereact/progressspinner";

export default function CourtCheck({
  userVerificationRequestInfo,
  taskDetail,
  info,
  flag,
}) {
  const [selectedStaffForAddressCheck, setSelectedStaffForAddressCheck] =
    useState(null);
  const [
    selectedStaffForInternalAddressCheck,
    setSelectedStaffForInternalAddressCheck,
  ] = useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [BCAStaffListInternal, setBCAStaffListInternal] = useState([]);
  const [identityCheckInfo, setIdentityCheckInfo] = useState([]);
  const [identityCheckId, setIdentityCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activityDetails, setActivityDetails] = useState([]);
  const [showActivity, setShowActivity] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type !== "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Court"))
          );
        });
        console.log("staffListForCourt>>", filteredData);

        setBCAStaffList(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBCAInternalStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type === "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Court"))
          );
        });
        console.log("staffListForCourt>>", filteredData);

        setBCAStaffListInternal(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else if (user?.role === "Vendor") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.vendorId);
      } else if (user?.role === "Coordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else if (user?.role === "FieldCoordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else {
        getBCAStaffByBCAId(user?._id);
        getBCAInternalStaffByBCAId(user?.BCAId);
      }
    }
    getIndetityInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  const getIndetityInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "courtCheck/link";
    } else {
      url = "courtCheck";
    }
    setLoading(true);
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setIdentityCheckInfo(res?.data?.docs);
        setIdentityCheckId(res?.data?.docs[0]?._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createIdentityCheck = async () => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "courtCheck/link";
    } else {
      url = "courtCheck";
    }

    setAddNewLoading(true);
    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      court: {
        courtSelect: "",
        typeOfAddress: "",
        candidateName: "",
        fullAddress: "",
        fatherName: "",
        dateOfBirth: "",
        address: "",
        idProof: "",
        civilCourtDateOfSearch: "",
        civilCourtJurisdiction: "",
        civilCourtResult: "",
        highCourtDateOfSearch: "",
        highCourtJurisdiction: "",
        highCourtResult: "",
        magistrateCourtDateOfSearch: "",
        magistrateCourtJurisdiction: "",
        magistrateCourtResult: "",
        highCourtDateOfSearchForCrminalProceedings: "",
        highCourtJurisdictionForCrminalProceedings: "",
        highCourtResultForCrminalProceedings: "",
        supremeCourtDateOfSearch: "",
        supremeCourtJurisdiction: "",
        supremeCourtResult: "",
        dateOfVerification: "",
        recordSummery: "",
        civilCourt: "",
        highCourt: "",
        magistrateCourt: "",
        sessionCourt: "",
        otherCourt: "",
        city: "",
        dateOfSearch: "",
        additionalRemarks: "",
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
        reportUrl: [],
      },
      verificationDetails: {
        annexure: "",
        candidateNameRemark: "",
        fullAddressRemark: "",
        courtDetailRemark: "",
      },
      reason: {
        reasonOfCandidateName: "",
        reasonOfFullAddress: "",
      },
      location: {
        latitute: "",
        longitute: "",
      },
    };
    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Court Added successfully");
        setAddNewLoading(false);
        getIndetityInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setAddNewLoading(false);
        console.log(err);
      });
  };
  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId:
          selectedStaffForAddressCheck?._id ||
          selectedStaffForInternalAddressCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Court",
      };

      if (userVerificationRequestInfo?.CourtTaskId === null) {
        body.checkListItem = identityCheckInfo;
        await createTask(body);
      } else {
        body.checkListItem = identityCheckInfo;
        let path = `task/${userVerificationRequestInfo?.CourtTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId:
            selectedStaffForAddressCheck?._id ||
            selectedStaffForInternalAddressCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      setAssignLoading(false);
      console.error("Error in API:", error);
    }
  };

  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsAssigned = async () => {
    let currentDate = new Date();
    let body = {
      isAssigned: {
        status: true,
        dateAndTime: currentDate,
      },
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getIndetityInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.post(path, body);
    if (response) {
      toast.success("Task updated successfully");
      getIndetityInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };

  useEffect(() => {}, [identityCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );
      return selectedStaff ? selectedStaff : null;
    }
  };

  const getActivityDetails = (id) => {
    ApiService.get(`caseActivity/byCaseId/${id}`)

      .then((res) => {
        // Sort the data based on createdAt field

        const sortedData = res?.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const filteredData = sortedData.filter(
          (item) => item.checkName === "Court"
        );

        console.log("Filtered Activity Details:", sortedData);

        setActivityDetails(filteredData);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrefillDataForInternal = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffListInternal.length > 0) {
      const selectedStaff = BCAStaffListInternal.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      console.log("selectedStaffOnPrefill>>", selectedStaff);

      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillAddressStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.CourtTaskId
      );

      setSelectedStaffForAddressCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prefillInternalExperienceStaff = async () => {
    try {
      const selectedstaff = await handlePrefillDataForInternal(
        userVerificationRequestInfo?.CourtTaskId
      );
      console.log("selectedstaffInternal>>", selectedstaff);

      setSelectedStaffForInternalAddressCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForAddress = async () => {
      if (userVerificationRequestInfo?.CourtTaskId !== null) {
        await prefillAddressStaff(userVerificationRequestInfo?.CourtTaskId);
      }
    };

    fetchStaffForAddress();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  useEffect(() => {
    const fetchStaffForEducation = async () => {
      if (userVerificationRequestInfo?.CourtTaskId !== null) {
        await prefillInternalExperienceStaff(
          userVerificationRequestInfo?.CourtTaskId
        );
      }
    };

    fetchStaffForEducation();
  }, [userVerificationRequestInfo !== "" && BCAStaffListInternal.length > 0]);

  return (
    <>
      <div>
        <div className="flex flex-col lg:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Court Check</h5>
          </div>

          <div className="flex gap-2 max-sm:flex-col">
            {identityCheckInfo.length > 0 &&
            (user?.role === "BCA" ||
              user?.role === "Vendor" ||
              user?.role === "FieldCoordinator" ||
              user?.role === "Coordinator") ? (
              <>
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForInternalAddressCheck}
                  onChange={(e) => {
                    setSelectedStaffForInternalAddressCheck(e.value);
                  }}
                  options={BCAStaffListInternal}
                  optionLabel="firstName"
                  className="w-[200px] h-[45px] max-sm:w-full"
                  placeholder="Assign Internal"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={identityCheckInfo.length === 0}
                />
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForAddressCheck}
                  onChange={(e) => {
                    setSelectedStaffForAddressCheck(e.value);
                  }}
                  options={BCAStaffList}
                  optionLabel="firstName"
                  className="w-[200px] h-[45px] max-sm:w-full"
                  placeholder="Assign External"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={identityCheckInfo.length === 0}
                />
              </>
            ) : (
              ""
            )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              <Button
                label="Activity"
                type="button"
                onClick={() => {
                  getActivityDetails(userVerificationRequestInfo?._id);
                  setShowActivity(!showActivity);
                }}
                icon={showActivity ? "pi pi-angle-down" : "pi pi-angle-right"}
                iconPos="right"
                className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />

              {identityCheckInfo.length > 0 &&
              (user?.role === "BCA" ||
                user?.role === "Vendor" ||
                user?.role === "FieldCoordinator" ||
                user?.role === "Coordinator") ? (
                <Button
                  label="Assign"
                  type="button"
                  loading={assignLoading}
                  onClick={() => handleCreateTaskAPI()}
                  className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              ) : (
                ""
              )}
              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createIdentityCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <div className="flex justify-center items-center h-screen w-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              strokeWidth="4"
              animationDuration=".7s"
            />
          </div>
        </>
      ) : (
        <>
          {identityCheckInfo.length === 0 ? (
            <>
              <div className="flex items-center">
                <NoData info="Ooops, we could not find any identity" />
              </div>
            </>
          ) : (
            <>
              {identityCheckInfo.length > 0 &&
                identityCheckInfo !== null &&
                identityCheckId !== undefined &&
                identityCheckId !== null &&
                identityCheckInfo !== undefined && (
                  <>
                    <CourtDetails
                      userVerificationDetail={userVerificationRequestInfo?._id}
                      identityCheckId={identityCheckId}
                      courtCheckInfo={identityCheckInfo}
                      getCourtCheckInfo={getIndetityInfo}
                      taskDetail={taskDetail}
                      activityDetails={activityDetails}
                      showActivity={showActivity}
                      getActivityDetails={getActivityDetails}
                      flag={flag}
                    />
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
}
