import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import Checkbox from "@mui/material/Checkbox";
import { getBase64 } from "../../Helper/Base64File.js";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "antd";
import { InputTextarea } from "primereact/inputtextarea";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const EvidenceForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const caseDetail = location?.state?.userInfo;
  const [aadharMap, setAadharMap] = useState([]);
  const [panMap, setPanMap] = useState([]);
  const [educationMap, setEducationMap] = useState([]);
  const [permanentAddressMap, setPermanentAddressMap] = useState([]);
  const [currentAddressMap, setCurrentAddressMap] = useState([]);
  const [experienceMap, setExperienceMap] = useState([]);
  const [courtMap, setCourtMap] = useState([]);
  const [policeMap, setPoliceMap] = useState([]);
  const [criminalMap, setCriminalMap] = useState([]);
  const [dlMap, setDlMap] = useState([]);
  const [globalDatabaseMap, setGlobalDatabaseMap] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [initialData, setInitialData] = useState({});
  const [previosEmploymentImage, setPreviosEmploymentImage] = useState([]);
  const [courtImage, setCourtImage] = useState([]);
  const [policeImage, setPoliceImage] = useState([]);
  const [criminalImage, setCriminalImage] = useState([]);
  const [drivingLicenceImage, setDrivingLicenceImage] = useState([]);
  const [panImage, setPanImage] = useState([]);
  const [educationImage, setEducationImage] = useState([]);
  const [stokeImage, setStokeImage] = useState([]);
  const [addressImage, setAddressImage] = useState([]);
  const [currentAddressImage, setCurrentAddressImage] = useState([]);
  const [globalDatabaseImage, setGlobalDatabaseImage] = useState([]);
  const [sexOffenderImage, setSexOffenderImage] = useState([]);

  const [experienceInfo, setExperienceInfo] = useState([]);
  const [educationInfo, setEducationInfo] = useState([]);
  const [referenceInfo, setReferenceInfo] = useState([]);
  const [isAdditionalSectionVisible, setIsAdditionalSectionVisible] =
    useState(false);
  const [sameAsPermanent, setSameAsPermanent] = useState(false);
  const [statusValues, setStatusValues] = useState([]);
  const [educationStatus, setEducationStatus] = useState([]);
  const [referenceStatus, setReferenceStatus] = useState([]);
  const [additionalComments, setAdditionalComments] = useState([]);
  const [referenceAdditionalComments, setReferenceAdditionalComments] =
    useState([]);
  const [educationAdditionalComment, setEducationAdditionalComments] = useState(
    []
  );
  const [authenticatedBy, setAuthenticatedBy] = useState([]);
  const [modeOfVerification, setModeOfVerification] = useState([]);
  const [fileUploadValues, setFileUploadValues] = useState({
    employment: [],
    education: [],
  });

  const [permanentAddress, setPermanentAddress] = useState({
    NameOfCandidate: "",
    fatherName: "",
    addressAndContact: "",
    periodOfStay: "",
    typeOfHouse: "",
    localty: "",
    respondentName: "",
    RespondentContact: "",
    RespondentCandidate: "",
    buildingOwner: "",
    ownderContactDetail: "",
    landMark: "",
    statusOfPermanentAddress: "",
    // proofUrl: addressImage,
  });

  const [currentAddress, setCurrentAddress] = useState({
    NameOfCandidate: "",
    fatherName: "",
    addressAndContact: "",
    periodOfStay: "",
    typeOfHouse: "",
    localty: "",
    respondentName: "",
    RespondentContact: "",
    RespondentCandidate: "",
    buildingOwner: "",
    ownderContactDetail: "",
    landMark: "",
    statusOfCurrentAddress: "",
    // proofUrl: currentAddressImage,
  });

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  const handleStatusChange = (index, newValue) => {
    setStatusValues((prevState) => {
      const newState = [...prevState];
      newState[index] = newValue;
      return newState;
    });
  };
  const handleEducationStatusChange = (index, newValue) => {
    setEducationStatus((prevState) => {
      const newState = [...prevState];
      newState[index] = newValue;
      return newState;
    });
  };
  const handleReferenceStatusChange = (index, newValue) => {
    setReferenceStatus((prevState) => {
      const newState = [...prevState];
      newState[index] = newValue;
      return newState;
    });
  };

  const handleAdditionalCommentChange = (event, index, type) => {
    const { value } = event.target;

    if (ValidateInput(value, type)) {
      const newComments = [...additionalComments]; // Create a copy of the current state
      newComments[index] = value; // Update the value at the specified index
      setAdditionalComments(newComments); // Update the state with the new array
    }
  };

  const handleEducationAdditionalCommentChange = (event, index, type) => {
    const { value } = event.target;

    if (ValidateInput(value, type)) {
      const newComments = [...educationAdditionalComment]; // Create a copy of the current state
      newComments[index] = value; // Update the value at the specified index
      setEducationAdditionalComments(newComments); // Update the state with the new array
    }
  };

  const handleReferenceAdditionalCommentChange = (event, index, type) => {
    const { value } = event.target;

    if (ValidateInput(value, type)) {
      const newComments = [...referenceAdditionalComments]; // Create a copy of the current state
      newComments[index] = value; // Update the value at the specified index
      setReferenceAdditionalComments(newComments); // Update the state with the new array
    }
  };

  const handleAuthenticatedByChange = (event, index, type) => {
    const { value } = event.target;

    if (ValidateInput(value, type)) {
      const newAuth = [...authenticatedBy]; // Create a copy of the current state
      newAuth[index] = value; // Update the value at the specified index
      setAuthenticatedBy(newAuth); // Update the state with the new array
    }
  };

  const handleModeOfVerificationChange = (event, index, type) => {
    const { value } = event.target;

    if (ValidateInput(value, type)) {
      const newMode = [...modeOfVerification]; // Create a copy of the current state
      newMode[index] = value; // Update the value at the specified index
      setModeOfVerification(newMode); // Update the state with the new array
    }
  };

  const handleFileUpload = async (event, category, index) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }
    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "stokeImage":
        setStokeImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("File uploaded successfully");
        break;
      case "panImage":
        setPanImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("Pan File uploaded successfully");
        break;
      case "educationImage":
        setEducationImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("Education file uploaded successfully");
        break;
      case "addressImage":
        setAddressImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("Address file uploaded successfully");
        break;
      case "currentAddressImage":
        setCurrentAddressImage((prevState) => [
          ...prevState,
          ...uploadedFileUrls,
        ]);
        toast.success("Current Address file uploaded successfully");
        break;
      case "courtImage":
        setCourtImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("Court file uploaded successfully");
        break;
      case "criminalImage":
        setCriminalImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("Criminal file uploaded successfully");
        break;
      case "policeImage":
        setPoliceImage((prevState) => [...prevState, ...uploadedFileUrls]);
        toast.success("Police file uploaded successfully");
        break;
      case "drivingLicenceImage":
        setDrivingLicenceImage((prevState) => [
          ...prevState,
          ...uploadedFileUrls,
        ]);
        toast.success("Driving Licence file uploaded successfully");
        break;
      case "globalDatabaseImage":
        setGlobalDatabaseImage((prevState) => [
          ...prevState,
          ...uploadedFileUrls,
        ]);
        toast.success("Global Database file uploaded successfully");
        break;
      case "sexOffenderImage":
        setSexOffenderImage((prevState) => [
          ...prevState,
          ...uploadedFileUrls,
        ]);
        toast.success("Sex Offender file uploaded successfully");
        break;
      case "previosEmploymentImage":
        setPreviosEmploymentImage((prevState) => {
          const newState = [...prevState];
          newState[index] = [...(newState[index] || []), ...uploadedFileUrls];
          return newState;
        });
        toast.success("Employment Proof file uploaded successfully");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setAadharMap([]);
    setPanMap([]);
    setEducationMap([]);
    setPermanentAddressMap([]);
    setCurrentAddressMap([]);
    setExperienceMap([]);
    setCourtMap([]);
    setPoliceMap([]);
    setCriminalMap([]);
    setDlMap([]);
    setGlobalDatabaseMap([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setAadharMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "panImg") {
          setPanMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "educationImg") {
          setEducationMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "permanentImg") {
          setPermanentAddress((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "currentImg") {
          setCurrentAddressMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "experienceImg") {
          setExperienceMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "courtImg") {
          setCourtMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "policeImg") {
          setPoliceMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "criminalImg") {
          setCriminalMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "dlImg") {
          setDlMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        } else if (imgFlag === "globalDatabaseImg") {
          setGlobalDatabaseMap((prev) => ({
            ...prev,
            [reportIndex]: res?.data,
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const handleProof = async (event, index, type) => {
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      setFileUploadValues((prevState) => {
        const newState = { ...prevState };
        // If proofUrl doesn't exist or is not an array, initialize it
        if (!Array.isArray(newState[type][index])) {
          newState[type][index] = [];
        }
        // Append new URLs to the proofUrl array at the correct index
        newState[type][index] = [...newState[type][index], ...uploadedFileUrls];
        return newState;
      });

      toast.success("Files uploaded successfully");
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Failed to upload files.");
    }
  };

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEducationInfo = (id) => {
    ApiService.get(`educationCheck/${id}`)
      .then((res) => {
        setEducationInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReferenceInfo = (id) => {
    ApiService.get(`referenceCheck/${id}`)
      .then((res) => {
        setReferenceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (caseDetail?._id !== undefined) {
      getExperienceInfo(caseDetail?._id);
      getEducationInfo(caseDetail?._id);
      getReferenceInfo(caseDetail?._id);
    }
  }, [caseDetail]);

  const updateEvidence = () => {
    const previousEmployementData = experienceInfo.map((experience, index) => ({
      type: experience?.experience?.type,
      companyName: experience?.experience?.companyName,
      location: experience?.experience?.location,
      employmentCode: experience?.experience?.employmentCode,
      periodOfEmployement: experience?.experience?.periodOfEmployement,
      designation: experience?.experience?.designation,
      salary: experience?.experience?.salary,
      reasonForLeaving: experience?.experience?.reasonForLeaving,
      additionalComment: additionalComments[index] || "",
      proofUrl: Array.isArray(fileUploadValues.employment[index])
        ? fileUploadValues.employment[index]
        : [],
      status: statusValues[index] || "",
    }));
    const educationData = educationInfo.map((education, index) => ({
      qualification: education?.education?.qualification,
      enrollmentNumber: education?.education?.enrollmentNumber,
      schoolName: education?.education?.schoolName,
      schoolAddress: education?.education?.schoolAddress,
      unversityName: education?.education?.unversityName,
      periodOfStudy: education?.education?.periodOfStudy,
      grade: education?.education?.grade,
      additionalComments: educationAdditionalComment[index] || "",
      modeOfVerification: modeOfVerification[index] || "",
      authenticatedBy: authenticatedBy[index] || "",
      proofUrl: Array.isArray(fileUploadValues.education[index])
        ? fileUploadValues.education[index]
        : [],
      status: educationStatus[index] || "",
    }));
    const referenceData = referenceInfo.map((reference, index) => ({
      type: reference?.reference?.type,
      fullName: reference?.reference?.fullName,
      contactNumber: reference?.reference?.contactNumber,
      email: reference?.reference?.email,
      city: reference?.reference?.city,
      state: reference?.reference?.state,
      country: reference?.reference?.country,
      pinCode: reference?.reference?.pinCode,
      additionalComment: referenceAdditionalComments[index] || "",
      status: referenceStatus[index] || "",
    }));
    let body = {
      finalReportInfo: {
        aadhaarAvidence: {
          proofUrl: stokeImage,
          status: initialData?.statusByadhaar,
        },
        panAvidence: {
          proofUrl: panImage,
          status: initialData?.statusByPan,
        },
        policeAvidence: {
          proofUrl: policeImage,
          status: initialData?.statusByPolice,
        },
        criminalAvidence: {
          proofUrl: criminalImage,
          status: initialData?.statusByCriminal,
        },
        drivingLicenceAvidence: {
          proofUrl: drivingLicenceImage,
          status: initialData?.statusByDL,
        },

        sexOffenderAvidence: {
          proofUrl: sexOffenderImage,
          status: initialData?.statusBySexOffender,
        },

        globalDatabaseAvidence: {
          proofUrl: globalDatabaseImage,
          status: initialData?.statusByGlobal,
        },
        courtAvidence: {
          proofUrl: courtImage,
          status: initialData?.statusByCourt,
        },
        addressAvidence: {
          proofUrl: addressImage,
          ...permanentAddress,
        },
        currentAddressAvidence: {
          proofUrl: currentAddressImage,
          ...currentAddress,
        },
        educationAvidence: educationData,
        previousEmployementAvidence: previousEmployementData,
        referenceAvidence: referenceData,
      },
    };

    ApiService.post(`userVerificationRequest/${caseDetail?._id}`, body)
      .then((res) => {
        toast.success("Evidence details updated successfully");
        navigate("/verification-details", {
          state: {
            verificationDetails: res?.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const onInputChange = (e) => {
  //   setInitialData({ ...initialData, [e.target.name]: e.target.value });
  // };
  const onInputChange = (e, type) => {
    const { value, name } = e.target;

    if (ValidateInput(value, type)) {
      setInitialData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSameAsPermanent(isChecked);

    if (isChecked) {
      // Prefill current address with permanent address values
      setCurrentAddress(permanentAddress);
    } else {
      // Clear the current address if the checkbox is unchecked
      setCurrentAddress({
        NameOfCandidate: "",
        fatherName: "",
        addressAndContact: "",
        periodOfStay: "",
        typeOfHouse: "",
        localty: "",
        respondentName: "",
        RespondentContact: "",
        RespondentCandidate: "",
        buildingOwner: "",
        ownderContactDetail: "",
        landMark: "",
      });
    }
  };

  const getDialogHeader = () => {
    switch (activeIndex) {
    case 0:
      return "Evidence Documents for Aadhar Verification";
    case 1:
      return "Evidence Documents for Pan Verification";
    case 2:
      return "Evidence of Education Verification";
    case 3:
      return "Evidence of Address Verification";
    case 4:
      return "Evidence of Previous Employee Check";
    case 5:
      return "Evidence of Court Verification";
    case 6:
      return "Evidence of Police Verification";
    case 7:
      return "Evidence of Criminal Verification";
    case 8:
      return "Evidence of Driving Licence Verification";
    case 9:
      return "Evidence of Reference Verification";
    case 10:
      return "Evidence of Global Database Verification";
    case 11:
      return "Evidence of Sex Offender Verification";
    default:
      return "Add New";
    }
  };

  const toggleAdditionalSection = () => {
    setIsAdditionalSectionVisible(!isAdditionalSectionVisible);
  };

  const handleInputChange = (e, formType, type) => {
    const { name, value } = e.target;

    if (ValidateInput(value, type)) {
      if (formType === "permanent") {
        setPermanentAddress((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setCurrentAddress((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  return (
    <Card title={getDialogHeader()} className="border-2 max-sm:mt-[10%]">
      <form>
        {activeIndex === 0 && (
          <>
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="status">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByadhaar || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByadhaar"
                name="statusByadhaar"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {stokeImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  auto
                  customUpload={true}
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) => handleFileUpload(e, "stokeImage")}
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {stokeImage &&
                  stokeImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    // const isPdf = fileExtension === "pdf";
                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(e, fileName, reportIndex, "img")
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {aadharMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={aadharMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 1 && (
          <>
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByPan || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByPan"
                name="statusByPan"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {panImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) => handleFileUpload(e, "panImage")}
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {panImage &&
                  panImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(e, fileName, reportIndex, "panImg")
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {panMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={panMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 2 && (
          <>
            {educationInfo.map((education, index) => (
              <div key={index}>
                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Qualification
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your qualification"
                      name="qualification"
                      onChange={onInputChange}
                      value={education?.education?.qualification}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="dateOfRequest">
                      Register No/Roll No.
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your Register No/Roll No."
                      name="enrollmentNumber"
                      onChange={onInputChange}
                      value={education?.education?.enrollmentNumber}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      University/Board
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your university"
                      name="university"
                      onChange={onInputChange}
                      value={education?.education?.unversityName}
                    />
                  </div>
                </div>
                {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                  <div className="row my-2">
                    <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        City
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="city"
                        placeholder="Enter your city"
                        onChange={onInputChange}
                        value={education?.education?.schoolAddress}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        College/Institute/School
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        placeholder="Enter your College/Institute/School"
                        name="college"
                        onChange={onInputChange}
                        value={education?.education?.schoolName}
                      />
                    </div>
                    <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        Period Of Study
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        placeholder="Enter your Period of Study (e.g., 2016-2020)"
                        name="periodOfStudy"
                        onChange={onInputChange}
                        value={education?.education?.periodOfStudy}
                      />
                    </div>
                  </div>
                )}

                <div className="row my-2">
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        Class/Divison/%
                      </label>
                      <InputText
                        className="form-control"
                        placeholder="Class/Division/Percentage"
                        type="text"
                        name="class"
                        onChange={onInputChange}
                        value={education?.education?.grade}
                      />
                    </div>
                  )}
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Authenticated by
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Authenticated by"
                      name="authenticatedByEducation"
                      onChange={(event) =>
                        handleAuthenticatedByChange(event, index, "text")
                      }
                      value={authenticatedBy[index] || ""}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Additional Comments
                    </label>
                    <InputText
                      className="form-control"
                      placeholder="Enter your Additional Comments"
                      type="text"
                      name="additionalCommentByEducation"
                      onChange={(event) =>
                        handleEducationAdditionalCommentChange(
                          event,
                          index,
                          "text"
                        )
                      }
                      value={educationAdditionalComment[index] || ""}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Status
                    </label>
                    <Dropdown
                      value={educationStatus[index] || ""}
                      onChange={(e) =>
                        handleEducationStatusChange(index, e.target.value)
                      }
                      options={[
                        { name: "Clear", value: "clear" },
                        { name: "Reject", value: "reject" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Status"
                      filter
                      className="w-full md:w-14rem"
                      inputId="statusByEducation"
                      name="statusByEducation"
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Mode of Verification
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your Mode of Verification"
                      name="modeOfVerificationByEducation"
                      onChange={(event) =>
                        handleModeOfVerificationChange(event, index, "text")
                      }
                      value={modeOfVerification[index] || ""}
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                    File upload :
                  </span>
                  {educationImage?.length === 0 ? (
                    <FileUpload
                      name="outsideUpload[]"
                      customUpload={true}
                      auto
                      multiple
                      accept="image/*"
                      maxFileSize={50000000}
                      uploadHandler={(e) => handleProof(e, index, "education")}
                      itemTemplate={customItemTemplate}
                    />
                  ) : (
                    ""
                  )}
                  <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                    {educationImage &&
                      educationImage.map((fileUrl, reportIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        return (
                          <li
                            key={reportIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(e, fileName, reportIndex, "educationImg")
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />

                            {educationMap?.[reportIndex] && (
                              <div className="text-center mt-2">
                                {isImage && (
                                  <a
                                    href={educationMap[reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${reportIndex}`}
                                  >
                                    View Image
                                  </a>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            ))}
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 3 && (
          <>
            <div>
              <div className="row my-2">
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="dateOfRequest">
                    Name of Candidate
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    placeholder="Enter your candidate name"
                    name="NameOfCandidate"
                    value={permanentAddress.NameOfCandidate}
                    onChange={(e) => handleInputChange(e, "permanent", "text")}
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Father Name
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    placeholder="Enter your Father's Name"
                    name="fatherName"
                    // onChange={onInputChange}
                    // value={initialData?.fatherName}

                    value={permanentAddress.fatherName}
                    onChange={(e) => handleInputChange(e, "permanent", "text")}
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="dateOfRequest">
                    Address and contact number
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    placeholder="Enter your Address and contact number"
                    name="addressAndContact"
                    maxLength={10}
                    // onChange={onInputChange}
                    // value={initialData?.addressAndContact}

                    value={permanentAddress.addressAndContact}
                    onChange={(e) =>
                      handleInputChange(e, "permanent", "alphanumeric")
                    }
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Period Of Stay
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    placeholder="Period of Stay (e.g., MM/YYYY - MM/YYYY)"
                    name="periodOfStay"
                    // onChange={onInputChange}
                    // value={initialData?.periodOfStay}

                    value={permanentAddress.periodOfStay}
                    onChange={(e) =>
                      handleInputChange(e, "permanent", "numeric")
                    }
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Type of House
                  </label>
                  <Dropdown
                    value={permanentAddress.typeOfHouse}
                    onChange={(e) => handleInputChange(e, "permanent")}
                    // value={initialData?.typeOfHouse || ""}
                    // onChange={onInputChange}
                    options={[
                      { name: "Apartment", value: "apartment" },
                      { name: "Independent House", value: "fail" },
                    ]}
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select House"
                    filter
                    className="w-full md:w-14rem"
                    inputId="typeOfHouse"
                    name="typeOfHouse"
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Localty
                  </label>
                  <Dropdown
                    value={permanentAddress.localty}
                    onChange={(e) => handleInputChange(e, "permanent")}
                    // value={initialData?.localty || ""}
                    // onChange={onInputChange}
                    options={[
                      { name: "Residential", value: "residential" },
                      { name: "Commercial", value: "commercial" },
                    ]}
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select Localty"
                    filter
                    className="w-full md:w-14rem"
                    inputId="localty"
                    name="localty"
                  />
                </div>
              </div>

              <div className="row my-2">
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Respondent&apos;s name
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    placeholder="Enter your Respondent's name"
                    name="respondentName"
                    // onChange={onInputChange}
                    // value={initialData?.respondentName}
                    value={permanentAddress.respondentName}
                    onChange={(e) => handleInputChange(e, "permanent", "text")}
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Respondent&apos;s contact No.
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    name="RespondentContact"
                    maxLength={10}
                    // onChange={onInputChange}
                    // value={initialData?.RespondentContact}

                    value={permanentAddress.RespondentContact}
                    onChange={(e) =>
                      handleInputChange(e, "permanent", "numeric")
                    }
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Respondent&apos;s Relationship to candidate
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    name="RespondentCandidate"
                    // onChange={onInputChange}
                    // value={initialData?.RespondentCandidate}

                    value={permanentAddress.RespondentCandidate}
                    onChange={(e) => handleInputChange(e, "permanent", "text")}
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Building Ownership
                  </label>
                  <Dropdown
                    value={permanentAddress.buildingOwner}
                    onChange={(e) => handleInputChange(e, "permanent")}
                    // value={initialData?.buildingOwner || ""}
                    // onChange={onInputChange}
                    options={[
                      { name: "Owner", value: "owner" },
                      { name: "Rented", value: "rented" },
                      { name: "Lease", value: "lease" },
                    ]}
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select Building"
                    filter
                    className="w-full md:w-14rem"
                    inputId="buildingOwner"
                    name="buildingOwner"
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    If rented/lease,Owner&apos;s Contact details
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    name="ownderContactDetail"
                    // onChange={onInputChange}
                    // value={initialData?.ownderContactDetail}

                    value={permanentAddress.ownderContactDetail}
                    onChange={(e) =>
                      handleInputChange(e, "permanent", "alphanumeric")
                    }
                  />
                </div>
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Land Mark
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    name="landMark"
                    // onChange={onInputChange}
                    // value={initialData?.landMark}

                    value={permanentAddress.landMark}
                    onChange={(e) => handleInputChange(e, "permanent", "text")}
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <label className="mb-1" htmlFor="reportNumber">
                    Status
                  </label>
                  <Dropdown
                    value={permanentAddress.statusOfPermanentAddress}
                    onChange={(e) => handleInputChange(e, "permanent")}
                    // value={initialData?.statusByAddress || ""}
                    // onChange={onInputChange}
                    options={[
                      { name: "Clear", value: "clear" },
                      { name: "Reject", value: "reject" },
                    ]}
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select Status"
                    filter
                    className="w-full md:w-14rem"
                    inputId="statusOfPermanentAddress"
                    name="statusOfPermanentAddress"
                  />
                </div>
                <div className="row my-2">
                  <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                    File upload :
                  </span>
                  {addressImage?.length === 0 ? (
                    <FileUpload
                      name="outsideUpload[]"
                      customUpload={true}
                      auto
                      multiple
                      accept="image/*"
                      maxFileSize={50000000}
                      uploadHandler={(e) => handleFileUpload(e, "addressImage")}
                      itemTemplate={customItemTemplate}
                    />
                  ) : (
                    ""
                  )}
                  <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                    {addressImage &&
                      addressImage.map((fileUrl, reportIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        return (
                          <li
                            key={reportIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(e, fileName, reportIndex, "permanentImg")
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />

                            {permanentAddressMap?.[reportIndex] && (
                              <div className="text-center mt-2">
                                {isImage && (
                                  <a
                                    href={permanentAddressMap[reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${reportIndex}`}
                                  >
                                    View Image
                                  </a>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-[20px]">
              <Button
                type="button"
                onClick={toggleAdditionalSection}
                className="mt-2 w-[100px] h-[40px] text-[14px] text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
              >
                {isAdditionalSectionVisible ? "Remove" : "Add"}
              </Button>

              <div>
                <label htmlFor="display"> Same as permanent :</label>

                <Checkbox
                  name="sameAsPermanent"
                  checked={sameAsPermanent}
                  onChange={handleCheckboxChange}
                  color="success"
                />
              </div>
            </div>

            {isAdditionalSectionVisible && (
              <div>
                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="dateOfRequest">
                      Name of Candidate
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your candidate name"
                      name="NameOfCandidate"
                      value={currentAddress.NameOfCandidate}
                      onChange={(e) => handleInputChange(e, "current", "text")}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Father Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your Father's Name"
                      name="fatherName"
                      // onChange={onInputChange}
                      // value={initialData?.fatherName}

                      value={currentAddress.fatherName}
                      onChange={(e) => handleInputChange(e, "current", "text")}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="dateOfRequest">
                      Address and contact number
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your Address and contact number"
                      name="addressAndContact"
                      maxLength={10}
                      // onChange={onInputChange}
                      // value={initialData?.addressAndContact}

                      value={currentAddress.addressAndContact}
                      onChange={(e) =>
                        handleInputChange(e, "current", "numeric")
                      }
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Period Of Stay
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Period of Stay (e.g., MM/YYYY - MM/YYYY)"
                      name="periodOfStay"
                      // onChange={onInputChange}
                      // value={initialData?.periodOfStay}

                      value={currentAddress.periodOfStay}
                      onChange={(e) =>
                        handleInputChange(e, "current", "numeric")
                      }
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Type of House
                    </label>
                    <Dropdown
                      value={currentAddress.typeOfHouse}
                      onChange={(e) => handleInputChange(e, "current")}
                      // value={initialData?.typeOfHouse || ""}
                      // onChange={onInputChange}
                      options={[
                        { name: "Apartment", value: "apartment" },
                        { name: "Independent House", value: "fail" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select House"
                      filter
                      className="w-full md:w-14rem"
                      inputId="typeOfHouse"
                      name="typeOfHouse"
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Localty
                    </label>
                    <Dropdown
                      value={currentAddress.localty}
                      onChange={(e) => handleInputChange(e, "current")}
                      // value={initialData?.localty || ""}
                      // onChange={onInputChange}
                      options={[
                        { name: "Residential", value: "residential" },
                        { name: "Commercial", value: "commercial" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Localty"
                      filter
                      className="w-full md:w-14rem"
                      inputId="localty"
                      name="localty"
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Respondent&apos;s name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      placeholder="Enter your Respondent's name"
                      name="respondentName"
                      // onChange={onInputChange}
                      // value={initialData?.respondentName}
                      value={currentAddress.respondentName}
                      onChange={(e) => handleInputChange(e, "current", "text")}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Respondent&apos;s contact No.
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="RespondentContact"
                      maxLength={10}
                      // onChange={onInputChange}
                      // value={initialData?.RespondentContact}

                      value={currentAddress.RespondentContact}
                      onChange={(e) =>
                        handleInputChange(e, "current", "numeric")
                      }
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Respondent&apos;s Relationship to candidate
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="RespondentCandidate"
                      // onChange={onInputChange}
                      // value={initialData?.RespondentCandidate}

                      value={currentAddress.RespondentCandidate}
                      onChange={(e) => handleInputChange(e, "current", "text")}
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Building Ownership
                    </label>
                    <Dropdown
                      value={currentAddress.buildingOwner}
                      onChange={(e) => handleInputChange(e, "current")}
                      // value={initialData?.buildingOwner || ""}
                      // onChange={onInputChange}
                      options={[
                        { name: "Owner", value: "owner" },
                        { name: "Rented", value: "rented" },
                        { name: "Lease", value: "lease" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Building"
                      filter
                      className="w-full md:w-14rem"
                      inputId="buildingOwner"
                      name="buildingOwner"
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      If rented/lease,Owner&apos;s Contact details
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="ownderContactDetail"
                      // onChange={onInputChange}
                      // value={initialData?.ownderContactDetail}

                      value={currentAddress.ownderContactDetail}
                      onChange={(e) =>
                        handleInputChange(e, "current", "alphanumeric")
                      }
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Land Mark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="landMark"
                      // onChange={onInputChange}
                      // value={initialData?.landMark}

                      value={currentAddress.landMark}
                      onChange={(e) => handleInputChange(e, "current", "text")}
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Status
                    </label>
                    <Dropdown
                      value={currentAddress.statusOfCurrentAddress}
                      onChange={(e) => handleInputChange(e, "current")}
                      // value={initialData?.statusByAddress || ""}
                      // onChange={onInputChange}
                      options={[
                        { name: "Clear", value: "clear" },
                        { name: "Reject", value: "reject" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Status"
                      filter
                      className="w-full md:w-14rem"
                      inputId="statusOfCurrentAddress"
                      name="statusOfCurrentAddress"
                    />
                  </div>
                  <div className="row my-2">
                    <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                      File upload :
                    </span>
                    {currentAddressImage?.length === 0 ? (
                      <FileUpload
                        name="outsideUpload[]"
                        customUpload={true}
                        auto
                        multiple
                        accept="image/*"
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "currentAddressImage")
                        }
                        itemTemplate={customItemTemplate}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {currentAddressImage &&
                        currentAddressImage.map((fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(e, fileName, reportIndex, "currentImg")
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {currentAddressMap?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={currentAddressMap[reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 4 && (
          <>
            {experienceInfo.map((experience, index) => (
              <div key={index}>
                <div className="row my-2">
                  <div className="col-12 col-sm-12 col-md-4">
                    <label className="mb-1" htmlFor="dateOfRequest">
                      Experience Type
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="employer"
                      onChange={onInputChange}
                      value={experience?.experience?.type}
                    />
                  </div>
                  {experience?.experience?.type === "Experience" && (
                    <>
                      <div className="col-12 col-sm-12 col-md-4">
                        <label className="mb-1" htmlFor="dateOfRequest">
                          Company Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="employer"
                          onChange={onInputChange}
                          value={experience?.experience?.companyName}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <label className="mb-1" htmlFor="reportNumber">
                          Period Of Employment
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="periodOfEmployement"
                          onChange={onInputChange}
                          value={experience?.experience?.periodOfEmployement}
                        />
                      </div>
                    </>
                  )}
                </div>
                {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                  <div className="row my-2">
                    <div className="col-12 col-sm-12 col-md-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        Location
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="location"
                        onChange={onInputChange}
                        value={experience?.experience?.location}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <label className="mb-1" htmlFor="dateOfRequest">
                        Employee Code
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="employeeCode"
                        onChange={onInputChange}
                        value={experience?.experience?.employmentCode}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        Last Drawn Salary (CTC)
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="lastDrawnSalary"
                        onChange={onInputChange}
                        value={experience?.experience?.salary}
                      />
                    </div>
                  </div>
                )}

                <div className="row my-2">
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-12 col-sm-12 col-md-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        Reason for leaving
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="leavingReason"
                        onChange={onInputChange}
                        value={experience?.experience?.reasonForLeaving}
                      />
                    </div>
                  )}
                  {experience?.experience?.type === "Experience" && (
                    <div className="col-12 col-sm-12 col-md-4">
                      <label className="mb-1" htmlFor="reportNumber">
                        Designation
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="designation"
                        onChange={onInputChange}
                        value={experience?.experience?.designation}
                      />
                    </div>
                  )}
                  <div className="col-12 col-sm-12 col-md-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Additional Comments
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="additionalComment"
                      onChange={(event) =>
                        handleAdditionalCommentChange(event, index, "text")
                      }
                      value={additionalComments[index] || ""}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Status
                    </label>
                    <Dropdown
                      value={statusValues[index] || ""}
                      onChange={(e) =>
                        handleStatusChange(index, e.target.value)
                      }
                      options={[
                        { name: "Clear", value: "clear" },
                        { name: "Reject", value: "reject" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Status"
                      filter
                      className="w-full md:w-14rem"
                      inputId="statusByEmployer"
                      name="statusByEmployer"
                    />
                  </div>
                </div>
                {experience?.experience?.type === "Experience" && (
                  <div className="row my-2">
                    <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold text-transparent bg-clip-text">
                      File upload :
                    </span>
                    {previosEmploymentImage?.length === 0 ? (
                      <FileUpload
                        customUpload
                        auto
                        multiple
                        accept="image/*"
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleProof(e, index, "employment")
                        }
                        itemTemplate={customItemTemplate}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {previosEmploymentImage &&
                        previosEmploymentImage.map((fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    reportIndex,
                                    "experienceImg"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {experienceMap?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={experienceMap[reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            ))}
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => setActiveIndex(activeIndex - 1)}
                className="mt-2 w-[84px] h-[40px] text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex(activeIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-white bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 5 && (
          <>
            {" "}
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByCourt || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByCourt"
                name="statusByCourt"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {courtImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) => handleFileUpload(e, "courtImage")}
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {courtImage &&
                  courtImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(e, fileName, reportIndex, "courtImg")
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {courtMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={courtMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}{" "}
        {activeIndex === 6 && (
          <>
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByPolice || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByPolice"
                name="statusByPolice"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {policeImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) => handleFileUpload(e, "policeImage")}
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {policeImage &&
                  policeImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(e, fileName, reportIndex, "policeImg")
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {policeMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={policeMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 7 && (
          <>
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByCriminal || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByCriminal"
                name="statusByCriminal"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {criminalImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) => handleFileUpload(e, "criminalImage")}
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {criminalImage &&
                  criminalImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(e, fileName, reportIndex, "criminalImg")
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {criminalMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={criminalMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 8 && (
          <>
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByDL || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByDL"
                name="statusByDL"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {drivingLicenceImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) =>
                    handleFileUpload(e, "drivingLicenceImage")
                  }
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {drivingLicenceImage &&
                  drivingLicenceImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(e, fileName, reportIndex, "dlImg")
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {dlMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={dlMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 9 && (
          <>
            {referenceInfo.map((reference, index) => (
              <div key={index}>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Type
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="type"
                      onChange={onInputChange}
                      value={reference?.reference?.type}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Full Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="fullName"
                      onChange={onInputChange}
                      value={reference?.reference?.fullName}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Contact Number
                    </label>
                    <InputText
                      className="form-control"
                      keyfilter="int"
                      maxLength={10}
                      name="contactNumber"
                      onChange={onInputChange}
                      value={reference?.reference?.contactNumber}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      E-mail Address
                    </label>
                    <InputText
                      className="form-control"
                      type="email"
                      name="email"
                      onChange={onInputChange}
                      value={reference?.reference?.email}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      City
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="city"
                      onChange={onInputChange}
                      value={reference?.reference?.city}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      State
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="state"
                      onChange={onInputChange}
                      value={reference?.reference?.state}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Country
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="country"
                      onChange={onInputChange}
                      value={reference?.reference?.country}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Pincode
                    </label>
                    <InputText
                      className="form-control"
                      keyfilter="int"
                      maxLength={6}
                      name="pinCode"
                      onChange={onInputChange}
                      value={reference?.reference?.pinCode}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Status
                    </label>
                    <Dropdown
                      value={referenceStatus[index] || ""}
                      onChange={(e) =>
                        handleReferenceStatusChange(index, e.target.value)
                      }
                      options={[
                        { name: "Clear", value: "clear" },
                        { name: "Reject", value: "reject" },
                      ]}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Status"
                      filter
                      className="w-full md:w-14rem"
                      inputId="statusByDL"
                      name="statusByDL"
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <label className="mb-1" htmlFor="reportNumber">
                      Additional Comments
                    </label>
                    <InputTextarea
                      className="form-control"
                      placeholder="Enter your Additional Comments"
                      type="text"
                      name="additionalCommentByReference"
                      onChange={(event) =>
                        handleReferenceAdditionalCommentChange(
                          event,
                          index,
                          "text"
                        )
                      }
                      value={referenceAdditionalComments[index] || ""}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 10 && (
          <>
            <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusByGlobal || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusByGlobal"
                name="statusByGlobal"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {globalDatabaseImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) =>
                    handleFileUpload(e, "globalDatabaseImage")
                  }
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {globalDatabaseImage &&
                  globalDatabaseImage.map((fileUrl, reportIndex) => {
                    // Extract file name from the URL
                    const fileName = fileUrl.split("/").pop();
                    const fileExtension = fileName
                      .split(".")
                      .pop()
                      .toLowerCase();

                    const isImage = ["jpeg", "png"].includes(fileExtension);

                    return (
                      <li
                        key={reportIndex}
                        className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                      >
                        <Button
                          label="Download"
                          onClick={(e) =>
                            getImg(
                              e,
                              fileName,
                              reportIndex,
                              "globalDatabaseImg"
                            )
                          }
                          className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />

                        {globalDatabaseMap?.[reportIndex] && (
                          <div className="text-center mt-2">
                            {isImage && (
                              <a
                                href={globalDatabaseMap[reportIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`file_${reportIndex}`}
                              >
                                View Image
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={() => setActiveIndex((prevIndex) => prevIndex + 1)}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Next
              </Button>
            </div>
          </>
        )}
        {activeIndex === 11 && (
          <>
            {/* <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <label className="mb-1" htmlFor="reportNumber">
                Status
              </label>
              <Dropdown
                value={initialData?.statusBySexOffender || ""}
                onChange={onInputChange}
                options={[
                  { name: "Clear", value: "clear" },
                  { name: "Reject", value: "reject" },
                ]}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Status"
                filter
                className="w-full md:w-14rem"
                inputId="statusBySexOffender"
                name="statusBySexOffender"
              />
            </div>
            <div className="row my-2">
              <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-xl font-semibold font-['Poppins'] text-transparent bg-clip-text ">
                File upload :
              </span>
              {sexOffenderImage?.length === 0 ? (
                <FileUpload
                  name="outsideUpload[]"
                  customUpload={true}
                  auto
                  multiple
                  accept="image/*"
                  maxFileSize={50000000}
                  uploadHandler={(e) => handleFileUpload(e, "sexOffenderImage")}
                  itemTemplate={customItemTemplate}
                />
              ) : (
                ""
              )}
              <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                {sexOffenderImage &&
                  sexOffenderImage.map((fileUrl, index) => (
                    <li
                      key={Math.random().toString(36).substr(2, 9)}
                      className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                    >
                      <div className="h-[200px] max-sm:h-[150px]">
                        <img
                          src={fileUrl}
                          alt={`Outside_Image ${index}`}
                          className="h-[200px] w-[200px] max-sm:h-[150px] max-sm:w-[150px] object-contain"
                        />
                      </div>
                      <a
                        href={fileUrl}
                        download={`Stoke_Image${index}`}
                        className="text-center"
                      >
                        Download
                      </a>
                    </li>
                  ))}
              </ul>
            </div> */}
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="mt-2 w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={(e) => {
                  updateEvidence(e);
                }}
                className="mt-2 w-[100px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </form>
    </Card>
  );
};
