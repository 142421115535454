import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { SplitButton } from "primereact/splitbutton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem"; 
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";


const SexOffenderDetails = ({
  userVerificationDetail,
  taskDetail,
  sexOffenderCheckInfo,
  getSexOffenderCheckInfo,
  activityDetails,
  showActivity,
  getActivityDetails,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [activity, setActivity] = useState(""); 
  let localStorageData = JSON.parse(localStorage.getItem("user"));
  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `sexoffenderCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getSexOffenderCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  
  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName: localStorageData?.role === "BCA"
        ? localStorageData?.name
        : localStorageData?.role === "BCAStaff"
          ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
          : localStorageData?.role === "Coordinator"
            ? localStorageData?.vendorName
            : localStorageData?.role === "DataEntry"
              ? localStorageData?.vendorName
              : "",
      checkName: "Sex Offender",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {getActivityDetails(userVerificationDetail);setActivity("")})
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const getSexOffenderInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "sexOffenderCheck/link";
    } else {
      url = "sexOffenderCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then((res) => {
        res?.data?.docs[0]; // Assuming there's only one item in the array
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          sexOffender: {
            ...newData[index].sexOffender,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(sexOffenderCheckInfo);
  }, [sexOffenderCheckInfo]);

  const updateSexOffenderDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "sexOffenderCheck/link";
    } else {
      url = "sexOffenderCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      sexOffender: data[index]?.sexOffender,
    };
    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getSexOffenderInfo(userVerificationDetail);
      toast.success("SexOffender details updated successfully");
      getSexOffenderCheckInfo(userVerificationDetail);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`sexOffenderCheck/sexOffender/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getSexOffenderCheckInfo(userVerificationDetail);
      // setCheckStatus(status);
      // geteducationCheckInfo(userVerificationDetail);
      // navigate("/staff-member-dashboard");
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "sexOffender",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Sex Offender Task Submitted!",
                userVerificationDetail,
                sexOffenderCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                    Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail,
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label
              className="small mb-1 px-3   font-bold"
              htmlFor="activity"
            >
                  All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                            createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                            createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                          Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${index === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role  !== "BCAStaff"  && <div
              className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
              role="presentation"
              onClick={() => confirm1(item._id)}
            >
              <DeleteIcon
                style={{ color: "red", height: "35px", width: "35px" }}
              />
              {/* <ConfirmDialog /> */}
            </div>}
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex justify-between">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",

                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",

                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Major Discrepancy",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Minor Discrepancy",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  index
                                );
                              },
                            },
                          ]}
                        />
                      )}

                      <>
                        <div>{getSeverity(item?.sexOffender?.status)}</div>
                      </>
                    </div>
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      First Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="firstName"
                      onChange={(e) => onInputChange(e, index, "text")}
                      value={item?.sexOffender?.firstName || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Last Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="lastName"
                      onChange={(e) => onInputChange(e, index, "text")}
                      value={item?.sexOffender?.lastName || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Country
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="country"
                      onChange={(e) => onInputChange(e, index, "text")}
                      value={item?.sexOffender?.country || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="entityName">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      type="text"
                      name="remark"
                      onChange={(e) => onInputChange(e, index, "alphanumeric")}
                      value={item?.sexOffender?.remark || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>
              
                <div className="flex justify-end gap-2 mt-[10px]">
                  {flag === "false" &&
                  item?.sexOffender?.status === "Pending" ? (
                      <>
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateSexOffenderDetails(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.sexOffender?.status)}</div>
                      </>
                    ) : (
                      <div>{getSeverity(item?.sexOffender?.status)}</div>
                    )}
                </div> 
              </form>
            </div>
          </Card>

         

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
          {localStorageData?.role === "user" && <ConfirmDialog visible={isModalOpen} />}
        </>
      ))}
    </>
  );
};

export default SexOffenderDetails;
