import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const PanCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 140,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const GvsPanDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [panInfo, setPanInfo] = useState([]);

  const getPanInfo = (id) => {
    if (id) {
      ApiService.get(`identityCheck/${id}`)
        .then((res) => {
          const panData = res?.data?.docs.find(
            (doc) => doc.identity?.type === "PAN Card"
          );
          if (panData) {
            console.log("panData>>>", panData);
            setPanInfo(panData);
          } else {
            console.log("No panData Card data found");
            setPanInfo([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    console.log("panInfo>>>>", panInfo);
  }, [panInfo]);

  //   useEffect(() => {
  //     const id = location?.state?.id;
  //     if (id) {
  //       getPanInfo(id);
  //     } else {
  //       console.log("No id found in location state");
  //     }
  //   }, [location?.state?.id]);

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getPanInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  //   useEffect(() => {
  //     if (panInfo.length > 0) {
  //       onDataUpdate({
  //         panInfo,
  //         renderAadharDetails: () => renderAadharDetails(),
  //       });
  //     }
  //   }, [panInfo]);

  useEffect(() => {
    console.log("panInfo state updated:", panInfo);

    if (panInfo && Object.keys(panInfo).length > 0) {
      console.log("onDataUpdate called with:", {
        panInfo,
        renderPanDetails: () => renderPanDetails(),
      });

      onDataUpdate({
        panInfo,
        renderPanDetails: () => renderPanDetails(),
      });
    }
  }, [panInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderPanDetails = () => {
    return (
      <>
        <React.Fragment
        //    key={index}
        >
          {/* Render table on one page */}
          <View style={PanCheckStyles.table}>
            <Text
              style={{
                textAlign: "center",
                textDecoration: "underline",
                backgroundColor: "#c5d9eb",
                fontSize: 13,
              }}
            >
              Identity Check Pan
            </Text>
            {/* <View style={PanCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Details
                    </Text>
                    <Text
                      style={{
                        width: "400px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      As Provided
                    </Text>
                  </View> */}
            <View style={PanCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Pan Number
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.cardNumber} */}
                {panInfo?.identity?.panNumber}
              </Text>
            </View>
            <View style={PanCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.name} */}
                {panInfo?.identity?.name}
              </Text>
            </View>
            <View style={PanCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Pan Status
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.fathersName} */}
                {panInfo?.identity?.panStatus}
              </Text>
            </View>
            <View style={PanCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Individual
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.dateOfBirth} */}
                {panInfo?.identity?.individual}
              </Text>
            </View>
            <View style={PanCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Date of Verification
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.address} */}
                {panInfo?.identity?.dateOfVerification}
              </Text>
            </View>
            <View style={PanCheckStyles.tableRow}>
              <Text
                style={{
                  width: "200px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 10,
                  fontWeight: "900",
                }}
              >
                Record summery
              </Text>
              <Text
                style={{
                  width: "400px",
                  border: "0.5px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: 9,
                }}
              >
                {/* {aadharEvidence?.identity?.state} */}
                {panInfo?.identity?.recordSummery}
              </Text>
            </View>
          </View>
          {/* <View break /> */}
          {panInfo?.identity?.uploadProofUrl?.map((url, proofIndex) => (
            <View
              key={proofIndex}
              style={PanCheckStyles.centeredImageContainer}
              wrap={false}
              break
            >
              <AsyncImage
                fileUrl={url}
                alt={`Pan evidence ${proofIndex + 1}`}
              />
            </View>
          ))}
          <View break />
        </React.Fragment>
      </>
    );
  };

  return null;
};
