import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const UanCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    // height: "590px",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 40,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const SiriusUanDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [uanInfo, setUanInfo] = useState([]);

  const getUanInfo = (id) => {
    ApiService.get(`uanCheck/${id}`)
      .then((res) => {
        setUanInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getUanInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (uanInfo.length > 0) {
      onDataUpdate({
        uanInfo,
        renderUanDetails: () => renderUanDetails(),
      });
    }
  }, [uanInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderUanDetails = () => {
    return (
      <>
        {uanInfo?.length > 0 && (
          <>
            {uanInfo.map((uanEvidence, index) => (
              <React.Fragment key={index}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    marginTop: 30,
                  }}
                >
                  Detailed Report- UAN check
                </Text>
                <View key={index} style={UanCheckStyles.table}>
                  <View style={UanCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Facts
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Details provided by client
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Verification remarks
                    </Text>
                  </View>
                  <View style={UanCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                        fontWeight: "900",
                      }}
                    >
                      Name of the candidate
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.uan?.candidateName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.verificationDetails?.candidateNameRemark}
                    </Text>
                  </View>
                  <View style={UanCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                        fontWeight: "900",
                      }}
                    >
                      UAN
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.uan?.uanNumber}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.verificationDetails?.uanNumberRemark}
                    </Text>
                  </View>
                  <View style={UanCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                        fontWeight: "900",
                      }}
                    >
                      Status
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.uan?.status}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.verificationDetails?.status}
                    </Text>
                  </View>
                  <View style={UanCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                        fontWeight: "900",
                      }}
                    >
                      Final remarks
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.uan?.additionalRemarks}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 4,
                        fontSize: 11,
                      }}
                    >
                      {uanEvidence?.verificationDetails?.additionalRemarks}
                    </Text>
                  </View>
                </View>

                {/* Loop through and display each proof image */}
                {uanEvidence?.uan?.uploadProofUrl?.map((url, proofIndex) => (
                  <View
                    key={proofIndex}
                    style={UanCheckStyles.centeredImageContainer}
                    wrap={false} // Ensure image stays on its own page
                    break={proofIndex !== 0} // Add page break after each image except the first one
                  >
                    <AsyncImage
                      fileUrl={url}
                      alt={`Uan evidence ${proofIndex + 1}`}
                    />
                  </View>
                ))}

                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };
  return null;
};
