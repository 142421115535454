import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EducationCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
  singleEducationContainer: {
    width: "100%",
    marginBottom: 30,
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const VleaderEducationDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [educationInfo, setEducationInfo] = useState([]);

  const getEducationInfo = (id) => {
    if (id) {
      ApiService.get(`educationCheck/${id}`)
        .then((res) => {
          setEducationInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getEducationInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (educationInfo.length > 0) {
      onDataUpdate({
        educationInfo,
        renderEducationDetails: () => renderEducationDetails(),
      });
    }
  }, [educationInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Link href={imgSrc} className={className || ""} target="_blank">
        {alt || "Click here to view the image"}
      </Link>
    );
  };

  const renderEducationDetails = () => {
    return (
      <>
        {educationInfo?.length > 0 && (
          <>
            {educationInfo.map((educationEvidence, index) => (
              <React.Fragment key={index}>
                <View style={EducationCheckStyles.singleEducationContainer}>
                  {/* Render table on one page */}
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Education Details:
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Check Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            educationEvidence?.education?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {educationEvidence?.education?.status}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={EducationCheckStyles.table}>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Education
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Provided Information
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Verified Information
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name & Address of the Institution
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.instituteName}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.verificationDetails
                          ?.instituteNameRemark ||
                          educationEvidence?.education?.instituteName}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Affiliated University
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.unversityName}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.verificationDetails
                          ?.universityNameRemark ||
                          educationEvidence?.education?.unversityName}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Qualification
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.qualification}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.verificationDetails
                          ?.qualificationRemark ||
                          educationEvidence?.education?.qualification}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Register No/Roll No
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.enrollmentNumber}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.verificationDetails
                          ?.enrollmentNumberRemark ||
                          educationEvidence?.education?.enrollmentNumber}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Year of passing
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {/* {educationEvidence?.education?.yearOfPassing} */}
                        {educationEvidence?.education?.yearOfPassing
                          ? formatDate(
                              educationEvidence.education.yearOfPassing
                            )
                          : "NA"}
                      </Text>
                      <Text
                        style={{
                          width: "200px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.verificationDetails
                          ?.yearOfPassingRemark
                          ? formatDate(
                              educationEvidence.verificationDetails
                                .yearOfPassingRemark
                            )
                          : educationEvidence?.education?.yearOfPassing
                            ? formatDate(
                                educationEvidence.education.yearOfPassing
                              )
                            : "NA"}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Mode of verification
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.modeOfVerification}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Remarks from verifier (if any)
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.remarkFromVerifier}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name and Designation of the Referee
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {
                          educationEvidence?.education
                            ?.nameAndDesignationOfTheReferee
                        }
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Additional Comments (If any)
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.additionalRemarks}
                      </Text>
                    </View>
                    <View style={EducationCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "300px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Proof
                      </Text>
                      <Text
                        style={{
                          width: "600px",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {educationEvidence?.education?.uploadProofUrl?.map(
                          (url, proofIndex) => {
                            return (
                              <Text key={proofIndex} style={{ marginRight: 5 }}>
                                <AsyncImage
                                  fileUrl={url}
                                  alt={`Education evidence ${proofIndex + 1}`}
                                />
                                {proofIndex <
                                  educationEvidence.education.uploadProofUrl
                                    .length -
                                    1 && (
                                  <Text style={{ marginRight: 5 }}> , </Text>
                                )}
                              </Text>
                            );
                          }
                        )}
                      </Text>
                    </View>
                  </View>

                  {/* <View break /> */}
                </View>
                <View wrap={false} break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
