import React from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import { Password } from "primereact/password";
import { casesCheckList, envChecks } from "../../utils/constants.js";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

const AddFieldExecutiveModal = ({
  showAddStaff,
  setShowaddStaff,
  onFinish,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  state,
  setState,
  type,
  setType,
  userName,
  setUserName,
  selectedChecks,
  setSelectedChecks,
  setChecksArray,
  password,
  setPassword,
  isLoading,
  form,
  passwordError,
  // setpasswordError
}) => {
  // const handlePhoneNumberChange = (e) => {
  //   const inputNumber = e.target.value;
  //   if (/^\d+$/.test(inputNumber) && inputNumber.length <= 10) {
  //     setPhoneNumber(inputNumber);
  //   }
  // };
  const checks = casesCheckList.filter((check) => envChecks[check.name]);

  const checksTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const typeList = [{ name: "Internal" }, { name: "External" }];

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <Modal
      width="40%"
      title="Add Your Field Executive"
      open={showAddStaff}
      footer={null}
      onCancel={() => setShowaddStaff(false)}
      className="custom-modal-content modalRespo"
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        className="add-new-wallet"
        form={form}
      >
        <p className="mt-2">Let’s add your Field Executive</p>

        {/* <Row gutter={18} className="registerinfo-row">
          <Col span={12}>
            <label htmlFor="firstName">Type</label>
            <Form.Item
              name="type" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "Type of executive is required",
                },
              ]}
            >
             

              <Dropdown
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                options={typeList}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Type"
                filter
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="w-full md:w-14rem"
                inputId="type"
                name="type"
              />
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={18} className="registerinfo-row">
          <Col span={12}>
            <label htmlFor="firstName">Type</label>
            <Form.Item
              name="type" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "Type of executive is required",
                },
              ]}
            >
              <Dropdown
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                options={typeList}
                optionLabel="name"
                optionValue="name"
                placeholder="Select Type"
                filter
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="w-full md:w-14rem"
                inputId="type"
                name="type"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div>
              <lable>Select Checks</lable>
              <MultiSelect
                value={selectedChecks}
                options={checks}
                onChange={(e) => {
                  setSelectedChecks(e.value);

                  const selectedChecksNames = e.value.map(
                    (check) => check.name
                  );
                  setChecksArray(selectedChecksNames);
                }}
                optionLabel="name"
                // placeholder="Select Type of Checks"
                itemTemplate={checksTemplate}
                className="w-full md:w-20rem"
                display="chip"
                filter
              />
            </div>
          </Col>
        </Row>

        <Row gutter={18} className="registerinfo-row mt-[10px]">
          <Col span={12}>
            <label htmlFor="firstName">First Name</label>
            <Form.Item
              name="firstName" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input
                value={firstName}
                size="large"
                placeholder="Enter First Name"
                // onChange={(e) => {
                //   setFirstName(e.target.value);
                // }}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setFirstName(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[a-zA-Z\s-]$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="lastName">Last Name</label>
            <Form.Item
              name="lastName" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input
                value={lastName}
                size="large"
                placeholder="Enter Last Name"
                // onChange={(e) => {
                //   setLastName(e.target.value);
                // }}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setLastName(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[a-zA-Z\s-]$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18} className="registerinfo-row">
          <Col span={12}>
            <label htmlFor="email">Email</label>
            <Form.Item
              name="email" // Unique name for the field
              rules={[
                {
                  required: false,
                  message: "Email is required",
                },
              ]}
            >
              <Input
                value={email}
                size="large"
                placeholder="Enter Your Email"
                // onChange={(e) => {
                //   setEmail(e.target.value);
                // }}
                onChange={(e) => {
                  const regex = /^[a-zA-Z0-9\s@._-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setEmail(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[a-zA-Z0-9\s@._-]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="phone">Phone Number</label>
            <Form.Item
              name="phoneNumber" // Unique name for the field
              rules={[
                {
                  required: false,
                  message: "Phone number is required",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter a valid phone number!",
                },
              ]}
            >
              <Input
                value={phoneNumber}
                size="large"
                placeholder="Enter Phone Number"
                maxLength={10}
                // onChange={handlePhoneNumberChange}
                onChange={(e) => {
                  const regex = /^[0-9\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setPhoneNumber(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[0-9\s-]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={18} className="registerinfo-row">
          <Col span={12}>
            <label htmlFor="aadhaar">Aadhaar Card</label>
            <Form.Item
              name="Aadhaar Card" // Unique name for the field
              rules={[
                {
                  required: false,
                  message: "Aadhaar Card is required",
                },
              ]}
            >
              <Input
                value={aadharCard}
                placeholder="Enter Aadhaar Number"
                size="large"
                // onChange={(e) => {
                //   setAadharCard(e.target.value);
                // }}
                onChange={(e) => {
                  const regex = /^[0-9\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setAadharCard(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[0-9\s-]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="pan">PAN Card</label>
            <Form.Item
              name="PAN Card" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "PAN Card is required",
                },
              ]}
            >
              <Input
                value={panCard}
                size="large"
                placeholder="Enter PAN Number"
                maxLength={10}
                // onChange={(e) => {
                //   setPanCard(e.target.value);
                // }}
                onChange={(e) => {
                  const regex = /^[a-zA-Z0-9\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setPanCard(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[a-zA-Z0-9\s-]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={18} className="registerinfo-row">
          <Col span={24}>
            <label htmlFor="state">Full address</label>
            <Form.Item
              name="state" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "Adress is required",
                },
              ]}
            >
              <Input
                value={state}
                placeholder="Enter State , City , Pincode"
                size="large"
                // onChange={(e) => {
                //   setState(e.target.value);
                // }}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setState(newValue);
                  }
                }}
                onKeyPress={(e) => {
                  const regex = /^[a-zA-Z\s-]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18} className="registerinfo-row">
          <Col span={12}>
            <label htmlFor="userName">User Name (Required)</label>
            <Form.Item
              name="userName" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "name is required",
                },
              ]}
            >
              <Input
                value={userName}
                placeholder="Enter User Name"
                size="large"
                onChange={(e) => {
                  const regex = /^[a-zA-Z0-9\s-]*$/;
                  const newValue = e.target.value;
                  if (regex.test(newValue) || newValue === "") {
                    setUserName(newValue);
                  }
                  // setUserName(e.target.value);
                }}
                onKeyPress={(e) => {
                  const regex = /^[a-zA-Z0-9\s-]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="password">Password (Required)</label>
            <Form.Item
              name="password" // Unique name for the field
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Password
                value={password}
                placeholder="Enter Password"
                size="large"
                toggleMask
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="w-full h-[40px]"
              />
              {passwordError && (
                <div style={{ color: "red" }}>{passwordError}</div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className="mt-[12px] text-center">
          <button
            type="submit"
            disabled={isLoading}
            className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Wait...
              </>
            ) : (
              "Add Field Executive"
            )}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddFieldExecutiveModal;
