import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    padding: 10,
    borderTop: "1px solid black",
    // borderBottom: "1px solid black",
    textAlign: "center",
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    textDecoration: "underline",
  },
  paragraph: {
    fontSize: 10,
    lineHeight: 1.5,
    textAlign: "justify",
  },
  endContainer: {
    marginTop: 20,
    paddingTop: 10,
    borderTop: "1px solid black",
    textAlign: "center",
  },
  endText: {
    fontSize: 12,
    fontWeight: "bold",
  },
});

export const PamacDisclaimer = () => {
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.title}>Disclaimer</Text>
        <Text style={styles.paragraph}>
          The factual information in the structured screening process is being
          presented to the client in the prescribed format. This report is
          furnished to you in accordance with the agreement for service between
          the parties. PAMAC is not providing any personal opinion but all
          information provided is obtained and maintained in good faith from a
          variety of sources deemed reliable. Although every effort has been
          made to assure accuracy, PAMAC cannot act as guarantor of information
          accuracy and completeness. PAMAC cannot be held responsible in any
          manner whatsoever for the actions taken by the Client or by the
          employee (existing / potential) due to this report. In no event PAMAC
          shall be liable for any indirect, incidental, contingent,
          consequential, special or punitive loss, damage or expense (including
          without limitation, lost profits, and opportunity costs etc.) even if
          the PAMAC has been advised of their possible existence.
        </Text>
      </View>

      <View style={styles.endContainer}>
        <Text style={styles.endText}>End of Report</Text>
      </View>
    </>
  );
};
