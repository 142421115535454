import React, { useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Page,
  Document,
  PDFViewer,
} from "@react-pdf/renderer";

import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import Vleader_logo from "../../../assets/images/VLeader_Logo.jpg";
import Vleader_footer from "../../../assets/images/Vleader_Footer.png";
import { VleaderEducationDetailCheck } from "./VleaderEducationDetailCheck.jsx";
import { VleaderEmploymentDetailCheck } from "./VleaderEmploymentDetailCheck.jsx";
import { VleaderAddressDetailCheck } from "./VleaderAddressDetailCheck.jsx";
import { VleaderReferenceDetailCheck } from "./VleaderReferenceDetailCheck.jsx";
import { VleaderAadharDetailCheck } from "./VleaderAadharDetailCheck.jsx";
import { VleaderPanDetailCheck } from "./VleaderPanDetailCheck.jsx";
import { VleaderCriminalDetailCheck } from "./VleaderCriminalDetailCheck.jsx";
import { VleaderCourtDetailCheck } from "./VleaderCourtDetailCheck.jsx";
import { VleaderGlobalDatabaseDetailCheck } from "./VleaderGlobalDatabaseDetailCheck.jsx";
import { VleaderCreditDetailCheck } from "./VleaderCreditDetailCheck.jsx";

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 75, // Increased top padding for header
    paddingBottom: 40, // Increased bottom padding for footer
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    position: "absolute",
    top: 5,
    left: 40,
    right: 40,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 40,
    right: 40,
  },
  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: { flexDirection: "row", marginTop: 24 },

  logo: { width: 100, height: 40 },
  // heading: {
  //   textAlign: "center",
  //   marginTop: 40,
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   textDecoration: "underline",
  // },
  headingContainer: {
    backgroundColor: "#d3d3d3",
    border: "1px solid black",
    padding: 2,
    width: "100%",
    textAlign: "center",
    marginTop: 40,
  },
  heading: {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    textDecoration: "underline",
  },

  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: 30,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  labelCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontWeight: "bold", // Bold text for labels
    fontSize: 8,
    backgroundColor: "#d3d3d3", // Grey background for labels
  },

  classificationsRow: {
    flexDirection: "row",
    marginTop: 10,
  },
  statusCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
    color: "#fff",
    fontWeight: "bold",
  },
  statusText: {
    fontSize: 8,
    textAlign: "center",
    color: "black",
  },
  synopsis: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 14,
    fontWeight: "bold",
  },
  synopsistableCell: {
    flex: 1, // Normal cell width
    border: "1px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    height: "90px",
    bottom: 30,
    left: 0,
    right: 0,
  },
});

const checksStyle = StyleSheet.create({
  checksContainer: {
    marginTop: "20px",
  },
});

const sypnosisStyles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 10,
    marginBottom: 40,
  },

  section: {
    border: "1px solid black",
    marginBottom: 10,
  },

  sectionHeader: {
    backgroundColor: "#d3d3d3",
    padding: 8,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    borderBottom: "1px solid black",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },

  cell: {
    flex: 1,
    padding: 8,
    fontSize: 10,
    textAlign: "center",
    borderRight: "1px solid black",
  },
});

const Header = () => (
  <View style={styles.headerContainer} fixed>
    <CompanyTitle />
  </View>
);
const Footer = () => (
  <View style={styles.footerContainer} fixed>
    <Image style={styles.footer} src={Vleader_footer} />
  </View>
);

const CompanyTitle = () => (
  <View style={styles.titleContainer} fixed>
    <View style={styles.spaceBetween}>
      <Text style={styles.reportTitle}></Text>
      <Image style={styles.logo} src={Vleader_logo} />
    </View>
  </View>
);
const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const CurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const GeneralInfo = ({ verificationInfo }) => (
  <View style={styles.table}>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Name of Client</Text>
      <Text
        style={{
          width: "75%",
          border: "0.5px solid black",
          textAlign: "center",
        }}
      >
        {verificationInfo?.companyInfo?.name}
      </Text>
    </View>

    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Name of the Candidate</Text>
      <Text style={{ width: "75%", textAlign: "center" }}>
        {`${verificationInfo?.userInfo?.firstName} ${verificationInfo?.userInfo?.lastName}`}
      </Text>
    </View>

    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Employee Id</Text>
      <Text style={styles.tableCell}>*****</Text>
      <Text style={styles.labelCell}>Date Case Received</Text>
      <Text style={styles.tableCell}>
        {formatDate(verificationInfo?.requestedDate)}
      </Text>
    </View>

    {/* Row 3 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Vleader Reference Number</Text>
      <Text style={styles.tableCell}>
        {" "}
        {`${process.env.REACT_APP_REFERENCE_TITLE}-${verificationInfo?.case}`}
      </Text>
      <Text style={styles.labelCell}>Insufficiency Fulfilled Date</Text>
      <Text style={styles.tableCell}>***</Text>
    </View>

    {/* Row 4 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Insufficiency Raised Date</Text>
      <Text style={styles.tableCell}>*****</Text>
      <Text style={styles.labelCell}>Date Report Sent</Text>
      <Text style={styles.tableCell}>{CurrentDate()}</Text>
    </View>

    {/* Row 5 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Re-Verification Date</Text>
      <Text style={styles.tableCell}>***</Text>
      <Text style={styles.labelCell}>Report Status</Text>
      <Text
        style={[
          styles.tableCell,
          {
            backgroundColor: getStatusColor(verificationInfo?.status),
            color: "black",
          },
        ]}
      >
        {verificationInfo?.status}
      </Text>
    </View>

    {/* Row 6 */}
    <View style={styles.tableRow}>
      <Text style={styles.labelCell}>Date of Birth</Text>
      <Text style={{ width: "75%", textAlign: "center" }}>
        {verificationInfo?.userInfo?.dateOfBirth
          ? formatDate(verificationInfo.userInfo.dateOfBirth)
          : "NA"}
      </Text>
    </View>

    {/* Classifications */}
    <View style={styles.tableRow}>
      <Text style={[styles.labelCell, { width: "100%", textAlign: "center" }]}>
        Classifications of ‘Report Status’
      </Text>
    </View>

    <View style={styles.tableRow}>
      <View style={[styles.statusCell, { backgroundColor: "green" }]}>
        <Text style={styles.statusText}>Clear</Text>
      </View>
      <View style={[styles.statusCell, { backgroundColor: "yellow" }]}>
        <Text style={styles.statusText}>Minor Discrepancy</Text>
      </View>
      <View style={[styles.statusCell, { backgroundColor: "red" }]}>
        <Text style={styles.statusText}>Discrepant</Text>
      </View>
      <View style={[styles.statusCell, { backgroundColor: "yellow" }]}>
        <Text style={styles.statusText}>
          Inaccessible for verification / Inputs required
        </Text>
      </View>
    </View>
  </View>
);

const Synopsis = ({
  selectedChecks,
  educationData,
  experienceData,
  addressData,
  referenceData,
  aadharData,
  panData,
  criminalData,
  courtData,
  globalDatabaseData,
  creditData,
}) => {
  return (
    <View style={sypnosisStyles.container} break>
      {/* Education Verification */}
      <View wrap={false}>
        {selectedChecks?.education && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Educational Verification
            </Text>
            {educationData?.educationInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Education Verification Report -{" "}
                  {item?.education?.unversityName}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.education?.status) },
                  ]}
                >
                  {item?.education?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>

      {/* Employment Verification */}
      <View wrap={false}>
        {selectedChecks?.experience && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Employment Verification
            </Text>
            {experienceData?.experienceInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Previous Employment Verification Report -{" "}
                  {item?.experience?.organizationName}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.experience?.status) },
                  ]}
                >
                  {item?.experience?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Address Verification */}
      <View wrap={false}>
        {selectedChecks?.address && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Address Verification
            </Text>
            {addressData?.addressInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Address Verification Report - {item?.address?.typeOfAddress}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.address?.status) },
                  ]}
                >
                  {item?.address?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Reference Check Verification */}
      <View wrap={false}>
        {selectedChecks?.reference && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Reference Check Verification
            </Text>
            {referenceData?.referenceInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Reference Check Report - {item?.reference?.refereeName}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.reference?.status) },
                  ]}
                >
                  {item?.reference?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Aadhar Verification */}
      <View wrap={false}>
        {selectedChecks?.aadhar && aadharData?.aadharInfo?.identity && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Aadhar Verification
            </Text>
            <View style={sypnosisStyles.row}>
              <Text style={sypnosisStyles.cell}>
                Aadhar Verification Report -{" "}
                {aadharData?.aadharInfo?.identity?.cardNumber}
              </Text>
              <Text
                style={[
                  sypnosisStyles.cell,
                  {
                    color: getStatusColor(
                      aadharData?.aadharInfo?.identity?.status
                    ),
                  },
                ]}
              >
                {aadharData?.aadharInfo?.identity?.status}
              </Text>
            </View>
          </View>
        )}
      </View>
      {/* PAN Verification */}
      <View wrap={false}>
        {selectedChecks?.pan && panData?.panInfo?.identity && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>PAN Verification</Text>
            <View style={sypnosisStyles.row}>
              <Text style={sypnosisStyles.cell}>
                PAN Verification Report -{" "}
                {panData?.panInfo?.identity?.panNumber}
              </Text>
              <Text
                style={[
                  sypnosisStyles.cell,
                  {
                    color: getStatusColor(panData?.panInfo?.identity?.status),
                  },
                ]}
              >
                {panData?.panInfo?.identity?.status}
              </Text>
            </View>
          </View>
        )}
      </View>
      {/* Criminal Check Verification */}
      <View wrap={false}>
        {selectedChecks?.criminal && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Criminal Check Verification
            </Text>
            {criminalData?.criminalInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Criminal Check Report - {item?.criminal?.criminalForm}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.criminal?.status) },
                  ]}
                >
                  {item?.criminal?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Court Check Verification */}
      <View wrap={false}>
        {selectedChecks?.court && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Court Check Verification
            </Text>
            {courtData?.courtInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Court Check {item?.court?.courtSelect}
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.court?.status) },
                  ]}
                >
                  {item?.court?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Global Database Check Verification */}
      <View wrap={false}>
        {selectedChecks?.globalDatabase && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Global Database Check Verification
            </Text>
            {globalDatabaseData?.globalDatabaseInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>
                  Global Database Check Report
                </Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.globalDatabase?.status) },
                  ]}
                >
                  {item?.globalDatabase?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      {/* Credit Check Verification */}
      <View wrap={false}>
        {selectedChecks?.credit && (
          <View style={sypnosisStyles.section}>
            <Text style={sypnosisStyles.sectionHeader}>
              Credit Check Verification
            </Text>
            {creditData?.creditInfo?.map((item, index) => (
              <View key={index} style={sypnosisStyles.row}>
                <Text style={sypnosisStyles.cell}>Credit Check Report</Text>
                <Text
                  style={[
                    sypnosisStyles.cell,
                    { color: getStatusColor(item?.credit?.status) },
                  ]}
                >
                  {item?.credit?.status}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

// Report document component
const ReportDocument = ({
  verificationInfo,
  selectedChecks,
  educationData,
  experienceData,
  addressData,
  referenceData,
  aadharData,
  panData,
  criminalData,
  courtData,
  globalDatabaseData,
  creditData,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header />
      {/* <View style={styles.headingContainer}>
        <Text style={styles.heading}>Final Background Report</Text>
      </View> */}

      <GeneralInfo verificationInfo={verificationInfo} />
      <Text style={styles.synopsis}>Executive Summary</Text>
      <Synopsis
        selectedChecks={selectedChecks}
        educationData={educationData}
        experienceData={experienceData}
        addressData={addressData}
        referenceData={referenceData}
        aadharData={aadharData}
        panData={panData}
        criminalData={criminalData}
        courtData={courtData}
        globalDatabaseData={globalDatabaseData}
        creditData={creditData}
      />

      <Footer />
    </Page>
    {selectedChecks?.education && educationData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.education && educationData && (
            <>{educationData.renderEducationDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
    {selectedChecks?.experience && experienceData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.experience && experienceData && (
            <>{experienceData.renderExperienceDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
    {selectedChecks?.address && addressData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.address && addressData && (
            <>{addressData.renderAddressDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.reference && referenceData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.reference && referenceData && (
            <>{referenceData.renderReferenceDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.aadhar && aadharData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.aadhar && aadharData && (
            <>{aadharData.renderAadharDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.pan && panData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.pan && panData && <>{panData.renderPanDetails()}</>}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.criminal && criminalData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.criminal && criminalData && (
            <>{criminalData.renderCriminalDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.court && courtData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.court && courtData && (
            <>{courtData.renderCourtDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.globalDatabase && globalDatabaseData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.globalDatabase && globalDatabaseData && (
            <>{globalDatabaseData.renderGlobalDatabaseDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}

    {selectedChecks?.credit && creditData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.credit && creditData && (
            <>{creditData.renderCreditDetails()}</>
          )}
        </View>
        <Footer />
      </Page>
    )}
  </Document>
);

// Main component
export const VleaderReport = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    education: false,
    experience: false,
    address: false,
    reference: false,
    aadhar: false,
    pan: false,
    criminal: false,
    court: false,
    globalDatabase: false,
    credit: false,
  });
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [referenceData, setReferenceData] = useState(null);
  const [aadharData, setAadharData] = useState(null);
  const [panData, setPanData] = useState(null);
  const [criminalData, setCriminalData] = useState(null);
  const [courtData, setCourtData] = useState(null);
  const [globalDatabaseData, setGlobalDatabaseData] = useState(null);
  const [creditData, setCreditData] = useState(null);

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("VerificationInfor>>>>", verificationInfo);
  }, [verificationInfo]);

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
  }, [location?.state?.id]);

  const handleEducationDataUpdate = (data) => {
    setEducationData(data);
  };

  useEffect(() => {
    console.log("DTATTATATT>>>>>", educationData);
  }, [educationData]);

  const handleExperienceDataUpdate = (data) => {
    setExperienceData(data);
  };

  const handleAddressDataUpdate = (data) => {
    setAddressData(data);
  };

  const handleReferenceDataUpdate = (data) => {
    setReferenceData(data);
  };

  const handleAadharDataUpdate = (data) => {
    setAadharData(data);
  };

  const handlePanDataUpdate = (data) => {
    setPanData(data);
  };

  const handleCriminalDataUpdate = (data) => {
    setCriminalData(data);
  };

  const handleCourtDataUpdate = (data) => {
    setCourtData(data);
  };

  const handleGlobalDatabadeDataUpdate = (data) => {
    setGlobalDatabaseData(data);
  };

  const handleCreditDataUpdate = (data) => {
    setCreditData(data);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  return (
    <div>
      <h1>Generate Background Report</h1>

      {/* Options to select sections for interim report */}
      <div>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.education}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                education: !prev.education,
              }))
            }
          />{" "}
          Education
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.experience}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                experience: !prev.experience,
              }))
            }
          />{" "}
          Experience
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.address}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                address: !prev.address,
              }))
            }
          />{" "}
          Address
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.reference}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                reference: !prev.reference,
              }))
            }
          />{" "}
          Reference
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.aadhar}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                aadhar: !prev.aadhar,
              }))
            }
          />
          Aadhar
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.pan}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                pan: !prev.pan,
              }))
            }
          />
          Pan
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.criminal}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                criminal: !prev.criminal,
              }))
            }
          />
          Criminal
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.court}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                court: !prev.court,
              }))
            }
          />
          Court
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.globalDatabase}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                globalDatabase: !prev.globalDatabase,
              }))
            }
          />
          Global Database
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.credit}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                credit: !prev.credit,
              }))
            }
          />
          Credit
        </label>
      </div>

      <button onClick={togglePreview}>
        {isPreview ? "Close Preview" : "Preview Report"}
      </button>
      <VleaderEducationDetailCheck onDataUpdate={handleEducationDataUpdate} />
      <VleaderEmploymentDetailCheck onDataUpdate={handleExperienceDataUpdate} />
      <VleaderAddressDetailCheck onDataUpdate={handleAddressDataUpdate} />
      <VleaderReferenceDetailCheck onDataUpdate={handleReferenceDataUpdate} />
      <VleaderAadharDetailCheck onDataUpdate={handleAadharDataUpdate} />
      <VleaderPanDetailCheck onDataUpdate={handlePanDataUpdate} />
      <VleaderCriminalDetailCheck onDataUpdate={handleCriminalDataUpdate} />
      <VleaderCourtDetailCheck onDataUpdate={handleCourtDataUpdate} />
      <VleaderGlobalDatabaseDetailCheck
        onDataUpdate={handleGlobalDatabadeDataUpdate}
      />
      <VleaderCreditDetailCheck onDataUpdate={handleCreditDataUpdate} />

      {/* PDF Preview */}
      {isPreview && (
        <div style={{ height: "500px", marginTop: "20px" }}>
          <PDFViewer width="100%" height="500px">
            <ReportDocument
              //   data={sampleData}
              verificationInfo={verificationInfo}
              selectedChecks={selectedChecks}
              educationData={educationData}
              experienceData={experienceData}
              addressData={addressData}
              referenceData={referenceData}
              aadharData={aadharData}
              panData={panData}
              criminalData={criminalData}
              courtData={courtData}
              globalDatabaseData={globalDatabaseData}
              creditData={creditData}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default VleaderReport;
