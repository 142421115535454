import React, { useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Page,
  Document,
  PDFViewer,
} from "@react-pdf/renderer";

import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import Sirius_Logo from "../../../assets/images/Sirius_Logo.png";
import { SiriusAddressDetailCheck } from "./SiriusAddressDetailCheck.jsx";
import { SiriusEducationDetailCheck } from "./SiriusEducationDetailCheck.jsx";
import { SiriusEmploymentDetailCheck } from "./SiriusEmploymentDetailCheck.jsx";
import { SiriusDrugDetailCheck } from "./SiriusDrugDetailCheck.jsx";
import { SiriusUanDetailCheck } from "./SiriusUanDetailCheck.jsx";

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "blue";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "blue";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  default:
    return "black";
  }
};

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 75, // Increased top padding for header
    paddingBottom: 40, // Increased bottom padding for footer
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    position: "absolute",
    top: 5,
    left: 75,
  },

  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: {
    flexDirection: "row",
    // marginTop: 24,
  },

  logo: { width: "440px", height: 110 },
  // heading: {
  //   textAlign: "center",
  //   marginTop: 40,
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   textDecoration: "underline",
  // },
  headingContainer: {
    padding: 2,
    width: "100%",
    textAlign: "left",
    marginTop: 40,
  },
  heading: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    color: "#606960",
    marginLeft: "20px",
  },

  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: 15,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  labelCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontWeight: 900,
    fontSize: 8,
  },
  synopsis: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  synopsistableCell: {
    flex: 1, // Normal cell width
    border: "1px solid black",
    textAlign: "left",
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    height: "90px",
    bottom: 30,
    left: 0,
    right: 0,
  },
});

const checksStyle = StyleSheet.create({
  checksContainer: {
    marginTop: "20px",
  },
});

const executiveSummaryStyles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 10,
    border: "1px solid black",
  },

  // Header Row Style
  headerRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#d3d3d3", // Light gray background for header
    borderBottom: "1px solid black",
  },

  // Header Cell Style
  headerCell: {
    flex: 1,
    textAlign: "center",
    padding: 5,
    fontSize: 10,
    fontWeight: "bold",
    borderRight: "1px solid black",
  },

  // Row Style for Data
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },

  // Cell Style for Data Rows
  cell: {
    flex: 1,
    textAlign: "center",
    padding: 8,
    fontSize: 10,
    borderRight: "1px solid black",
  },
});

const Header = () => (
  <View style={styles.headerContainer}>
    <CompanyTitle />
  </View>
);

const CompanyTitle = () => (
  <View style={styles.titleContainer}>
    <View>
      <Image style={styles.logo} src={Sirius_Logo} />
    </View>
  </View>
);

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const GeneralInfo = ({ verificationInfo }) => (
  <View style={styles.table}>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Name of the Client
      </Text>
      <Text style={styles.tableCell}>
        {verificationInfo?.companyInfo?.name}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: "900",
          fontSize: 8,
        }}
      >
        Case Received On
      </Text>
      <Text style={styles.tableCell}>
        {formatDate(verificationInfo?.finalReportDate)}
      </Text>
    </View>

    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Name of the Candidate
      </Text>
      <Text style={styles.tableCell}>
        {`${verificationInfo?.userInfo.firstName} ${verificationInfo?.userInfo.lastName}`}
      </Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Case Completed On
      </Text>
      <Text style={styles.tableCell}>09 November 2016</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Employee number
      </Text>
      <Text style={styles.tableCell}>123</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Case completed by
      </Text>
      <Text style={styles.tableCell}>Not applicable</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Case reference number
      </Text>
      <Text style={styles.tableCell}>ABC/MUM/000</Text>
      <Text
        style={{
          width: "25%",
          border: "0.5px solid black",
          textAlign: "center",
          padding: 5,
          fontWeight: 900,
          fontSize: 8,
        }}
      >
        Status
      </Text>
      <Text
        style={[
          styles.tableCell,
          { backgroundColor: getStatusColor(verificationInfo.status) },
        ]}
      >
        {verificationInfo.status}
      </Text>
    </View>
  </View>
);

const ExecutiveSummary = ({
  addressData,
  educationData,
  experienceData,
  drugData,
  uanData,
}) => {
  return (
    <View style={executiveSummaryStyles.table} wrap>
      {/* Header */}
      <View style={executiveSummaryStyles.headerRow}>
        <Text style={executiveSummaryStyles.headerCell}>
          Services requested
        </Text>
        <Text style={executiveSummaryStyles.headerCell}>
          Verification Remarks
        </Text>
        <Text style={executiveSummaryStyles.headerCell}>
          Client review required
        </Text>
      </View>

      {/* Education Verification */}
      {educationData?.educationInfo?.map((item, index) => (
        <View key={index} style={executiveSummaryStyles.row}>
          <Text style={executiveSummaryStyles.cell}>
            Education Verification
          </Text>
          <Text
            style={[
              executiveSummaryStyles.cell,
              { backgroundColor: getStatusColor(item?.education?.status) },
            ]}
          >
            {item?.education?.status}
          </Text>
          <Text style={executiveSummaryStyles.cell}>
            {item?.education?.additionalRemarks}
          </Text>
        </View>
      ))}

      {/* Employment Verification */}
      {experienceData?.experienceInfo?.map((item, index) => (
        <View key={index} style={executiveSummaryStyles.row}>
          <Text style={executiveSummaryStyles.cell}>
            Employment Verification
          </Text>
          <Text
            style={[
              executiveSummaryStyles.cell,
              { backgroundColor: getStatusColor(item?.experience?.status) },
            ]}
          >
            {item?.experience?.status}
          </Text>
          <Text style={executiveSummaryStyles.cell}>
            {item?.experience?.additionalRemarks}
          </Text>
        </View>
      ))}

      {/* Address Verification */}
      {addressData?.addressInfo?.map((item, index) => (
        <View key={index} style={executiveSummaryStyles.row}>
          <Text style={executiveSummaryStyles.cell}>Address Verification</Text>
          <Text
            style={[
              executiveSummaryStyles.cell,
              { backgroundColor: getStatusColor(item?.address?.status) },
            ]}
          >
            {item?.address?.status}
          </Text>
          <Text style={executiveSummaryStyles.cell}>
            {item?.address?.additionalRemarks}
          </Text>
        </View>
      ))}
      {/* Drug Verification */}
      {drugData?.drugInfo?.map((item, index) => (
        <View key={index} style={executiveSummaryStyles.row}>
          <Text style={executiveSummaryStyles.cell}>Drug Verification</Text>
          <Text
            style={[
              executiveSummaryStyles.cell,
              { backgroundColor: getStatusColor(item?.drug?.status) },
            ]}
          >
            {item?.drug?.status}
          </Text>
          <Text style={executiveSummaryStyles.cell}>
            {item?.drug?.additionalRemarks}
          </Text>
        </View>
      ))}
      {/* UAN Verification */}
      {uanData?.uanInfo?.map((item, index) => (
        <View key={index} style={executiveSummaryStyles.row}>
          <Text style={executiveSummaryStyles.cell}>UAN Verification</Text>
          <Text
            style={[
              executiveSummaryStyles.cell,
              { backgroundColor: getStatusColor(item?.uan?.status) },
            ]}
          >
            {item?.uan?.status}
          </Text>
          <Text style={executiveSummaryStyles.cell}>
            {item?.uan?.additionalRemarks}
          </Text>
        </View>
      ))}
    </View>
  );
};

// Report document component
const ReportDocument = ({
  verificationInfo,
  selectedChecks,
  addressData,
  educationData,
  experienceData,
  drugData,
  uanData,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header />
      <View style={styles.headingContainer}>
        <Text style={styles.heading}>Final Report</Text>
      </View>

      <GeneralInfo verificationInfo={verificationInfo} />
      <ExecutiveSummary
        addressData={addressData}
        educationData={educationData}
        experienceData={experienceData}
        drugData={drugData}
        uanData={uanData}
      />
    </Page>

    {selectedChecks?.education && educationData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.education && educationData && (
            <>{educationData.renderEducationDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.employment && experienceData && (
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.employment && experienceData && (
            <>{experienceData.renderExperienceDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.address && addressData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.address && addressData && (
            <>{addressData.renderAddressDetails()}</>
          )}
        </View>
      </Page>
    )}

    {selectedChecks?.drug && drugData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.drug && drugData && (
            <>{drugData.renderDrugDetails()}</>
          )}
        </View>
      </Page>
    )}
    {selectedChecks?.uan && uanData && (
      <Page size="A4" style={styles.page} wrap={true}>
        <Header />
        <View style={checksStyle.checksContainer} wrap={true}>
          {selectedChecks.uan && uanData && <>{uanData.renderUanDetails()}</>}
        </View>
      </Page>
    )}

    <Page size="A4" style={styles.page} wrap={true}>
      <Header />
    </Page>
  </Document>
);

export const SiriusReport = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    address: true,
    education: true,
    employment: true,
    drug: true,
    uan: true,
  });
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [drugData, setDrugData] = useState(null);
  const [uanData, setUanData] = useState(null);

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("VerificationInfor>>>>", verificationInfo);
  }, [verificationInfo]);

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
  }, [location?.state?.id]);

  const handleEducationDataUpdate = (data) => {
    setEducationData(data);
  };

  useEffect(() => {
    console.log("DTATTATATT>>>>>", educationData);
  }, [educationData]);

  const handleAddressDataUpdate = (data) => {
    setAddressData(data);
  };

  const handleExperienceDataUpdate = (data) => {
    setExperienceData(data);
  };

  const handleDrugDataUpdate = (data) => {
    setDrugData(data);
  };

  const handleUanDataUpdate = (data) => {
    setUanData(data);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  return (
    <div>
      <h1>Generate Background Report</h1>

      {/* Options to select sections for interim report */}
      <div>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.education}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                education: !prev.education,
              }))
            }
          />{" "}
          Education
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.employment}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                employment: !prev.employment,
              }))
            }
          />{" "}
          Employment
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.address}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                address: !prev.address,
              }))
            }
          />
          Address
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.drug}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                drug: !prev.drug,
              }))
            }
          />
          Drug
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedChecks.uan}
            onChange={() =>
              setSelectedChecks((prev) => ({
                ...prev,
                uan: !prev.uan,
              }))
            }
          />
          UAN
        </label>
      </div>

      <button onClick={togglePreview}>
        {isPreview ? "Close Preview" : "Preview Report"}
      </button>
      <SiriusAddressDetailCheck onDataUpdate={handleAddressDataUpdate} />
      <SiriusEducationDetailCheck onDataUpdate={handleEducationDataUpdate} />
      <SiriusEmploymentDetailCheck onDataUpdate={handleExperienceDataUpdate} />
      <SiriusDrugDetailCheck onDataUpdate={handleDrugDataUpdate} />
      <SiriusUanDetailCheck onDataUpdate={handleUanDataUpdate} />

      {/* PDF Preview */}
      {isPreview && (
        <div style={{ height: "500px", marginTop: "20px" }}>
          <PDFViewer width="100%" height="500px">
            <ReportDocument
              //   data={sampleData}
              verificationInfo={verificationInfo}
              selectedChecks={selectedChecks}
              addressData={addressData}
              educationData={educationData}
              experienceData={experienceData}
              drugData={drugData}
              uanData={uanData}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default SiriusReport;
