import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const AddressCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "600px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

export const PamacAddressDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [addressInfo, setAddressInfo] = useState([]);

  const getAddressInfo = (id) => {
    ApiService.get(`addressCheck/${id}`)
      .then((res) => {
        setAddressInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getAddressInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (addressInfo.length > 0) {
      onDataUpdate({
        addressInfo,
        renderAddressDetails: () => renderAddressDetails(),
      });
    }
  }, [addressInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderAddressDetails = () => {
    return (
      <>
        <View wrap>
          <Text style={{ textAlign: "center", textDecoration: "underline" }}>
            Address verification
          </Text>
          {addressInfo?.length > 0 && (
            <>
              {addressInfo.map((addressEvidence, index) => (
                <React.Fragment key={index}>
                  <View style={{ marginTop: "40px" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: 5,
                        marginTop: 30,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {addressEvidence?.address?.typeOfAddress} :
                      </Text>

                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            marginRight: 5,
                          }}
                        >
                          Status:
                        </Text>
                        <View
                          style={{
                            backgroundColor: getStatusColor(
                              addressEvidence?.address?.status
                            ),
                            paddingVertical: 2,
                            paddingHorizontal: 10,
                            borderRadius: 4,
                          }}
                        >
                          <Text
                            style={{
                              color: "black",
                              fontSize: 12,
                              fontWeight: "bold",
                            }}
                          >
                            {addressEvidence?.address?.status}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={AddressCheckStyles.table}>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 10,
                            fontWeight: "bold",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Address Details
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 10,
                            fontWeight: "bold",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Information obtained
                        </Text>
                      </View>

                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Complete Address
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.completeAddress}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Address Type
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.typeOfAddress}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Prominent Landmark
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.landmark}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Period of stay
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.periodOfStay}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Marital Status
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.maritalStatus}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Easy to locate
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.easyToLocate}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Type of accommodation
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.accommodationType}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Residence Status
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.residenceStatus}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Building Appearance
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.buildingAppearance}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          No. of family members
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.familyMembers}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Person Met
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.personMet}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Relation with the Candidate
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.relationWithCandidate}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Contact number
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.contactNumber}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          City
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.city}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "left",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Re- Confirmation:
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Name of the verifier
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.verifier}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Relationship with candidate
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "center",
                            padding: 5,
                            fontSize: 8,
                          }}
                        >
                          {addressEvidence?.address?.relationshipWithCandidate}
                        </Text>
                      </View>
                      <View style={AddressCheckStyles.tableRow}>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "left",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          Conclusion & Additional comments
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            border: "0.5px solid black",
                            textAlign: "left",
                            padding: 5,
                            fontSize: 8,
                            fontWeight: "900",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          {addressEvidence?.address?.additionalRemarks}
                        </Text>
                      </View>
                    </View>
                    {addressEvidence?.address?.uploadProofUrl?.map(
                      (url, proofIndex) => (
                        <View
                          key={proofIndex}
                          style={AddressCheckStyles.centeredImageContainer}
                          wrap={false} // Prevent image splitting between pages
                          break // Page break to place image on a new page
                        >
                          <AsyncImage
                            fileUrl={url}
                            alt={`Address evidence ${proofIndex + 1}`}
                          />
                        </View>
                      )
                    )}
                  </View>
                  <View break />
                </React.Fragment>
              ))}
            </>
          )}
        </View>
      </>
    );
  };
  return null;
};
