import React from "react";
import {
  // StyleSheet,
  Text,
  View,
  // Image
} from "@react-pdf/renderer";

export const GvsRestrictionPage = () => {
  return (
    <>
      <View style={{ marginTop: "40px" }}>
        <Text
          style={{
            color: "#1155cc",
            textAlign: "center",
            fontSize: 20,
          }}
        >
          Restrictions & Limitation
        </Text>
      </View>
      <View style={{ marginTop: "20px" }}>
        <Text style={{ fontSize: 12 }}>
          The reports and comments prepared by Genuine Verification Services
          Private Limited are confidential in nature. They are meant only for
          the internal use of the client for the assessment of the background of
          their applicant. These reports are not intended for publication or
          circulation, No part of this publication may be reproduced,
          photocopies, transmitted without the prior permission or written
          consent of Genuine Verification Services Private Limited
        </Text>
        <Text> </Text>

        <Text style={{ fontSize: 12 }}>
          Our reports cannot be relied upon by any other person and we expressly
          disclaim all responsibility or liability for any costs, damages,
          losses, liabilities, Expenses incurred by anyone as a result of
          circulation, publication, reproduction or use of our reports contrary
          to the provisions of this paragraph. The reports provided by Genuine
          Verification Services Private Limited are non-auditable
        </Text>
        <Text> </Text>
        <Text style={{ fontSize: 12 }}>
          Our findings are based on the information available to us, the
          validity of verification is only as good as the source and information
          furnished, and hence cannot be considered to be conclusive. Should
          additional information or documentation become available which impacts
          our conclusions reached in our reports, we reserve the right to amend
          our findings accordingly.
        </Text>
        <Text> </Text>
        <Text style={{ fontSize: 12 }}>
          Due to certain factors beyond our control, it may be possible that we
          are unable to get all the relevant necessary information in spite of
          our sincere efforts to do so.
        </Text>
        <Text> </Text>
        <Text style={{ fontSize: 12 }}>
          You should be cautioned that certain information presented in this
          report may be hearsay and may not be accurate or reliable when
          identified as being alleged, or of unknown reliability.
        </Text>
        <Text> </Text>
        <Text style={{ fontSize: 12 }}>
          Due to the limitations mentioned above, the result of our work with
          respect to the background checks should be considered only as a
          guideline. Our reports and comments should not be considered
          definitive pronouncement on the individual.
        </Text>
        <Text> </Text>
        <Text> </Text>
        <Text> </Text>
        <Text> </Text>
        <Text style={{ textAlign: "center", fontSize: 13 }}>
          **** End of Report ****
        </Text>
      </View>
    </>
  );
};
