import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const AddressCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
    // height: "600px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const GvsAddressDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [addressInfo, setAddressInfo] = useState([]);

  const getAddressInfo = (id) => {
    ApiService.get(`addressCheck/${id}`)
      .then((res) => {
        setAddressInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getAddressInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (addressInfo.length > 0) {
      onDataUpdate({
        addressInfo,
        renderAddressDetails: () => renderAddressDetails(),
      });
    }
  }, [addressInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image
        src={imgSrc}
        alt={alt || "image"}
        className={className || ""}
        style={{ height: "550px" }}
      />
    );
  };

  const renderAddressDetails = () => {
    return (
      <>
        <View wrap>
          {addressInfo?.length > 0 && (
            <>
              {addressInfo.map((addressEvidence, index) => (
                <React.Fragment key={index}>
                  <View style={AddressCheckStyles.table}>
                    <Text
                      style={{
                        textAlign: "center",
                        textDecoration: "underline",
                        backgroundColor: "#c5d9eb",
                        fontSize: 13,
                      }}
                    >
                      Address verification
                    </Text>
                    {/* <View style={AddressCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "left",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Current address:
                    </Text>
                    <Text
                      style={{
                        width: "100%",

                        textAlign: "right",
                        padding: 5,
                        fontSize: 12,
                        fontWeight: "bold",
                        color: getStatusColor(addressEvidence?.address?.status),
                      }}
                    >
                      Status : {addressEvidence?.address?.status}
                    </Text>
                  </View> */}
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Address Details
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "bold",
                          // backgroundColor: "#c5d9eb",
                        }}
                      >
                        Information obtained
                      </Text>
                    </View>

                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Name of Candidate
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.nameOfCandidate}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Father’s Name
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.fatherName}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Address claim by candidate
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.completeAddress}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Address verifyed by Gvs
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.addressVerifyedByGvs}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Candidate - Date of Birth
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.candidateDateOfBirth}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Confirmation of Address (Post Office, Pin No)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.pincode}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Type of Address -
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.typeOfAddress}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Contact Number of Candidate
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.contactNumber}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Period of Stay
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.periodOfStay}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Type of Property
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.typeOfProperty}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Photo ID Proof Signature
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.photoIDProofSignature}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        ID Proof & Address confirmed by the person while
                        conducting virtual check. (Respondent Name)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {addressEvidence?.address?.respondentName}
                      </Text>
                    </View>
                    <View style={AddressCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                          backgroundColor: "#c5d9eb",
                        }}
                      >
                        Remarks
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        Verification done on date{" "}
                        {addressEvidence?.verificationDetails?.remarkDate} We
                        Met With Name of the person met{" "}
                        {addressEvidence?.address?.respondentName} Who Confirmed
                        The Applicant Is Residing A Given Address To since birth
                        to Till Date. The Snapshot of the Residence proof was
                        being presented in Annexure –{" "}
                        {addressEvidence?.verificationDetails?.annexure} of the
                        final report.
                      </Text>
                    </View>
                  </View>
                  {/* <View break /> */}

                  {addressEvidence?.address?.uploadProofUrl?.map(
                    (url, proofIndex) => (
                      <View
                        key={proofIndex}
                        style={AddressCheckStyles.centeredImageContainer}
                        wrap={false} // Prevent image splitting between pages
                        break // Page break to place image on a new page
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            margin: "17px 0 0 0",
                            textDecoration: "underline",
                            textAlign: "center",
                            display: "block",
                            marginTop: "50px",
                            marginBottom: "20px",
                          }}
                        >
                          Annexure-{" "}
                          {addressEvidence?.verificationDetails?.annexure}
                        </Text>
                        <AsyncImage
                          fileUrl={url}
                          alt={`Address evidence ${proofIndex + 1}`}
                        />
                      </View>
                    )
                  )}
                  <View break />
                </React.Fragment>
              ))}
            </>
          )}
        </View>
      </>
    );
  };
  return null;
};
