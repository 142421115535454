import React from "react";
import UploadBulk from "../../Helper/UploadBulk.jsx";
import { Modal } from "antd";

const UploadBulkStaffModal = ({
  showAddStaffBulk,
  setShowAddStaffBulk,
  handleDataParsed,
  closeModal,
}) => {
  return (
    <Modal
      width="70%"
      title={<span className="!text-[19px]">Upload Data</span>}
      open={showAddStaffBulk}
      footer={null}
      onCancel={() => setShowAddStaffBulk(false)}
      className="custom-modal-content modalRespo"
    >
      <div className="mt-[40px] text-center">
        <UploadBulk onDataParsed={handleDataParsed} closeModal={closeModal} />
      </div>
    </Modal>
  );
};

export default UploadBulkStaffModal;
