import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const ReferenceCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "40px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

// const getStatusColor = (status) => {
//   switch (status) {
//     case "Major Discrepancy":
//       return "red";
//     case "Minor Discrepancy":
//       return "orange";
//     case "Verified Clear":
//       return "green";
//     case "Insufficiency":
//       return "blue";
//     case "Unable to Verify":
//       return "orange";
//     case "Stop Check":
//       return "gray";
//     case "Rejected":
//       return "red";
//     case "Approved":
//       return "green";
//     case "Completed":
//       return "green";
//     case "InProgress":
//       return "blue";
//     case "Pending":
//       return "orange";
//     case "BcaApproval":
//       return "green";
//     case "BcaRejection":
//       return "red";
//     default:
//       return "black";
//   }
// };

export const GvsReferenceDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [referenceInfo, setReferenceInfo] = useState([]);

  const getReferenceInfo = (id) => {
    ApiService.get(`referenceCheck/${id}`)
      .then((res) => {
        setReferenceInfo(res?.data?.docs);
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getReferenceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (referenceInfo.length > 0) {
      onDataUpdate({
        referenceInfo,
        renderReferenceDetails: () => renderReferenceDetails(),
      });
    }
  }, [referenceInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
      <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  const renderReferenceDetails = () => {
    return (
      <>
        <View wrap>
          {referenceInfo?.length > 0 && (
            <>
              {referenceInfo.map((referenceEvidence, index) => (
                <React.Fragment key={index}>
                  <View
                    key={index}
                    style={ReferenceCheckStyles.table}
                    wrap={false}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        textDecoration: "underline",
                        backgroundColor: "#c5d9eb",
                        fontSize: 13,
                      }}
                    >
                      Reference Verification
                    </Text>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 1,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        Reference Details
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 1,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        Information obtained
                      </Text>
                    </View>

                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Respondent Name
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.fullName}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Designation
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.Designation}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Organization
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.organization}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Contact No.
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.contactNumber}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 12,
                          fontWeight: "900",
                        }}
                      >
                        FEEDBACK
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Applicant Name:
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.applicantName}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Employer Name:
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.employerName}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Last Position Held:
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.lastPosition}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Duties and Responsibilities
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.responsibilities}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Year of association
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.yearOfAssociation}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Subject Knowledge/ Technical Skills (Excellent/ Good/
                        Average)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.subjectKnowledge}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Communication skill
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.communicationSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Performance rating on a scale of 1 to 10
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.performanceRating}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Soft skills (Excellent/ Good/ Average)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.softSkills}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Behavior and code of conduct
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.behavior}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Integrity issues (If Any)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.integrity}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Professional strengths & where candidate needs
                        improvement
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.professionalStrength}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        What is your overall assessment of the candidate
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        {referenceEvidence?.reference?.assessment}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 10,
                          fontWeight: "900",
                        }}
                      >
                        Additional comments
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 1,
                          fontSize: 9,
                        }}
                      >
                        The above mentioned details have been Received on Call
                        date{" "}
                        {referenceEvidence?.verificationDetails?.remarkDate}
                      </Text>
                    </View>
                  </View>
                  {/* Loop through and display each proof image */}
                  {referenceEvidence?.reference?.uploadProofUrl?.map(
                    (url, proofIndex) => (
                      <View
                        key={proofIndex}
                        style={ReferenceCheckStyles.centeredImageContainer}
                        wrap={false} // Ensure image stays on its own page
                        break={proofIndex !== 0} // Add page break after each image except the first one
                      >
                        {/* <Text
                          style={{
                            fontWeight: "bold",
                            margin: "17px 0 0 0",
                            textDecoration: "underline",
                            textAlign: "center",
                            display: "block",
                            marginTop: "50px",
                            marginBottom: "20px",
                          }}
                        >
                          Copy of summarization mail sent to{" "}
                          {referenceEvidence?.reference?.schoolName}
                        </Text> */}
                        <AsyncImage
                          fileUrl={url}
                          alt={`Reference evidence ${proofIndex + 1}`}
                        />
                      </View>
                    )
                  )}
                  <View break />
                </React.Fragment>
              ))}
            </>
          )}
        </View>
      </>
    );
  };
  return null;
};
