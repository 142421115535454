import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import DrugDetails from "../../StaffMember/DrugDetails.jsx";
import { Button } from "primereact/button";
import NoData from "../../../Helper/NoDataFound.jsx";
import { ProgressSpinner } from "primereact/progressspinner";

export default function DrugCheck({
  userVerificationRequestInfo,
  info,
  taskDetail,
  flag,
}) {
  const [selectedStaffForDrugCheck, setSelectedStaffForDrugCheck] =
    useState(null);
  const [
    selectedStaffForInternalDrugCheck,
    setSelectedStaffForInternalDrugCheck,
  ] = useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [BCAStaffListInternal, setBCAStaffListInternal] = useState([]);
  const [drugCheckInfo, setDrugCheckInfo] = useState([]);
  const [drugCheckId, setDrugCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activityDetails, setActivityDetails] = useState([]);
  const [showActivity, setShowActivity] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type !== "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Drug"))
          );
        });
        console.log("staffListForDrug>>", filteredData);

        setBCAStaffList(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBCAInternalStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type === "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Drug"))
          );
        });
        console.log("staffListForDrug>>", filteredData);

        setBCAStaffListInternal(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else if (user?.role === "Vendor") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.vendorId);
      } else if (user?.role === "Coordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else if (user?.role === "FieldCoordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else {
        getBCAStaffByBCAId(user?._id);
        getBCAInternalStaffByBCAId(user?.BCAId);
      }
    }
    getDrugCheckInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  const getActivityDetails = (id) => {
    ApiService.get(`caseActivity/byCaseId/${id}`)

      .then((res) => {
        // Sort the data based on createdAt field

        const sortedData = res?.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const filteredData = sortedData.filter(
          (item) => item.checkName === "Drug"
        );

        console.log("Filtered Activity Details:", sortedData);

        setActivityDetails(filteredData);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getDrugCheckInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    setLoading(true);
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setDrugCheckInfo(res?.data?.docs);
        setDrugCheckId(res?.data?.docs[0]?._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createDrugCheck = async () => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    setAddNewLoading(true);
    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      drug: {
        name: "",
        clinicName: "",
        testName: "",
        laboratory: "",
        case: "",
        doctorName: "",
        clinicAddress: "",
        additionalRemarks: "",
        testDate: "",
        reportDate: "",
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
        reportUrl: [],
      },
      verificationDetails: {
        nameRemark: "",
        testNameRemark: "",
        laboratoryRemark: "",
        caseRemark: "",
        testDateRemark: "",
        additionalRemarksRemark: "",
      },
      location: {
        latitute: "",
        longitute: "",
      },
    };
    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Drug Added successfully");
        setAddNewLoading(false);
        getDrugCheckInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
        setAddNewLoading(false);
      });
  };
  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId:
          selectedStaffForDrugCheck?._id ||
          selectedStaffForInternalDrugCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Drug",
      };

      if (userVerificationRequestInfo?.DrugTaskId === null) {
        body.checkListItem = drugCheckInfo;
        await createTask(body);
      } else {
        body.checkListItem = drugCheckInfo;
        let path = `task/${userVerificationRequestInfo?.DrugTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId:
            selectedStaffForDrugCheck?._id ||
            selectedStaffForInternalDrugCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      console.error("Error in API:", error);
      setAssignLoading(false);
    }
  };
  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsAssigned = async () => {
    let currentDate = new Date();
    let body = {
      isAssigned: {
        status: true,
        dateAndTime: currentDate,
      },
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getDrugCheckInfo(userVerificationRequestInfo?._id);
      info();
      // getUserVerificationReqInfo();
      // handleNext();
      updateBcaConsentReq("InProgress");
      setAssignLoading(false);
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.post(path, body);
    if (response) {
      toast.success("Task updated successfully");
      getDrugCheckInfo(userVerificationRequestInfo?._id);
      info();
      // getUserVerificationReqInfo();
      // handleNext();
      updateBcaConsentReq("InProgress");
      setAssignLoading(false);
      updateCaseIsAssigned();
    }
  };

  useEffect(() => {}, [drugCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );
      return selectedStaff ? selectedStaff : null;
    }
  };

  const handlePrefillDataForInternal = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffListInternal.length > 0) {
      const selectedStaff = BCAStaffListInternal.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      console.log("selectedStaffOnPrefill>>", selectedStaff);

      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillAddressStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.DrugTaskId
      );

      setSelectedStaffForDrugCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prefillInternalExperienceStaff = async () => {
    try {
      const selectedstaff = await handlePrefillDataForInternal(
        userVerificationRequestInfo?.DrugTaskId
      );
      console.log("selectedstaffInternal>>", selectedstaff);

      setSelectedStaffForInternalDrugCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForAddress = async () => {
      if (userVerificationRequestInfo?.DrugTaskId !== null) {
        await prefillAddressStaff(userVerificationRequestInfo?.DrugTaskId);
      }
    };

    fetchStaffForAddress();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  useEffect(() => {
    const fetchStaffForEducation = async () => {
      if (userVerificationRequestInfo?.DrugTaskId !== null) {
        await prefillInternalExperienceStaff(
          userVerificationRequestInfo?.DrugTaskId
        );
      }
    };

    fetchStaffForEducation();
  }, [userVerificationRequestInfo !== "" && BCAStaffListInternal.length > 0]);

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Drug Check</h5>
          </div>

          <div className="flex max-sm:flex-col gap-2">
            {drugCheckInfo.length > 0 &&
            (user?.role === "BCA" ||
              user?.role === "Vendor" ||
              user?.role === "FieldCoordinator" ||
              user?.role === "Coordinator") ? (
                <>
                  <Dropdown
                    inputId="dd-city"
                    value={selectedStaffForInternalDrugCheck}
                    onChange={(e) => {
                      setSelectedStaffForInternalDrugCheck(e.value);
                    }}
                    options={BCAStaffListInternal}
                    optionLabel="firstName"
                    className="w-[200px] h-[45px] max-sm:w-full"
                    placeholder="Assign Internal"
                    style={{ backgroundColor: "#F8F8F8" }}
                    disabled={drugCheckInfo.length === 0}
                  />
                  <Dropdown
                    inputId="dd-city"
                    value={selectedStaffForDrugCheck}
                    onChange={(e) => {
                      setSelectedStaffForDrugCheck(e.value);
                    }}
                    options={BCAStaffList}
                    optionLabel="firstName"
                    className="w-[200px] h-[45px] max-sm:w-full"
                    placeholder="Assign External"
                    style={{ backgroundColor: "#F8F8F8" }}
                    disabled={drugCheckInfo.length === 0}
                  />
                </>
              ) : (
                ""
              )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              <Button
                label="Activity"
                type="button"
                onClick={() => {
                  getActivityDetails(userVerificationRequestInfo?._id);
                  setShowActivity(!showActivity);
                }}
                icon={showActivity ? "pi pi-angle-down" : "pi pi-angle-right"}
                iconPos="right"
                className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />

              {drugCheckInfo.length > 0 &&
              (user?.role === "BCA" ||
                user?.role === "Vendor" ||
                user?.role === "FieldCoordinator" ||
                user?.role === "Coordinator") ? (
                  <Button
                    label="Assign"
                    type="button"
                    loading={assignLoading}
                    onClick={() => handleCreateTaskAPI()}
                    className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                ) : (
                  <></>
                )}
              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createDrugCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <div className="flex justify-center items-center h-screen w-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              strokeWidth="4"
              animationDuration=".7s"
            />
          </div>
        </>
      ) : (
        <>
          {drugCheckInfo.length === 0 ? (
            <>
              <div className="flex items-center">
                <NoData info="Ooops, we could not find any drug check details" />
              </div>
            </>
          ) : (
            <>
              {drugCheckInfo.length > 0 &&
                drugCheckInfo !== null &&
                drugCheckId !== undefined &&
                drugCheckId !== null &&
                drugCheckInfo !== undefined && (
                <>
                  <DrugDetails
                    userVerificationDetail={userVerificationRequestInfo?._id}
                    drugCheckId={drugCheckId}
                    drugCheckInfo={drugCheckInfo}
                    getDrugCheckInfo={getDrugCheckInfo}
                    taskDetail={taskDetail}
                    activityDetails={activityDetails}
                    showActivity={showActivity}
                    getActivityDetails={getActivityDetails}
                    flag={flag}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
