/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import {
  getSeverity,
  optionForRecord,
  modeOfVerification,
} from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import { SplitButton } from "primereact/splitbutton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";
import { Dropdown } from "primereact/dropdown";

const GlobalDatabaseDetails = ({
  userVerificationDetail,
  taskDetail,
  globalDatabaseCheckInfo,
  getGlobalDatabaseCheckInfo,
  activityDetails,
  showActivity,
  getActivityDetails,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [fileUpload, SetFileUplaod] = useState([]);
  const [adhaarUpload, SetAdhaarUplaod] = useState([]);
  const [panUpload, SetPanUplaod] = useState([]);
  const [editable, setEditable] = useState(false);
  const [uploadProof, SetUploadProof] = useState([]);
  const [adhaarFileMap, setAdhaarFileMap] = useState([]);
  const [panFileMap, setPanFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activity, setActivity] = useState("");
  // const [remarkForActivity, setRemarkForActivity] = useState("");

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));
  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    if (type === "file") {
      const updatedUrls = item?.globalDatabase?.adhaarUrl?.filter(
        (_, index) => index !== reportIndex
      );
      updateGlobalFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.globalDatabase?.uploadProofUrl?.filter(
        (_, index) => index !== reportIndex
      );
      updateGlobalProofArray(e, id, item, updatedUrls);
    }
  };

  const updateGlobalFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "globaldatabaseCheck/link";
    } else {
      url = "globaldatabaseCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      globalDatabase: {
        ...item.globalDatabase,
        adhaarUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.globalDatabase.remark,
        uploadProofUrl: [
          ...new Set([...item.globalDatabase.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      reason: {
        ...item.reason,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      SetAdhaarUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getDiscreteCallInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateGlobalProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "globaldatabaseCheck/link";
    } else {
      url = "globaldatabaseCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      globalDatabase: {
        ...item.globalDatabase,
        adhaarUrl: [
          ...new Set([...item.globalDatabase.adhaarUrl, ...adhaarUpload]),
        ], // Set the updated reportUrl array here
        remark: item.globalDatabase.remark,
        uploadProofUrl: updatedReportUrls,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      SetAdhaarUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getDiscreteCallInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const deleteItem = (id) => {
    const url = `globaldatabaseCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getGlobalDatabaseCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Global Database",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "globalDatabaseCheck/link";
    } else {
      url = "globalDatabaseCheck";
    }

    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.post(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        newData[index] = {
          ...newData[index],
          globalDatabase: {
            ...newData[index].globalDatabase,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(globalDatabaseCheckInfo);
    // SetFileUplaod(data?.globalDatabase?.reportUrl || []);
    SetAdhaarUplaod(data?.globalDatabase?.adhaarUrl || []);
    SetPanUplaod(data?.globalDatabase?.panUrl || []);
  }, [globalDatabaseCheckInfo]);

  const updateDiscreteCalls = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "globalDatabaseCheck/link";
    } else {
      url = "globalDatabaseCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      globalDatabase: {
        ...data[index]?.globalDatabase,
        adhaarUrl: [
          ...new Set([
            ...data[index]?.globalDatabase?.adhaarUrl,
            ...adhaarUpload,
          ]),
        ],
        panUrl: [
          ...new Set([...data[index]?.globalDatabase?.panUrl, ...panUpload]),
        ],
        remark: data[index]?.globalDatabase?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.globalDatabase?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   globalDatabaseCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Global Databasels updated successfully");
      if (flag === "false") {
        getGlobalDatabaseCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(
        `globalDatabaseCheck/globalDatabase/status/${id}`,
        body
      );

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getGlobalDatabaseCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
        // case "file":
        //   SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        //   toast.success("File successfully uploaded");
        //   break;
        case "adhaar":
          SetAdhaarUplaod(uploadedFileUrls);
          toast.success("File uploaded successfully");
          break;
        case "pan":
          SetPanUplaod(uploadedFileUrls);
          toast.success("Adhaar successfully uploaded");
          break;
        case "uploadProof":
          SetUploadProof(uploadedFileUrls);
          toast.success("Upload proof successfully uploaded");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearImages = () => {
    setAdhaarFileMap([]);
    setPanFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "adhaar") {
          setAdhaarFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "pan") {
          setPanFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "proof") {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        }

        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const handleEdit = () => {
    setEditable(!editable);
  };
  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Global Database Task Submitted!",
                userVerificationDetail,
                globalDatabaseCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex justify-between">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",

                                item?._id,
                                dataIndex
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",

                                item?._id,
                                dataIndex
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Major Discrepancy",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Minor Discrepancy",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  dataIndex
                                );
                              },
                            },
                          ]}
                        />
                      )}

                      <>
                        <div>{getSeverity(item?.globalDatabase?.status)}</div>
                      </>
                    </div>
                  </div>
                </>
              )}
              {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS === "true" && (
                <form>
                  <>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Candidate Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="candidateName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.candidateName || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Father&apos;s Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="fatherName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.fatherName || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Country
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="country"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.country || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Date Of Birth
                        </label>
                        <InputText
                          className="form-control"
                          type="Date"
                          name="dateOfBirth"
                          onChange={(e) => onInputChange(e, dataIndex)}
                          value={item?.globalDatabase?.dateOfBirth || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Address
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="address"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.globalDatabase?.address || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  </>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        Adhaar Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.adhaarUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "adhaar")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.adhaarUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "adhaar"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {adhaarFileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          adhaarFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          adhaarFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        PAN Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.panUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "pan")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.panUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "pan"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {panFileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          panFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          panFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.globalDatabase?.remark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "false"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.globalDatabase?.status === "Pending" ? (
                      item?.globalDatabase?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.globalDatabase?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.globalDatabase?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.globalDatabase?.status)}</div>
                    )}
                  </div>
                </form>
              )}
              {process.env.REACT_APP_IS_PAMAC_REPORT_ENABLED === "true" && (
                <form>
                  <>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Candidate Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="candidateName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.candidateName || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Father&apos;s Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="fatherName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.fatherName || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Country
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="country"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.country || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Date Of Birth
                        </label>
                        <InputText
                          className="form-control"
                          type="Date"
                          name="dateOfBirth"
                          onChange={(e) => onInputChange(e, dataIndex)}
                          value={item?.globalDatabase?.dateOfBirth || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Address
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="address"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithAllowedSymbols"
                            )
                          }
                          value={item?.globalDatabase?.address || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  </>
                  {/* <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        FileUpload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.adhaarUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "adhaar")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.adhaarUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "adhaar"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {adhaarFileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          adhaarFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          adhaarFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        PAN Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.panUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "pan")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.panUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "pan"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {panFileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          panFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          panFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div> */}

                  {/* {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, dataIndex, "alphanumeric");
                        }}
                        value={item?.globalDatabase?.remark || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )} */}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.globalDatabase?.status === "Pending" ? (
                      item?.globalDatabase?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.globalDatabase?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.globalDatabase?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.globalDatabase?.status)}</div>
                    )}
                  </div>
                </form>
              )}

              {process.env.REACT_APP_IS_VLEADER_REPORT_ENABLED === "true" && (
                <form>
                  <>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Candidate Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="candidateName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.candidateName || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Father&apos;s Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="fatherName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.globalDatabase?.fatherName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Date Of Birth
                        </label>
                        <InputText
                          className="form-control"
                          type="Date"
                          name="dateOfBirth"
                          onChange={(e) => onInputChange(e, dataIndex)}
                          value={item?.globalDatabase?.dateOfBirth || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>

                    {localStorageData?.role !== "user" &&
                      localStorageData?.role !== "DataEntry" && (
                        <>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Mode of Verification
                              </label>
                              <Dropdown
                                value={
                                  item?.globalDatabase?.modeOfVerification || ""
                                }
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text")
                                }
                                options={modeOfVerification}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="modeOfVerification"
                                name="modeOfVerification"
                              />
                            </div>

                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                Verified by
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="verifiedBy"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text")
                                }
                                value={item?.globalDatabase?.verifiedBy || ""}
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Date of Verification
                              </label>
                              <InputText
                                className="form-control"
                                type="Date"
                                name="dateOfVerification"
                                onChange={(e) => onInputChange(e, dataIndex)}
                                value={
                                  item?.globalDatabase?.dateOfVerification || ""
                                }
                              />
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Regulatory Authorities Check
                              </label>
                              <Dropdown
                                value={item?.globalDatabase?.regulatory || ""}
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text")
                                }
                                options={optionForRecord}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="regulatory"
                                name="regulatory"
                              />
                            </div>

                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                Compliance Checks
                              </label>
                              <Dropdown
                                value={item?.globalDatabase?.compliance || ""}
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text")
                                }
                                options={optionForRecord}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="compliance"
                                name="compliance"
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Serious and Organized Crimes
                              </label>
                              <Dropdown
                                value={
                                  item?.globalDatabase?.seriousCrimes || ""
                                }
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text")
                                }
                                options={optionForRecord}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="seriousCrimes"
                                name="seriousCrimes"
                              />
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Web and Media Searches
                              </label>
                              <Dropdown
                                value={item?.globalDatabase?.mediaSearch || ""}
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "text")
                                }
                                options={optionForRecord}
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select"
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                className="w-full md:w-14rem"
                                inputId="mediaSearch"
                                name="mediaSearch"
                              />
                            </div>

                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Time of Verification
                              </label>
                              <InputText
                                className="form-control"
                                type="text"
                                name="timeOfVerification"
                                onChange={(e) =>
                                  onInputChange(e, dataIndex, "alphanumeric")
                                }
                                value={
                                  item?.globalDatabase?.timeOfVerification || ""
                                }
                                disabled={flag === "true"}
                              />
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="entityName"
                              >
                                Verification Remarks
                              </label>

                              <textarea
                                className="form-control h-[110px]"
                                name="verificationRemark"
                                onChange={(e) => {
                                  // setRemarkForActivity(e.target.value);
                                  onInputChange(e, dataIndex, "text");
                                }}
                                value={
                                  item?.globalDatabase?.verificationRemark || ""
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="small mb-1"
                                htmlFor="clientType"
                              >
                                Additional Comments
                              </label>

                              <textarea
                                className="form-control h-[110px]"
                                name="additionalComment"
                                onChange={(e) => {
                                  // setRemarkForActivity(e.target.value);
                                  onInputChange(e, dataIndex, "text");
                                }}
                                value={
                                  item?.globalDatabase?.additionalComment || ""
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                  </>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        FileUpload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.globalDatabase?.adhaarUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "adhaar")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.globalDatabase?.adhaarUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  } // Pass the `item` to removeImg
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      dataIndex,
                                      reportIndex,
                                      "adhaar"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {adhaarFileMap[dataIndex]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={
                                          adhaarFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={
                                          adhaarFileMap[dataIndex][reportIndex]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                      <div className="col-md-8">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(e, dataIndex, "alphanumeric");
                          }}
                          value={item?.globalDatabase?.remark || ""}
                        />
                      </div>
                    )}

                  {localStorageData?.role !== "user" &&
                    localStorageData?.role !== "DataEntry" && (
                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG,PDF less than 5MB)
                          </span>
                        </label>
                        {item?.globalDatabase?.uploadProofUrl?.length === 0 && (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*,application/pdf"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                            disabled={flag === "true"}
                          />
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.globalDatabase?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              const isPdf = fileExtension === "pdf";

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <div
                                    role="presentation"
                                    onClick={(e) =>
                                      removeImg(
                                        e,
                                        item._id,
                                        item,
                                        proofIndex,
                                        "proof"
                                      )
                                    } // Pass the `item` to removeImg
                                    className="cursor-pointer"
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{ color: "red" }}
                                    ></i>
                                  </div>
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage &&
                                      proofImage[dataIndex]?.[proofIndex] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][proofIndex]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${proofIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                    {isPdf &&
                                      proofImage[dataIndex]?.[proofIndex] && (
                                        <a
                                          href={
                                            proofImage[dataIndex][proofIndex]
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-center"
                                        >
                                          View PDF
                                        </a>
                                      )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" &&
                    item?.globalDatabase?.status === "Pending" ? (
                      item?.globalDatabase?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.globalDatabase?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.globalDatabase?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.globalDatabase?.status)}</div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
          {localStorageData?.role === "user" && (
            <ConfirmDialog visible={isModalOpen} />
          )}
        </>
      ))}
    </>
  );
};

export default GlobalDatabaseDetails;
